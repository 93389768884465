import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
// Import Global Styles
import Text from '../../../../../../../../components/Text';
// Import Styles
import { Card, Button, ImgWrapper } from './styles';
// Import Logos
import VISA from '../../icons/visa-logo.png';
// Will add in EFT at a later date
// import EFT from '../../icons/banktransfer-logo.png';
import INTERAC from '../../icons/interac-logo.png';

// External Transfer to a Personal Bank Account
const Network = ({ network, selectedNetwork, setSelectedNetwork }) => {
  const imgSrc = {
    visa: VISA,
    // eft: EFT,
    etransfer: INTERAC,
  };

  const intl = useIntl();

  return (
    <Button key={network} onClick={() => setSelectedNetwork(network)}>
      <Card selected={selectedNetwork === network}>
        <ImgWrapper>
          <img src={imgSrc[network]} alt={`${network} logo`} />
        </ImgWrapper>

        <Text
          text={intl.messages[`personal-account-${network}-network-text`]}
          textFor={`${network}-network`}
        />
      </Card>
    </Button>
  );
};
Network.propTypes = {
  network: PropTypes.string.isRequired,
  selectedNetwork: PropTypes.string.isRequired,
  setSelectedNetwork: PropTypes.func.isRequired,
};

export default Network;
