import styled from 'styled-components';

export const IconWrapper = styled.span`
  margin-right: 10px;
  display: inline-flex;
`;

export const Alert = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid;
  border-radius: 2px;
  flex-direction: row;
  padding: 0.75em 1em;
  margin-bottom: 10px;
  ${({ type, theme }) => theme.alerts[type]}
  width: 100%;
`;
