export const personToExternalEn = {
  'person-to-external-heading': 'Request Funds',
  'person-to-external-add-contact-alert':
    'No Contacts found, please add a new contact to continue',
  'person-to-external-new-contact-button': 'Add Contact',
  'person-to-external-create-contact-heading': 'Add Contact',
  'person-to-external-contact-first-name-input-label': 'First Name',
  'person-to-external-contact-last-name-input-label': 'Last Name',
  'person-to-external-contact-email-input-label': 'Email',
  'person-to-external-contact-confirm-email-input-label': 'Confirm Email',
  'person-to-external-create-contact': 'add Contact',
  'person-to-external-success-create-alert': 'Contact successfully added',
  'person-to-external-form-title': 'Request Funds to my Account',
  'person-to-external-form-text':
    'From External Contact with Interac e-Transfer',
  'person-to-external-form-select-external-account-label':
    'Select a contact to request funds from',
  'person-to-external-home-transfers-title': 'Transfers',
  'person-to-external-tfr-date-table-header': 'Transfer Date',
  'person-to-external-tfr-description-table-header': 'Transfer Type',
  'person-to-external-tfr-amount-table-header': 'Amount',
  'person-to-external-tfr-status-table-header': 'Status',
  'person-to-external-tfr-information-name-table-header': 'Name',
  'person-to-external-tfr-information-email-table-header': 'Email',
  'person-to-external-tfr-cancel-table-heading': 'Cancel Transfer',
  'date-to-label': 'To:',
  'date-from-label': 'From:',
  'person-to-external-amount-label': 'Amount',
  'person-to-external-exceeds-available-amount-alert':
    'Amount exceeds available balance',
  'person-to-external-transfer-created-alert': 'Funds requested successfully',
  'person-to-external-transfer-cancelled-alert':
    'Transfer cancellation submitted successfully',
  'person-to-external-confirm-transfer-heading': 'Confirm Transfer',
  'person-to-external-confirm-cancel-heading': 'Confirm Transfer Cancellation',
  'person-to-external-message-label': 'Message',
  'person-to-external-confirm-transfer-message':
    'This transfer has a fee of ${feeAmount}. Do you wish to continue with this fund request?', // eslint-disable-line no-template-curly-in-string
  'person-to-external-confirm-transfer-no-fee-message':
    'Do you wish to continue with this fund request?',
  'person-to-external-confirm-cancel-message':
    'This transfer cancellation has a fee of ${feeAmount}. Do you wish to continue with this cancellation?', // eslint-disable-line no-template-curly-in-string
  'person-to-external-confirm-cancel-no-fee-message':
    'Do you wish to continue with this cancellation?',
};

export const personToExternalFr = {
  'person-to-external-heading': 'Demander de l’argent',
  'person-to-external-add-contact-alert':
    'Aucun contact trouvé, veuillez ajouter un nouveau contact pour continuer',
  'person-to-external-new-contact-button': 'Ajouter un contact',
  'person-to-external-contact-first-name-input-label': 'Prénom',
  'person-to-external-contact-last-name-input-label': 'Nom de famille',
  'person-to-external-contact-email-input-label': 'E-mail',
  'person-to-external-contact-confirm-email-input-label': 'Confirmer l’e-mail',
  'person-to-external-create-contact-heading': 'Ajouter un contact',
  'person-to-external-tfr-cancel-table-heading': 'Annuler le transfert',
  'person-to-external-create-contact': 'Ajouter un contact',
  'person-to-external-success-create-alert': 'Contact ajouté avec succès',
  'person-to-external-form-title':
    'Demander des fonds à mon compte avec Interac e-Transfer',
  'person-to-external-form-text':
    'Vers un contact externe avec Interac e-Transfer',
  'person-to-external-form-select-external-account-label':
    'Sélectionnez un contact pour demander des fonds',
  'person-to-external-home-transfers-title': 'Transferts',
  'person-to-external-tfr-date-table-header': 'Date du transfert',
  'person-to-external-tfr-description-table-header': 'Type de transfert',
  'person-to-external-tfr-amount-table-header': 'Montant',
  'person-to-external-tfr-status-table-header': 'Statut',
  'person-to-external-tfr-information-name-table-header': 'Nom',
  'person-to-external-tfr-information-email-table-header': 'E-mail',
  'date-to-label': 'À:',
  'date-from-label': 'De:',
  'person-to-external-amount-label': 'Montant',
  'person-to-external-exceeds-available-amount-alert':
    'Le montant dépasse le solde disponible',
  'person-to-external-transfer-created-alert': 'Fonds demandés avec succès',
  'person-to-external-transfer-cancelled-alert':
    'Annulation de transfert soumise avec succès',
  'person-to-external-confirm-transfer-heading': 'Confirmer le transfert',
  'person-to-external-confirm-cancel-heading':
    'Confirmer l’annulation du transfert',
  'person-to-external-message-label': 'Message',
  'person-to-external-confirm-transfer-message':
    'Ce transfert entraîne des frais de 1,50 $. Voulez-vous continuer avec cette demande de fonds?',
  'person-to-external-confirm-transfer-no-fee-message':
    'Souhaitez-vous continuer avec cette demande de fonds?',
  'person-to-external-confirm-cancel-message':
    'Cette annulation de transfert comporte des frais de ${feeAmount}. Souhaitez-vous continuer avec cette annulation?', // eslint-disable-line no-template-curly-in-string
  'person-to-external-confirm-cancel-no-fee-message':
    'Souhaitez-vous continuer avec cette annulation?',
};
