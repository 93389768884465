import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'shared-components';
import PropTypes from 'prop-types';

const LinkWrapper = ({ linkFor, link, page, ...props }) => (
  <Link {...props}>
    <FormattedMessage
      id={`${page}-${linkFor}-link`}
      description={`Link for ${link}`}
      defaultMessage={`${link}`}
    />
  </Link>
);

LinkWrapper.propTypes = {
  page: PropTypes.string.isRequired,
  linkFor: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
};

export default LinkWrapper;
