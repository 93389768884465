import {useState, useEffect} from 'react';

// hook that creates an alert state and a method to update the alert
// if the alert if of type 'success' we add a timeout that will remove the alert after 10 seconds

export default () => {
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    // eslint-disable-next-line
    const setSuccess = () => {
      if (alert && alert.type === 'success') {
        const clearSuccess = setTimeout(() => {
          // Clear Success message after 10 seconds
          setAlert(null);
        }, 10000);
        // clean up timer before useEffect and when component unmounts
        return () => {
          setAlert(null);
          clearTimeout(clearSuccess);
        };
      }
    };

    setSuccess();
  }, [alert]);

  return [alert, setAlert];
};
