import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
// Import Shared components
import { Footer, CookieConsent, ErrorBoundary } from 'shared-components';
// Import Contexts
import { ProgramConfigProvider } from '../contexts/ProgramConfigContext';
// Import Layouts
import Base from './Base';
import Header from './Header';
import BodyWrapper from './Body';
import Body from './PrivateBody';

import { errorLogger } from '../utils/errors';
import { ServiceConfigProvider } from '../contexts/ServiceConfigContext';

const PrivateLayout = ({ children, ...props }) => {

  const intl = useIntl();

  return (
    <Base>
      <ProgramConfigProvider>
        <ServiceConfigProvider>
          <Header {...props} />
          <BodyWrapper>
            <Body>
              <ErrorBoundary errorLogger={errorLogger}>{children}</ErrorBoundary>
            </Body>
            <Footer priv />
          </BodyWrapper>
          <CookieConsent messages={intl.messages} />
        </ServiceConfigProvider>
      </ProgramConfigProvider>
    </Base>
  );
};

PrivateLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default PrivateLayout;
