import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Loader, ErrorComponent } from 'shared-components';
import { deleteHTTP, get, post } from 'shared-components/src/utils/http';
import Heading from '../../../../components/Heading';
import Title from '../../../../components/Title';
import Alert from '../../../../components/Alert';
import Button from '../../../../components/Button';
import Card from '../../../../components/Card';
import Text from '../../../../components/Text';
import useAlert from '../../../../hooks/useAlert';
import ExternalTransferDirectsendPushForm from './components/ExternalTransferDirectsendPushForm';
import { TitleContainer, TextContainer } from './styles';
import { HeadingWrapper } from '../../styles';
import CreateExternalDirectsendAccountV2Modal from '../../components/CreateExternalDirectsendAccountV2Modal';
import EditExternalDirectsendAccountV2Modal from '../../components/EditExternalDirectsendAccountV2Modal';
import ConfirmDeleteModal from '../../components/ConfirmDeleteModal';
import TransferModal from '../../components/TransferModal';
import { directsendPushComponentName, updateFeeAmounts } from './utils';

const directsendPush = () => {
  const intl = useIntl();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useAlert();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [externalAccounts, setExternalAccounts] = useState([]);
  const [pushParams, setPushParams] = useState(null);
  const [feeAmounts, setFeeAmounts] = useState({
    create: '',
  });

  useEffect(() => {
    const fetchExternalAccounts = async () => {
      try {
        const [externalAccountsData] = await Promise.all([
          get(
            '/api/v2/cardholder_portal/cardholder/external_accounts?type=personal_funding_account'
          ),
        ]);

        setExternalAccounts(externalAccountsData.data);
      } catch (e) {
        setError({ type: 'danger', message: e });
      }
      setLoading(false);
    };

    const fetchProgramFeeConfig = async () => {
      try {
        const res = await get(
          '/api/v1/cardholder_portal/cardholder/fee_configs'
        );
        const fees = updateFeeAmounts(res.data);
        setFeeAmounts(fees);
      } catch (e) {
        setError(e);
      }
    };

    fetchProgramFeeConfig();
    fetchExternalAccounts();
  }, []);

  const page = 'directsend-push';

  const editExternalAccount = account => {
    setSelectedAccount(account);
    setShowEditModal(true);
  };

  const deleteExternalAccount = account => {
    setSelectedAccount(account);
    setShowDeleteModal(true);
  };

  const closeConfirmDeleteModal = e => {
    e.preventDefault();
    setShowDeleteModal(false);
    setAlert(false);
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      await Promise.all([
        deleteHTTP(
          `/api/v2/cardholder_portal/cardholder/external_accounts/${selectedAccount.id}`
        ),
      ]);
      setExternalAccounts(
        externalAccounts.filter(acc => acc.id !== selectedAccount.id)
      );
      setAlert({
        type: 'success',
        message: intl.messages[`${page}-account-deleted-alert`],
        messageFor: 'account-deleted',
        component: directsendPushComponentName,
      });
      setLoading(false);
      setShowDeleteModal(false);
    } catch (e) {
      setAlert({
        type: 'danger',
        message: e.message,
        messageFor: 'delete-error',
        component: directsendPushComponentName,
      });
      setLoading(false);
      setShowDeleteModal(false);
    }
  };

  const renderAlerts = component => {
    return (
      alert &&
      component === alert.component && (
        <Alert
          type={alert.type}
          alert={alert.message}
          alertFor={alert.messageFor}
          page={alert.page || page}
        />
      )
    );
  };

  const renderNewContactButton = () => {
    return (
      <Button
        buttonText={intl.messages[`${page}-new-contact-button`]}
        buttonFor="new-contact"
        page={page}
        onClick={e => {
          e.preventDefault();
          setShowCreateModal(true);
        }}
        type="primary"
      />
    );
  };

  const createPushTransfer = async params => {
    setLoading(true);
    try {
      await post(
        '/api/v2/cardholder_portal/cardholder/external_transfers',
        params
      );

      // set success alert
      setAlert({
        type: 'success',
        message: intl.messages[`${page}-transfer-created-alert`],
        messageFor: 'transfer-created',
        component: directsendPushComponentName,
      });
      setLoading(false);
      setShowConfirmModal(false);
    } catch (e) {
      setAlert({
        type: 'danger',
        message: e.message,
        messageFor: 'transfer-error',
        component: directsendPushComponentName,
      });
      setLoading(false);
      setShowConfirmModal(false);
    }
  };

  if (error) return <ErrorComponent />;
  if (loading) return <Loader />;

  return (
    <>
      <HeadingWrapper>
        <Heading page={page} heading={intl.messages[`${page}-heading`]} />
      </HeadingWrapper>
      <Card>
        {externalAccounts.length === 0 ? (
          <>
            {renderAlerts(directsendPushComponentName)}
            <TitleContainer>
              <Title
                title={intl.messages[`${page}-form-title`]}
                titleFor="form"
                page={page}
              />
              {renderNewContactButton()}
            </TitleContainer>
            <br />
            <TextContainer>
              <Alert
                type="info"
                alert={intl.messages[`${page}-add-contact-alert`]}
                alertFor="add-contact"
                page={page}
              />
            </TextContainer>
          </>
        ) : (
          <>
            {renderAlerts(directsendPushComponentName)}
            <TitleContainer>
              <Title
                title={intl.messages[`${page}-form-title`]}
                titleFor="form"
                page={page}
              />
              {renderNewContactButton()}
            </TitleContainer>
            <TextContainer>
              <Text
                page={page}
                textFor="form"
                text={intl.messages[`${page}-form-text`]}
              />
            </TextContainer>
            <ExternalTransferDirectsendPushForm
              setPushParams={setPushParams}
              setShowConfirmModal={setShowConfirmModal}
              disableSubmit={externalAccounts.length === 0}
              externalAccounts={externalAccounts}
              page={page}
              editExternalAccount={editExternalAccount}
              deleteExternalAccount={deleteExternalAccount}
            />
          </>
        )}
      </Card>
      {showCreateModal && (
        <CreateExternalDirectsendAccountV2Modal
          toggleModal={setShowCreateModal}
          setSuccess={setAlert}
          setExternalAccounts={setExternalAccounts}
          page={page}
        />
      )}
      {showConfirmModal && (
        <TransferModal
          toggleModal={setShowConfirmModal}
          confirmCallback={createPushTransfer}
          params={pushParams}
          feeAmount={feeAmounts.create}
          type="create"
          description="Text for the total amount of fee applied for a successful transfer"
          page={page}
        />
      )}
      {showEditModal && (
        <EditExternalDirectsendAccountV2Modal
          toggleModal={setShowEditModal}
          setSuccess={setAlert}
          setExternalAccounts={setExternalAccounts}
          page={page}
          id={selectedAccount.id}
        />
      )}
      {showDeleteModal && (
        <ConfirmDeleteModal
          toggleModal={closeConfirmDeleteModal}
          loading={loading}
          alert={alert}
          deleteItemValue={selectedAccount.id}
          deleteItemName={selectedAccount.name}
          deleteCallback={handleDelete}
          page={page}
        />
      )}
    </>
  );
};

export default directsendPush;
