import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
// Import Shared Utils
import { formatCurrencyCode } from 'shared-components/src/utils/formatCurrency';
// Import Translations
import { transferEn } from '../../../../i18n/transfer';
// Import Global Components
import Label from '../../../../components/Label';
// Import Component Styles
import { StyledAccountBalance, Balance } from './styles';

const AccountBalance = ({ balance, currency }) => (
  <StyledAccountBalance>
    <Label
      label={transferEn['transfer-balance-label']}
      labelFor="balance"
      page="transfer"
    />
    <Balance>
      <FormattedNumber
        value={balance}
        style="currency" // eslint-disable-line react/style-prop-object
        currency={formatCurrencyCode(currency)}
      />
    </Balance>
  </StyledAccountBalance>
);

AccountBalance.propTypes = {
  balance: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired
};

export default AccountBalance;
