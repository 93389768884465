import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 15px;

  & > *:not(:last-child) {
    margin-bottom: 15px;
  }
`;
