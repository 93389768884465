import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Button from './Button';

const CancelButton = ({ onClick }) => {
  const intl = useIntl();

  return (
    <Button
      buttonText={intl.messages['cancel-button']}
      buttonFor="cancel"
      page=""
      variant="text"
      type="danger"
      onClick={onClick}
    />
  );
};

CancelButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default CancelButton;
