import styled from 'styled-components';

export const StyledAccountBalance = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
  align-self: flex-end;
`;

export const Balance = styled.p`
margin: 0
font-weight: 700;
font-size: 18.72px;
line-height: 1.1;
color: ${({ theme }) => theme.brand.primary};
`;
