import React, { useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Input, ActionBar } from 'shared-components';
import { numberDollarsToCents } from 'shared-components/src/utils/currency';
import InputGroup from '../../../../../layouts/InputGroup';
import InputRow from '../../../../../layouts/InputRow';
import Form from '../../../../../layouts/Form';
import SubmitButton from '../../../../../components/SubmitButton';
import { TooltipWrapper } from '../../../../../components/TransferFeeTooltip/styles';

const PersonToExternalTransferForm = ({
  disableSubmit,
  externalAccounts,
  setPullParams,
  setShowConfirmModal,
  feeAmount,
  limits,
}) => {
  const intl = useIntl();
  const externalAccountsRef = useRef(null);
  const page = 'person-to-external';

  const [amountInput, setAmountInput] = useState(0);

  const handleSubmit = e => {
    e.preventDefault();
    setPullParams({
      amount: numberDollarsToCents(amountInput),
      transfer_type: 'direct_send_interac_pull',
      external_account_id: externalAccountsRef.current.value,
    });
    setShowConfirmModal(true);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <InputRow>
        <InputGroup
          label={intl.messages[`${page}-form-select-external-account-label`]}
          labelFor="externalAccounts"
          page="person-to-external"
          required
        >
          <Input ref={externalAccountsRef} required as="select">
            {externalAccounts.map(account => (
              <option key={account.id} value={account.id}>
                {account.name} - {account.email}
              </option>
            ))}
          </Input>
        </InputGroup>
        <InputGroup
          required
          label={intl.messages[`${page}-amount-label`]}
          labelFor="amount"
          page={page}
        >
          <Input
            name="amount"
            pattern="[0-9\.]"
            title="Please enter a valid amount"
            placeholder="00.00"
            type="number"
            step="0.01"
            min={limits.min_value_per_transaction || 1}
            max={limits.max_value_per_transaction || null}
            value={amountInput}
            onChange={e => setAmountInput(e.target.value)}
            required
          />
        </InputGroup>
      </InputRow>
      {feeAmount !== '' && (
        <InputRow>
          <TooltipWrapper>
            <p>
              <FormattedMessage
                id={`${page}-total-fee-text`}
                description="Text for the total amount of fee applied for a transfer"
                defaultMessage={`There is a $${feeAmount} fee for this transfer.`}
                values={{ feeAmount }}
              />
            </p>
          </TooltipWrapper>
        </InputRow>
      )}
      <ActionBar>
        <SubmitButton disabled={disableSubmit} />
      </ActionBar>
    </Form>
  );
};

PersonToExternalTransferForm.propTypes = {
  externalAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      vanity_identifier: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      created_at: PropTypes.string.isRequired,
      updated_at: PropTypes.string.isRequired,
    })
  ).isRequired,
  disableSubmit: PropTypes.bool.isRequired,
  setPullParams: PropTypes.func.isRequired,
  setShowConfirmModal: PropTypes.func.isRequired,
  feeAmount: PropTypes.string,
  limits: PropTypes.shape({
    max_value_per_transaction: PropTypes.number.isRequired,
    min_value_per_transaction: PropTypes.number.isRequired,
  }).isRequired,
};

PersonToExternalTransferForm.defaultProps = {
  feeAmount: '',
};

export default PersonToExternalTransferForm;
