import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Input, Modal } from 'shared-components';
import { post } from 'shared-components/src/utils/http';
import InputGroup from '../../../layouts/InputGroup';
import CardProxyLayout from '../layouts/CardholderAgreements';
import InfoTooltip from '../../../components/InfoTooltip';
import { StyledButton } from '../layouts/CardholderAgreements/styles';

const CardProxyPage = () => {
  const intl = useIntl();
  const [cardProxy, setCardProxy] = useState('');
  const [showCardProxyModal, setshowCardProxyModal] = useState(false);
  const [chaUrl, setChaUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [downloaded, setDownloaded] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleFindAgreement = async () => {
    try {
      setIsButtonDisabled(true);
      setLoading(true);
      setError('');

      if (!cardProxy.trim()) {
        // If the input is empty or contains only whitespace
        setError('Please enter a valid Proxy Number.');
        return;
      }

      const res = await post('/api/v1/cardholder_portal/cardholder_agreement', {
        processor_reference: cardProxy,
      });

      if (res.data && res.data.cardholder_agreement_url) {
        setChaUrl(res.data.cardholder_agreement_url);
        setshowCardProxyModal(true);
      } else {
        // Handle case where URL is not returned
        setError(
          'Error finding cardholder agreement, please try again. If you have any issues, please reach out to help@mycardholdersupport.com'
        );
      }
    } catch (error) {
      setError(
        'Error finding cardholder agreement, please try again. If you have any issues, please reach out to help@mycardholdersupport.com'
      );
    } finally {
      setLoading(false);

      // Enable the button after 10 seconds
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 10000);
    }
  };

  const handleDownloadAgreement = () => {
    window.open(chaUrl, '_blank');
    setDownloaded(true); // Update the state to indicate that the agreement has been downloaded
  };

  const handleGoBack = () => {
    setshowCardProxyModal(false);
    setDownloaded(false);
    setCardProxy('');
  };

  return (
    <CardProxyLayout
      page="cardholder-agreement"
      heading={intl.messages['cardholder-agreement-heading']}
      subHeaderThree={intl.messages['cardholder-agreement-subheader1-text']}
    >
      <InputGroup
        label={intl.messages['cardholder-agreement-card-proxy']}
        labelFor="card-proxy"
        required
        tooltip={(
          <InfoTooltip
            content={(
              <img
                src="https://images.dev.pungle.co/images/ProxyNumber.png"
                alt={intl.messages['cardholder-agreement-find-card-proxy']}
                style={{ maxWidth: '100%' }}
              />
            )}
            position="right"
          />
        )}
      >
        <Input
          value={cardProxy}
          onChange={e => setCardProxy(e.target.value.trim())}
          required
        />
      </InputGroup>

      <StyledButton
        type="primary"
        size="large"
        onClick={handleFindAgreement}
        disabled={loading || downloaded || isButtonDisabled}
      >
        {isButtonDisabled ? intl.messages['cardholder-agreement-wait-button'] : intl.messages['cardholder-agreement-submit-button']}
      </StyledButton>

      {error && <p style={{ color: 'red' }}>{error}</p>}

      {showCardProxyModal && (
        <Modal>
          {downloaded ? (
            <>
              <p>Your Cardholder Agreement is downloading!</p>
              <p>
                If you have any other questions, please contact us at
                help@mycardholdersupport.com
              </p>
            </>
          ) : (
            <>
              <p>Your Cardholder Agreement is ready to view.</p>
              <StyledButton
                type="primary"
                size="small"
                onClick={handleDownloadAgreement}
              >
                Download & View my Cardholder Agreement
              </StyledButton>
            </>
          )}
          <StyledButton size="small" onClick={handleGoBack}>
            Go Back
          </StyledButton>
        </Modal>
      )}
    </CardProxyLayout>
  );
};
export default CardProxyPage;
