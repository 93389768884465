import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
// Import Shared Components
import { Alert } from 'shared-components';

const AlertWrapper = ({ alert, alertFor, page, values, ...props }) => (
  <Alert {...props}>
    <FormattedMessage
      id={page ? `${page}-${alertFor}-alert` : `${alertFor}-alert`}
      description={`Alert for ${alertFor}`}
      defaultMessage={alert}
      values={values}
    />
  </Alert>
);

AlertWrapper.propTypes = {
  alert: PropTypes.string.isRequired,
  alertFor: PropTypes.string.isRequired,
  page: PropTypes.string,
  values: PropTypes.object, //eslint-disable-line
};

AlertWrapper.defaultProps = {
  page: null,
};

export default AlertWrapper;
