import {
  getFormattedMerchantInfo,
  getFormattedTransactionDate,
  getFormattedTransactionDescription,
} from 'shared-components/src/utils/cardholders';
import { formatCurrencySV } from 'shared-components/src/utils/formatCurrency';

export const csvHeaders = [
  { label: 'Transaction Date', key: 'timestamp' },
  { label: 'Transaction Details', key: 'description' },
  { label: 'Status', key: 'status' },
  { label: 'Merchant Information', key: 'merchant' },
  { label: 'Amount', key: 'amount' },
];

export const getTransactionCSVData = (transactions, locale) => {
  return transactions.map(trans => {
    const { info, localAmount } = getFormattedMerchantInfo(trans);
    const amount = trans.amount ? trans.amount : trans.billing_amount;
    const transactionCurrency = trans.billing_currency || null;

    const formattedAmount = formatCurrencySV(
      amount === '' ? '0' : amount,
      transactionCurrency,
      true
    );
    const merchantInfo = info && info !== '---' ? info : '';

    return {
      description: getFormattedTransactionDescription(trans),
      merchant:
        `${merchantInfo}${
          merchantInfo && localAmount ? ` (${localAmount})` : ''
        }` || '---',
      amount: formattedAmount,
      status: trans.status,
      timestamp: getFormattedTransactionDate(trans.timestamp, locale),
    };
  });
};
