import styled from 'styled-components';
import { HeaderWrapper as UnstyledHeaderWrapper } from '../../../../layouts/Header/styles';

export const LanguageWrapper = styled.div`
  margin-left: auto;
`;

export const HeaderWrapper = styled(UnstyledHeaderWrapper)`
  background-image: none;
`;
