export const labelsEn = {
  'transfer-name-label': 'Transfer Name:',
  'firstName-label': 'First Name:',
  'lastName-label': 'Last Name:',
  'email-label': 'Email:',
  'amount-label': 'Amount:',
  'account-label': 'Account:',
  'avaiable-balance-label': 'Available Balance:',
  'recipient-label': 'Recipient:',
  'description-label': 'Description:',
  'account-name-label': 'Account Name:',
  'name-label': 'Name:',
  'phone-label': 'Phone Number:',
  'security-question-label': 'Security Question:',
  'security-answer-label': 'Security Answer:',
  'sms-label': 'SMS',
  'email-2-label': 'Email',
};

export const labelsFr = {
  'transfer-name-label': 'Nom du transfert:',
  'firstName-label': 'Prénom:',
  'lastName-label': 'Nom de famille:',
  'email-label': 'Email:',
  'amount-label': 'Montant:',
  'account-label': 'Compte:',
  'available-balance-label': 'Vers mon compte',
  'recipient-label': 'Recipient:',
  'description-label': 'La description:',
  'account-name-label': 'Nom du compte:',
  'name-label': 'Nom:',
  'phone-label': 'Numéro de téléphone:',
  'security-question-label': 'Question de sécurité :',
  'security-answer-label': 'Réponse de sécurité:',
  'sms-label': 'SMS',
  'email-2-label': 'Email',
};
