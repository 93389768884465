import React from 'react';
import { Switch, withRouter } from 'react-router-dom';
// Import shared components
import { WithAutoLogout } from 'shared-components';
import auth from 'shared-components/src/utils/auth';
// Import Global Components
import Route from '../components/PrivateRoute';
// Import Global Layouts
import PrivateLayout from '../layouts/PrivateLayout';
// Import Private Routes
import Home from './Home';
import Profile from './Profile';
import About from './About';
import Transfer from './Transfer';
// Import Page Not Found Components
import NotFound from '../layouts/NotFound';
// Import Utils
import { refresh } from '../utils/auth';

const Private = withRouter(({ history, ...props }) => {
  if (!auth.isAuthenticated) return history.push('/auth/login');
  return (
    <PrivateLayout {...props} history={history} id="private">
      <Switch>
        <Route
          exact
          path="/"
          render={routeProps => {
            return <Home {...routeProps} />;
          }}
        />
        <Route
          path="/transfers"
          render={routeProps => {
            return <Transfer {...routeProps} />;
          }}
        />
        <Route
          exact
          path="/about"
          render={routeProps => {
            return <About {...routeProps} />;
          }}
        />
        <Route
          exact
          path="/profile"
          render={routeProps => {
            return <Profile {...routeProps} />;
          }}
        />
        <Route
          render={routeProps => {
            return <NotFound {...routeProps} />;
          }}
        />
      </Switch>
    </PrivateLayout>
  );
});

export default WithAutoLogout(Private, refresh);
