import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
// Import Component Styles
import { Heading } from './styles';

const HeadingWrapper = ({ page, heading, ...props }) => (
  <Heading>
    <FormattedMessage
      id={`${page}-heading`}
      description={`Heading for ${page} page`}
      defaultMessage={heading}
      {...props}
    />
  </Heading>
);

HeadingWrapper.propTypes = {
  page: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
};

export default HeadingWrapper;
