import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
// Import Shared Components
import { Input } from 'shared-components';
// Import Shared Hooks
import { useUpdateStateObject } from 'shared-components/src/hooks/useUpdateStateObject';
// Import Shared Validations
import {
  validateUsername,
  validatePassword,
} from 'shared-components/src/validations/cardholderValidations';
// Import Gobal Components
import InputGroup from '../../../layouts/InputGroup';
// Import Local Components
import AuthLayout from '../layouts/Auth';
import { AuthForm } from '../layouts/InnerAuth';
// Import Utils
import { login } from '../../../utils/auth';

const Login = ({ history }) => {
  const intl = useIntl();
  const page = 'auth';

  // Set States
  const initialUserInfo = {
    username: '',
    password: '',
  };
  const [formError, setFormError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  // Create reducer with initial card information state
  // Returns the updated state to be passed to components and a function to update the state
  const [userInfo, setUserInfo] = useUpdateStateObject(initialUserInfo);

  useEffect(() => {
    if (history.location.state && history.location.state.success) {
      setSuccess(history.location.state.success);
      // Remove success message from state

      const clearSuccess = setTimeout(() => {
        // Clear Success message after 5 seconds
        setSuccess(null);
      }, 5000);

      return () => {
        setSuccess(null);
        clearTimeout(clearSuccess);
      };
    }

    return () => setSuccess(null);
  }, [history.location.state]);

  // eslint-disable-next-line consistent-return
  async function handleLogin(username, password, setLoading, setFormError) {
    if (!validateUsername(username))
      return setFormError('invalid-username-format-error');
    if (!validatePassword(password))
      return setFormError('invalid-password-format-error');

    try {
      setLoading(true);
      setFormError(false);
      await login(username, password);
    } catch (error) {
      setLoading(false);
      setFormError(error.message);
    }
  }

  // Set Handlers
  const handleSubmit = async e => {
    e.preventDefault();
    await handleLogin(
      userInfo.username,
      userInfo.password,
      setLoading,
      setFormError
    );
  };

  const handleLoginKeyUp = async e => {
    if (e.keyCode === 13)
      await handleLogin(
        userInfo.username,
        userInfo.password,
        setLoading,
        setFormError
      );
  };

  return (
    <AuthLayout
      page="login"
      heading={intl.messages['login-heading']}
      subHeaderOne={intl.messages['login-subheader1-text']}
      subHeaderTwo={intl.messages['login-subheader2-text']}
      error={formError}
      success={success}
    >
      <AuthForm
        page="login"
        onSubmit={handleSubmit}
        onKeyUp={handleLoginKeyUp}
        loading={loading}
        secondaryButtonText={intl.messages['login-auth-button']}
        secondaryButtonFunc={() => {
          setFormError(null);
          return history.push('/forgot-password');
        }}
      >
        <InputGroup
          label={intl.messages[`${page}-username-label`]}
          labelFor="username"
          page={page}
          required
        >
          <Input
            value={userInfo.username}
            onChange={e =>
              setUserInfo({ key: 'username', value: e.target.value })}
            minLength={3}
            maxLength={30}
            required
          />
        </InputGroup>
        <InputGroup
          label={intl.messages[`${page}-password-label`]}
          labelFor="password"
          page={page}
          required
        >
          <Input
            value={userInfo.password}
            onChange={e =>
              setUserInfo({ key: 'password', value: e.target.value })}
            type="password"
            minLength={8}
            required
          />
        </InputGroup>
      </AuthForm>
    </AuthLayout>
  );
};

Login.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Login;
