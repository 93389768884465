import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
// Import Shared Components
import { Table, Loader, ErrorComponent } from 'shared-components';
import {
  getFormattedMerchantInfo,
  getFormattedTransactionDate,
  getFormattedTransactionDescription,
} from 'shared-components/src/utils/cardholders';
// Import Shared Utils
import { get } from 'shared-components/src/utils/http';
import { formatCurrencySV } from 'shared-components/src/utils/formatCurrency';
// Import Global Components
import Alert from '../../../../../../components/Alert';

const LocalAmountSpan = styled.span`
  color: #3f48cc;
  margin-left: 24px;
`;

const TransactionsTable = ({
  accountId,
  startDate,
  endDate,
  handleCsvData,
}) => {
  const intl = useIntl();

  // Set component states
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  // Set data states
  const [transactions, setTransactions] = useState([]);
  const [authorizations, setAuthorizations] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setError(false);
      setLoading(true);

      try {
        const query = new URLSearchParams({
          start_date: startDate,
          end_date: endDate,
        }).toString();

        const [
          transactionsData,
          authorizationsData,
        ] = await Promise.all([
          get(
            `/api/v1/cardholder_portal/cardholder/accounts/${accountId}/transactions?${query}`
          ),
          get(
            `/api/v1/cardholder_portal/cardholder/accounts/${accountId}/authorizations`
          ),
        ]);

        // Add apporpriate status to transactions and authorizations
        const supplementedTransactionsData = transactionsData.data.map(
          transaction => ({
            ...transaction,
            status: 'Settled',
          })
        );

        const supplementedAuthData = authorizationsData.data.map(auth => ({
          ...auth,
          status: 'Pending',
        }));

        setTransactions(supplementedTransactionsData);
        setAuthorizations(supplementedAuthData);

        handleCsvData([
          ...supplementedAuthData,
          ...supplementedTransactionsData,
        ]);
      } catch (e) {
        setError(true);
      }

      setLoading(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, accountId]);

  // Define page states
  if (error) return <ErrorComponent />;
  if (loading) return <Loader />;

  const columns = [
    {
      width: 175,
      Header: () => (
        <FormattedMessage
          id="txn-date-table-header"
          description="Table Header txn date"
          defaultMessage={intl.messages['txn-date-table-header']}
        />
      ),
      accessor: 'timestamp',
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => getFormattedTransactionDate(value, intl.locale),
    },
    {
      width: 200,
      Header: () => (
        <FormattedMessage
          id="txn-description-table-header"
          description="Table Header txn description"
          defaultMessage={intl.messages['txn-description-table-header']}
        />
      ),
      accessor: 'description',
      Cell: cell => {
        const description = getFormattedTransactionDescription(cell.original);
        return <div>{description}</div>;
      },
    },
    {
      width: 125,
      Header: () => (
        <FormattedMessage
          id="txn-status-table-header"
          description="Table Header txn status info"
          defaultMessage={intl.messages['txn-status-table-header']}
        />
      ),
      accessor: 'status',
    },
    {
      width: 450,
      Header: () => (
        <FormattedMessage
          id="txn-merchant-info-table-header"
          description="Table Header txn merchant info"
          defaultMessage={intl.messages['txn-merchant-info-table-header']}
        />
      ),
      accessor: 'merchant',
      Cell: cell => {
        const { info, localAmount } = getFormattedMerchantInfo(cell.original);
        return (
          <div>
            {info}
            {localAmount && <LocalAmountSpan>({localAmount})</LocalAmountSpan>}
          </div>
        );
      },
    },
    {
      width: 125,
      Header: () => (
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <FormattedMessage
            id="txn-amount-table-header"
            description="Table Header txn amount"
            defaultMessage={intl.messages['txn-amount-table-header']}
          />
        </div>
      ),
      Cell: cell => {
        // Use amount field if exists (galileo transaction),
        // if amount field is falsy we then check if auth_amount (galileo auth transaction)
        // if both are false we default to billing amount (carta transaction)
        const amount =
          cell.original.amount ||
          cell.original.auth_amount ||
          cell.original.billing_amount;

        // Get currency code or default to 'CAD'
        const currencyCode = cell.original.billing_currency || 'CAD';
        const formattedAmount = formatCurrencySV(
          amount === '' ? '0' : amount,
          currencyCode,
          true
        );

        return (
          <div
            style={{
              width: '100%',
              'text-align': 'right',
            }}
          >
            {formattedAmount}
          </div>
        );
      },
    },
  ];

  return (
    <>
      {transactions.length === 0 && authorizations.length === 0 ? (
        <Alert
          type="info"
          alert={intl.messages['home-table-alert']}
          alertFor="table"
          page="home"
        />
      ) : (
        <Table
          data={[...authorizations, ...transactions]}
          columns={columns}
        />
      )}
    </>
  );
};

TransactionsTable.propTypes = {
  accountId: PropTypes.number.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  handleCsvData: PropTypes.func.isRequired,
  accountBalance: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    balance: PropTypes.number.isRequired,
  }).isRequired,
};
export default TransactionsTable;
