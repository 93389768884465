import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
// Import Shared Components
import { ExternalLink } from 'shared-components';

const ExternalLinkWrapper = ({ page, linkFor, linkText, ...props }) => (
  <ExternalLink {...props}>
    <FormattedMessage
      page={page}
      id={`${page}-${linkFor}-link`}
      description={`External Link to ${linkFor}`}
      defaultMessage={linkText}
    />
  </ExternalLink>
);

ExternalLinkWrapper.propTypes = {
  page: PropTypes.string.isRequired,
  linkFor: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired
};

export default ExternalLinkWrapper;
