import React from 'react';
import PropTypes from 'prop-types'
;

const Icon = ({ color, size }) => (
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 115 115">
    <defs>
      <style jsx="true">{`.status-1{opacity:0.2;}.status-2{fill:${color};}`}</style>
    </defs>
    <title>images</title>
    <g className="status-1">
      <circle className="status-2" cx="56.5" cy="56.5" r="55.5" />
    </g>
    <path className="status-2" d="M56.5,113A56.5,56.5,0,1,1,113,56.5,56.56,56.56,0,0,1,56.5,113Zm0-111A54.5,54.5,0,1,0,111,56.5,54.56,54.56,0,0,0,56.5,2Z" />
    <path className="status-2" d="M75.05,57.76v-10A16.12,16.12,0,0,0,65.6,33.16a21.7,21.7,0,0,0-3.87-1.35v-.64a5.54,5.54,0,1,0-11.08,0v.73a22.75,22.75,0,0,0-3.48,1.26c-4.85,2.24-9.76,7.63-9.81,14.63v10a24.59,24.59,0,0,1-7.43,18.05,1.11,1.11,0,0,0,.6,1.88L41,79.5c2,.34,3.76.62,5.47.84a11,11,0,0,0,19.75,0c1.71-.23,3.51-.5,5.45-.84l10.52-1.81a1.11,1.11,0,0,0,.87-.76,1.09,1.09,0,0,0-.28-1.11,24.78,24.78,0,0,1-7.77-18.05ZM52.87,31.17a3.32,3.32,0,0,1,6.64,0v.2a22,22,0,0,0-6.64.05Zm3.52,53.17a8.87,8.87,0,0,1-7.11-3.67,67.14,67.14,0,0,0,7.1.38,67.57,67.57,0,0,0,7.12-.38,8.87,8.87,0,0,1-7.11,3.67Zm15-7c-2.13.36-4.07.66-5.92.9h0c-.81.1-1.6.19-2.37.27h0c-.71.07-1.4.13-2.09.18l-.38,0c-.59,0-1.18.07-1.76.09l-.36,0c-1.38,0-2.74,0-4.12,0l-.37,0-1.74-.09-.42,0c-.66,0-1.34-.1-2-.17h-.08c-.77-.08-1.55-.17-2.35-.27h0c-1.85-.23-3.8-.54-5.94-.9l-8.49-1.46a26.66,26.66,0,0,0,6.65-18.09v-10c0-6,4.31-10.67,8.52-12.62A20.13,20.13,0,0,1,52,33.86h0a20.13,20.13,0,0,1,8.39-.07h0a20,20,0,0,1,4.24,1.38,14.06,14.06,0,0,1,8.16,12.62v10a26.94,26.94,0,0,0,7,18.09Zm0,0" />
    <path className="status-2" d="M63.3,38.19a16.92,16.92,0,0,0-13.81,0c-2.43,1.12-6.56,4.4-6.59,9.59A1.11,1.11,0,0,0,44,48.9h0a1.11,1.11,0,0,0,1.11-1.1c0-4.07,3.34-6.69,5.3-7.59a14.68,14.68,0,0,1,12,0,1.11,1.11,0,0,0,.92-2Zm0,0" />
    <path className="status-2" d="M28.28,48.11a1.1,1.1,0,0,0-1.56,0,12.84,12.84,0,0,0,0,18.15,1.11,1.11,0,0,0,1.56-1.57,10.64,10.64,0,0,1,0-15,1.11,1.11,0,0,0,0-1.57Zm0,0" />
    <path className="status-2" d="M32.16,64.06a1.13,1.13,0,0,0,.78-.32,1.11,1.11,0,0,0,0-1.57,7.27,7.27,0,0,1,0-10.28,1.11,1.11,0,0,0-1.57-1.56,9.5,9.5,0,0,0,0,13.41,1.1,1.1,0,0,0,.79.32Zm0,0" />
    <path className="status-2" d="M85.67,48.11a1.11,1.11,0,1,0-1.56,1.57,10.64,10.64,0,0,1,0,15,1.11,1.11,0,1,0,1.56,1.57,12.84,12.84,0,0,0,0-18.15Zm0,0" />
    <path className="status-2" d="M79.44,50.33a1.11,1.11,0,0,0,0,1.56,7.27,7.27,0,0,1,0,10.28,1.11,1.11,0,0,0,0,1.57,1.11,1.11,0,0,0,1.57,0,9.5,9.5,0,0,0,0-13.41,1.11,1.11,0,0,0-1.57,0Zm0,0" />
    <script xmlns="" />
  </svg>
);

Icon.propTypes = {
  color: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired
};

export default Icon;