import React from 'react';
import PropTypes from 'prop-types';
// Import Global Components
import Text from '../../../../components/Text';
// Import Local Components
import Heading from '../../components/Heading';
// Import Component Styles
import {
  CHAWrapper,
  CHAContainer,
  Card,
  HeadingContainer,
  Auth,
} from './styles';

const CardProxyLayout = ({ page, heading, subHeaderThree, children }) => {
  return (
    <CHAWrapper>
      <Auth>
        <Card>
          <CHAContainer>
            <HeadingContainer>
              <Heading heading={heading} page={page} />
              {subHeaderThree && (
                <Text
                  text={subHeaderThree}
                  textFor="cardholder-agreement-text"
                  page={page}
                />
              )}
            </HeadingContainer>
            {children}
          </CHAContainer>
        </Card>
      </Auth>
    </CHAWrapper>
  );
};

CardProxyLayout.propTypes = {
  page: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  subHeaderThree: PropTypes.string,
  children: PropTypes.element.isRequired,
};

CardProxyLayout.defaultProps = {
  subHeaderThree: null,
};

export default CardProxyLayout;

export const BareCardProxyLayout = ({ children }) => (
  <CHAWrapper>
    <Auth>
      <Card>
        <CHAContainer>{children}</CHAContainer>
      </Card>
    </Auth>
  </CHAWrapper>
);

BareCardProxyLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
