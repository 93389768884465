import styled from 'styled-components';
import Container from './Container';

export default styled(Container)`
  padding: 105px 15px 15px;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;

  @media (min-width: 768px) {
    max-width: 750px;
  }
  @media (min-width: 992px) {
    max-width: 970px;
  }
  @media (min-width: 1200px) {
    max-width: 1170px;
  }
`;
