import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
// Import Shared Components
import { ChangeLocale, Logo } from 'shared-components';
// import Global components
import { Header as StyledHeader } from '../../../../layouts/Header/styles';
// Import Component Styles
import { HeaderWrapper, LanguageWrapper } from './styles';

const Header = withTheme(({ theme, setLocale, locale }) => {
  // create intl instance
  const intl = useIntl();

  return (
    <HeaderWrapper>
      <StyledHeader>
        <Logo src={locale === 'en' ? theme.brand.logo : theme.brand.logo_fr} />
        <LanguageWrapper>
          <ChangeLocale
            setLocale={setLocale}
            locale={locale}
            messages={intl.messages}
          />
        </LanguageWrapper>
      </StyledHeader>
    </HeaderWrapper>
  );
});

Header.propTypes = {
  setLocale: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
};

export default Header;
