export const tableHeadersEn = {
  'id-table-heading': 'ID',
  'name-table-heading': 'Name',
  'email-table-heading': 'Email',
  'create-at-table-heading': 'Created At',
  'actions-table-heading': 'Actions',
};

export const tableHeadersFr = {
  'id-table-heading': 'ID',
  'name-table-heading': 'Nom',
  'email-table-heading': 'Email',
  'create-at-table-heading': 'Créé à',
  'actions-table-heading': 'Actions',
};
