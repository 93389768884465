import styled from 'styled-components';

export default styled.div`
  background-color: #fff;
  border: ${({ theme }) => theme.borders.default};
  padding: 25px;
  border-radius: 3px;
  width: 100%;

  ${({ theme }) => theme.media.tabletLandscapeUp`
    min-height: 104px;
  `}
`;
