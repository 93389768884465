import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
// Import Shared Components
import { Loader } from 'shared-components';
// Import Shared Utils
import { get, post, deleteHTTP } from 'shared-components/src/utils/http';
// Import Hooks
import useAlert from '../../../../../../hooks/useAlert';
// Import Local Components
import ConfirmDeleteModal from '../../../../components/ConfirmDeleteModal';
import UpdateAccountModal from '../UpdateAccountModal';
import Form from './components/Form';
import PersonalAccount from './components/PersonalAccount';

// External Transfer to a Personal Bank Account
const PersonalAccountTransferForm = ({
  setAlert,
  personalAccount,
  setPersonalAccount,
  setPersonalAccounts,
  personalAccounts,
  setAccountBalance,
  balance,
  selectedNetwork,
}) => {
  const intl = useIntl();

  // Component states
  const [loading, setLoading] = useState(false);
  // Modal states
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteAlert, setDeleteAlert] = useAlert();
  const [deletingAccount, setDeletingAccount] = useState(false);

  const page = 'personal-account';

  const createPersonalTransfer = async params => {
    setLoading(true);
    try {
      // prepare payload
      const payload = {
        external_account_id: personalAccount.id,
        transfer_type: 'direct_send_transaction',
        ...params,
      };

      // send transfer request
      await post(
        '/api/v1/cardholder_portal/cardholder/external_transfers',
        payload
      );

      const accountBalanceData = await get(
        '/api/v1/cardholder_portal/cardholder/current_account/balance'
      );

      // set updated account data
      setAccountBalance(accountBalanceData.data);
      // set success alert
      setAlert({
        type: 'success',
        message: intl.messages[`${page}-transfer-created-alert`],
        messageFor: 'transfer-created',
      });
    } catch (e) {
      setAlert({
        type: 'danger',
        message: e.message,
      });
    }
    setLoading(false);
  };

  const deletePersonalAccount = async id => {
    setDeletingAccount(true);
    try {
      await deleteHTTP(
        `/api/v1/cardholder_portal/cardholder/external_accounts/${id}`
      );

      setAlert({
        type: 'success',
        message: intl.messages[`${page}-success-delete-alert`],
        messageFor: 'success-delete',
      });

      const newPersonalAccounts = personalAccounts.filter(pa => pa.id !== id);

      setPersonalAccounts(newPersonalAccounts);
      setPersonalAccount(null);
      setDeletingAccount(false);
      setShowDeleteModal(false);
    } catch (e) {
      setDeleteAlert({
        type: 'danger',
        message: e.message,
        messageFor: 'delete-account-failure',
      });
      setDeletingAccount(false);
    }
  };

  if (loading) return <Loader />;

  return (
    <>
      <Form
        setAlert={setAlert}
        balance={balance}
        disableSubmit={!personalAccount}
        createPersonalTransfer={createPersonalTransfer}
        selectedNetwork={selectedNetwork}
      >
        <PersonalAccount
          personalAccountName={personalAccount.name}
          toggleUpdateModal={setShowUpdateModal}
          toggleDeleteModal={setShowDeleteModal}
        />
      </Form>
      {showUpdateModal && (
        <UpdateAccountModal
          toggleModal={setShowUpdateModal}
          setSuccess={setAlert}
          personalAccount={personalAccount}
          setPersonalAccount={setPersonalAccount}
        />
      )}
      {showDeleteModal && (
        <ConfirmDeleteModal
          toggleModal={e => {
            e.preventDefault();
            setShowDeleteModal(false);
            setDeleteAlert(false);
          }}
          loading={deletingAccount}
          alert={deleteAlert}
          deleteCallback={deletePersonalAccount}
          page={page}
          deleteItemValue={personalAccount.id}
          deleteItemName={personalAccount.name}
        />
      )}
    </>
  );
};

PersonalAccountTransferForm.propTypes = {
  setAlert: PropTypes.func.isRequired,
  setPersonalAccount: PropTypes.func.isRequired,
  personalAccount: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
  setPersonalAccounts: PropTypes.func.isRequired,
  personalAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  balance: PropTypes.number.isRequired,
  selectedNetwork: PropTypes.func.isRequired,
  setAccountBalance: PropTypes.func.isRequired,
};

export default PersonalAccountTransferForm;
