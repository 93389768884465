import styled from 'styled-components';
import UnstyledCard from '../../../../components/Card';
import UnstyledLegend from '../../../../components/Legend';

// Wrapper for Header inside Card and Icon Button
export const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  h1 {
    margin-bottom: 0;
  }

  button {
    margin-top: 45px;
    width: auto;
  }
`;

export const Card = styled(UnstyledCard)`
  & > h3 {
    padding-top: 25px;
  }
`;

export const Legend = styled(UnstyledLegend)`
  padding: 5px 0;
`;
