import styled from 'styled-components';

// Main information in the widget
export const WidgetData = styled.p`
  margin: 0
  font-weight: 700;
  font-size: 18.72px;
  line-height: 1.1;
  color: ${({ theme }) => theme.brand.primary};
`;

// Wraps Label and Data
export const WidgetDataContainer = styled.div`
  text-align: right;
`;

// Wraps Icon, Label and Data
export const WidgetContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
