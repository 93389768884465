import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { get, put } from 'shared-components/src/utils/http';
import PropTypes from 'prop-types';
import {
  Modal,
  ActionBar,
  ButtonGroup,
  Loader,
  Input,
} from 'shared-components';
import { StyledLabel, ModalFormContainer } from './styles';

import SubmitButton from '../../../../components/SubmitButton';
import CancelButton from '../../../../components/CancelButton';
import Alert from '../../../../components/Alert';

const EditExternalEtransferAccountV2Modal = ({
  toggleModal,
  setSuccess,
  setExternalAccounts,
  page,
  id,
}) => {
  const intl = useIntl();
  const [formData, setFormData] = useState({
    contactExternalAccountName: '',
    contactInputFirstName: '',
    contactInputLastName: '',
    contactInputEmail: '',
    contactConfirmInputEmail: '',
  });

  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchExternalAccount = async () => {
    try {
      return await get(
        `/api/v2/cardholder_portal/cardholder/external_accounts/${id}/details`
      );
    } catch (error) {
      throw new Error(error.message || 'Failed to fetch external account');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const externalAccount = await fetchExternalAccount(id);
        if (externalAccount) {
          const [
            firstName,
            ...lastNameParts
          ] = externalAccount.etransfer.name.split(' ');
          const lastName = lastNameParts.join(' ');

          setFormData({
            contactExternalAccountName: externalAccount.name,
            contactInputFirstName: firstName,
            contactInputLastName: lastName,
            contactInputEmail: externalAccount.etransfer.email,
          });
        }
      } catch (error) {
        setAlert({ type: 'danger', message: error.message });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const updateContact = async () => {
    const {
      contactExternalAccountName,
      contactInputFirstName,
      contactInputLastName,
      contactInputEmail,
      contactConfirmInputEmail,
    } = formData;

    if (contactInputEmail !== contactConfirmInputEmail) {
      throw new Error('Emails do not match.');
    }

    try {
      const externalAccountPayload = {
        name: contactExternalAccountName,
        etransfer: {
          name: `${contactInputFirstName} ${contactInputLastName}`,
          email: contactInputEmail,
        },
      };

      await put(
        `/api/v2/cardholder_portal/cardholder/external_accounts/${id}`,
        externalAccountPayload
      );

      const response = await get(
        `/api/v1/cardholder_portal/cardholder/external_accounts/${id}`
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message || 'Failed to update external account');
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    setAlert(null);

    try {
      const updatedContact = await updateContact();
      setExternalAccounts(externalAccounts =>
        externalAccounts.map(account =>
          account.id === updatedContact.id ? updatedContact : account
        )
      );

      setSuccess({
        type: 'success',
        message: intl.messages[`${page}-success-update-alert`],
        messageFor: 'success-update',
        component: page,
        page,
      });

      setLoading(false);
      toggleModal(false);
    } catch (error) {
      setAlert({ type: 'danger', message: error.message });
      setLoading(false);
    }
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Modal>
      <ModalFormContainer>
        {alert && (
          <Alert
            alert={alert.message}
            alertFor={alert.messageFor}
            page={page}
            type={alert.type}
          />
        )}
        <h2>{intl.messages[`${page}-update-contact-heading`]}</h2>
        {loading ? (
          <Loader />
        ) : (
          <form onSubmit={handleSubmit}>
            <StyledLabel htmlFor="contactExternalAccountName">
              <span>*</span> {intl.messages[`${page}-contact-name-input-label`]}
            </StyledLabel>
            <Input
              id="contactExternalAccountName"
              type="text"
              name="contactExternalAccountName"
              value={formData.contactExternalAccountName}
              onChange={handleChange}
              required
            />
            <StyledLabel htmlFor="contactInputFirstName">
              <span>*</span>{' '}
              {intl.messages[`${page}-contact-first-name-input-label`]}
            </StyledLabel>
            <Input
              id="contactInputFirstName"
              type="text"
              name="contactInputFirstName"
              value={formData.contactInputFirstName}
              onChange={handleChange}
              required
            />
            <StyledLabel htmlFor="contactInputLastName">
              <span>*</span>{' '}
              {intl.messages[`${page}-contact-last-name-input-label`]}
            </StyledLabel>
            <Input
              id="contactInputLastName"
              type="text"
              name="contactInputLastName"
              value={formData.contactInputLastName}
              onChange={handleChange}
              required
            />
            <StyledLabel htmlFor="contactInputEmail">
              <span>*</span>{' '}
              {intl.messages[`${page}-contact-email-input-label`]}
            </StyledLabel>
            <Input
              id="contactInputEmail"
              type="email"
              name="contactInputEmail"
              value={formData.contactInputEmail}
              onChange={handleChange}
              autoComplete="email"
              minLength="10"
              maxLength="254"
              required
              pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
            />
            <StyledLabel htmlFor="contactConfirmInputEmail">
              <span>*</span>{' '}
              {intl.messages[`${page}-contact-confirm-email-input-label`]}
            </StyledLabel>
            <Input
              id="contactConfirmInputEmail"
              type="email"
              name="contactConfirmInputEmail"
              value={formData.contactConfirmInputEmail}
              onChange={handleChange}
              autoComplete="email"
              minLength="10"
              maxLength="254"
              required
              pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
            />
            <ActionBar>
              <ButtonGroup reverse>
                <SubmitButton />
                <CancelButton
                  onClick={e => {
                    e.preventDefault();
                    toggleModal(false);
                  }}
                />
              </ButtonGroup>
            </ActionBar>
          </form>
        )}
      </ModalFormContainer>
    </Modal>
  );
};

EditExternalEtransferAccountV2Modal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
  setExternalAccounts: PropTypes.func.isRequired,
  page: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
};

export default EditExternalEtransferAccountV2Modal;
