import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// Import Shared Components
import { ActionBar, Loader } from 'shared-components';
import { useUpdateStateObject } from 'shared-components/src/hooks/useUpdateStateObject';
// Import Global Components
import SubmitButton from '../../../components/SubmitButton';
// Import Global Layouts
import Form from '../../../layouts/Form';
// Import Local Components
import ProfileForm from '../components/Form';

const ProfileEdit = ({ onSubmit, cardholder, ...props }) => {
  // Define States
  const [loading, setLoading] = useState(true);
  // Define Reducers
  const [cardholderInput, handleOnChange] = useUpdateStateObject({});

  useEffect(() => {
    Object.keys(cardholder).map(k => {
      handleOnChange({ key: k, value: cardholder[k] });
      return null;
    });
    setLoading(false);
  }, [cardholder, handleOnChange]);

  if (loading) return <Loader />;

  return (
    <Form
      onSubmit={e => {
        e.preventDefault();
        onSubmit(cardholderInput);
      }}
    >
      <ProfileForm
        iconType="x"
        cardholder={cardholderInput}
        onChange={handleOnChange}
        {...props}
      />
      <ActionBar>
        <SubmitButton
          disabled={
            JSON.stringify(cardholderInput) === JSON.stringify(cardholder)
          }
        />
      </ActionBar>
    </Form>
  );
};

ProfileEdit.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  cardholder: PropTypes.objectOf({
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    email: PropTypes.string,
    first_name: PropTypes.string,
    id: PropTypes.number,
    last_name: PropTypes.string,
    middle_name: PropTypes.string,
    phone: PropTypes.string,
    postal_code: PropTypes.string,
  }).isRequired,
};

export default ProfileEdit;
