import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// Import Shared Components
import { Modal, Loader, IconButton } from 'shared-components';
// Import Shared Utils
import { post } from 'shared-components/src/utils/http';
// Import Global Components
import Heading from '../../../../components/Heading';
import Text from '../../../../components/Text';
import Alert from '../../../../components/Alert';
// Import Translations
import { profileEn } from '../../../../i18n/profile';
import { alertEn } from '../../../../i18n/alert';
// Import Local Layouts
import PinModal from '../../layouts/PinModal';
// Import Component Styles
import { PinContainer } from './styles';
// Import Global Hook
import useAlert from '../../../../hooks/useAlert';

const GetGalileoPinModal = ({
  toggleModal,
  currentAccount,
}) => {
  const [loading, setLoading] = useState(true);
  const [config, setConfig] = useState({
    access_token: '',
    config: '', 
    agserv_url: '' 
  });
  const [isConfigValid, setIsConfigValid] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [alert, setAlert] = useAlert();
  /* eslint-disable no-param-reassign */
  useEffect(() => {
    const fetchData = async () => {
      try {
        const params = {
          id: currentAccount.id,
          last_four_digits: currentAccount.cards[0].last_four_digits,
        };

        const res = await post(
          `api/v1/cardholder_portal/cardholder/accounts/${currentAccount.id}/pin/config`,
          params
        );

        setConfig(res.data);
        setIsConfigValid(true);
      } catch (e) {
        setAlert({ type: 'danger', message: alertEn['pin-retrieve-error'] });
        setIsConfigValid(false);
      }
      setLoading(false);
    };
    fetchData();
  }, []);
  /* eslint-enable no-param-reassign */

  const page = 'profile';

  const handleImageError = () => {
    setImageError(true);
    setAlert({ type: 'danger', message: alertEn['pin-retrieve-error'] });
  };

  return (
    <Modal>
      <PinModal>
        <IconButton
          iconType="x"
          onClick={toggleModal}
          className="close-modal"
        />
        {/* alert for both error and success */}
        {alert && (
          <Alert
            alert={alert.message}
            alertFor={alert.message}
            page={page}
            type={alert.type}
          />
        )}
        <Heading
          page={`${page}-get-pin`}
          heading={profileEn[`${page}-get-pin-heading`]}
        />
        {loading ? (
          <>
            <Text
              text={profileEn[`${page}-get-pin-wait-text`]}
              textFor="get-pin-wait"
              page={page}
            />
            <Loader />
          </>
        ) : isConfigValid && (
          <PinContainer>
            {!imageError && (
              <img
                src={`${config.agserv_url}/pin?token=${config.access_token}&config=${config.config}`}
                alt=""
                onError={handleImageError}
              />
            )}
          </PinContainer>
        )}
      </PinModal>
    </Modal>
  );
};

GetGalileoPinModal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  currentAccount: PropTypes.shape({
    id: PropTypes.number.isRequired,
    cards: PropTypes.array.isRequired, //eslint-disable-line
  }).isRequired,
};

export default GetGalileoPinModal;