import React from 'react';
import { useIntl } from 'react-intl';
import { SubmitButton } from 'shared-components';

export default props => {
  const intl = useIntl();

  return (
    <SubmitButton
      {...props}
      buttonType="primary"
      size="large"
      value={intl.formatMessage({
        id: 'submit-button',
        description: 'Submit button',
        defaultMessage: intl.messages['submit-button'],
      })}
    />
  );
};
