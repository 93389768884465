import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
// Import Shared Components
import {
  Modal,
  ActionBar,
  ButtonGroup,
  Loader,
  Input,
} from 'shared-components';
// Import Shared Utils
import { post } from 'shared-components/src/utils/http';
import { personToPersonAccount as PERSON_TO_PERSON_ACCOUNT } from 'shared-components/src/constants/propTypes';
// Import Global Layouts
import InputGroup from '../../../../../../layouts/InputGroup';
import InputRow from '../../../../../../layouts/InputRow';
import Form, { ModalFormContainer } from '../../../../../../layouts/Form';
// Import Global Components
import Heading from '../../../../../../components/Heading';
import Alert from '../../../../../../components/Alert';
import SubmitButton from '../../../../../../components/SubmitButton';
import CancelButton from '../../../../../../components/CancelButton';

const CreatePersonToPersonAccountModal = ({
  toggleModal,
  setSuccess,
  personToPersonAccounts,
  setPersonToPersonAccounts,
}) => {
  const intl = useIntl();
  // input refs
  const firstNameInput = useRef(null);
  const lastNameInput = useRef(null);
  const emailInput = useRef(null);
  // component states
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);

  const page = 'person-to-person';

  // post request to handle creation of recipient account
  const createRecipientAccount = async params => {
    try {
      // create external account
      const res = await post(
        '/api/v1/cardholder_portal/cardholder/external_accounts',
        params
      );

      setPersonToPersonAccounts([res.data, ...personToPersonAccounts]);
      setSuccess({
        type: 'success',
        message: intl.messages[`${page}-account-created-alert`],
        messageFor: 'account-created',
        page,
      });
      setLoading(false);
      toggleModal(false);
    } catch (e) {
      setAlert({ type: 'danger', message: e.message });
      setLoading(false);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    setAlert(null);

    const params = {
      first_name: firstNameInput.current.value,
      last_name: lastNameInput.current.value,
      email: emailInput.current.value,
      type: 'person_to_person',
    };

    createRecipientAccount(params);
  };

  return (
    <Modal>
      <ModalFormContainer>
        {alert && (
          <Alert
            alert={alert.message}
            alertFor={alert.messageFor}
            page={page}
            type={alert.type}
          />
        )}
        <Heading
          page={`${page}-create`}
          heading={intl.messages[`${page}-create-heading`]}
        />
        {loading ? (
          <Loader />
        ) : (
          <Form onSubmit={e => handleSubmit(e)}>
            <InputRow>
              <InputGroup
                label={intl.messages['firstName-label']}
                labelFor="firstName"
                required
              >
                <Input
                  ref={firstNameInput}
                  required
                  dataTestid="firstName"
                  name="firstName"
                  min="1"
                  max="40"
                  autocomplete="firstName"
                />
              </InputGroup>
            </InputRow>
            <InputRow>
              <InputGroup
                label={intl.messages['lastName-label']}
                labelFor="lastName"
                required
              >
                <Input
                  ref={lastNameInput}
                  required
                  dataTestid="lastName"
                  name="lastName"
                  min="1"
                  max="40"
                  autocomplete="lastName"
                />
              </InputGroup>
            </InputRow>
            <InputRow>
              <InputGroup
                label={intl.messages['email-label']}
                labelFor="email"
                required
              >
                <Input
                  ref={emailInput}
                  required
                  dataTestId="email"
                  name="email"
                  min="13"
                  max="19"
                  autocomplete="email"
                  type="email"
                />
              </InputGroup>
            </InputRow>

            <ActionBar>
              <ButtonGroup reverse>
                <SubmitButton />
                <CancelButton
                  onClick={e => {
                    e.preventDefault();
                    toggleModal(false);
                  }}
                />
              </ButtonGroup>
            </ActionBar>
          </Form>
        )}
      </ModalFormContainer>
    </Modal>
  );
};

CreatePersonToPersonAccountModal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
  personToPersonAccounts: PropTypes.arrayOf(
    PropTypes.shape(PERSON_TO_PERSON_ACCOUNT)
  ).isRequired,
  setPersonToPersonAccounts: PropTypes.func.isRequired,
};

export default CreatePersonToPersonAccountModal;
