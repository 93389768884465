import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ color, size }) => (
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 115 115">
    <defs>
      <style jsx="true">{`.balance-1{opacity:0.2;}.balance-2{fill:${color};}`}</style>
    </defs>
    <title>images</title>
    <g className="balance-1">
      <circle className="balance-2" cx="56.5" cy="56.5" r="55.5" />
    </g>
    <path className="balance-2" d="M56.5,113A56.5,56.5,0,1,1,113,56.5,56.56,56.56,0,0,1,56.5,113Zm0-111A54.5,54.5,0,1,0,111,56.5,54.56,54.56,0,0,0,56.5,2Z" />
    <path className="balance-2" d="M54.13,50.7c0,.56.24,1.39,2.33,2.08C57.34,53.08,60,54,60,56.6a3.88,3.88,0,0,1-3,3.76v.39a.92.92,0,0,1-1.83,0v-.39a3.88,3.88,0,0,1-3-3.76.92.92,0,1,1,1.83,0,2,2,0,1,0,4.07,0c0-.65-.28-1.4-2.33-2.08-2.38-.79-3.58-2.08-3.58-3.82a3.88,3.88,0,0,1,3-3.76v-.39a.92.92,0,0,1,1.83,0v.39a3.88,3.88,0,0,1,3,3.76.92.92,0,0,1-1.83,0,2,2,0,0,0-4.07,0Zm34-13v32a.92.92,0,0,1-.92.92h-.92v5.25a.92.92,0,0,1-.92.92h-.09l-2.67-.26v5.77a.92.92,0,0,1-.92.92l-.19,0L23.11,70.54h-.2l0,0h0l0,0,0,0h0l0,0,0,0h0l0,0,0,0,0,0,0,0,0,0,0,0,0,0v0l0,0v0l0,0v-.29h0v-.08h0a.8.8,0,0,1,0-.08v-32a.92.92,0,0,1,.92-.92H87.2a.92.92,0,0,1,.92.92ZM77.68,68.73a9.58,9.58,0,0,1,8.6-8.6v-13a9.58,9.58,0,0,1-8.6-8.6H32.81a9.58,9.58,0,0,1-8.6,8.6v13a9.58,9.58,0,0,1,8.6,8.6ZM31,68.73A7.74,7.74,0,0,0,24.21,62v6.75Zm0-30.15H24.21v6.75A7.74,7.74,0,0,0,31,38.58Zm55.31,0H79.53a7.74,7.74,0,0,0,6.75,6.75ZM80.77,76.27,39.3,72.15l41.47,8.93Zm3.67-5.71H41.81l42.63,4.23Zm1.84-1.83V62a7.74,7.74,0,0,0-6.75,6.75h6.75ZM64.83,46.45a.92.92,0,0,0-.22,1.28,10.31,10.31,0,1,1-2.52-2.52.92.92,0,1,0,1.05-1.5,12.14,12.14,0,1,0,3,3,.92.92,0,0,0-1.28-.22Zm0,0" />
    <script xmlns="" />
  </svg>
);

Icon.propTypes = {
  color: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired
};

export default Icon;