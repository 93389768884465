import styled from 'styled-components';

export const PersonalAccountName = styled.p`
  flex-grow: 1;
  color: #555;
  line-height: 1.428571429;
  font-weight: bold;
  outline: none;
  padding: 6px 12px;
  width: 100%;
  height: auto;
  min-height: 34px;
  margin: 0;
  box-sizing: border-box;
`;

export const PersonalAccountContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  input {
    margin-right: 15px;
  }
`;

export const StyledPersonalAccount = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
