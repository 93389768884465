// Translations File
import {
  issuerStatementEn,
  issuerStatementFr,
} from 'shared-components/src/components/IssuerStatement/text';
import TRANSLATIONS from 'shared-components/src/i18n/translations';
import { aboutEn, aboutFr } from './about';
import { homeEn, homeFr } from './home';
import { profileEn, profileFr } from './profile';
import { navEn, navFr } from './nav';
import { authEn, authFr } from './auth';
import { alertEn, alertFr } from './alert';
import { transferEn, transferFr } from './transfer';
import { personalAccountEn, personalAccountFr } from './personalAccount';
import { accountToAccountEn, accountToAccountFr } from './accountToAccount';
import { recipientAccountEn, recipientAccountFr } from './recipientAccount';
import { personToPersonEn, personToPersonFr } from './personToPerson';
import { personToExternalEn, personToExternalFr } from './personToExternal';
import { etransferPushEn, etransferPushFr } from './etransferPush';
import { etransferPullEn, etransferPullFr } from './etransferPull';
import { directsendPushEn, directsendPushFr } from './directsendPush';
import { directsendPullEn, directsendPullFr } from './directsendPull';
import { labelsEn, labelsFr } from './labels';
import { tableHeadersEn, tableHeadersFr } from './tableHeaders';
import { inputsEn, inputsFr } from './inputs';
import { cookieEn, cookieFr } from './cookie';

export default {
  en: {
    greeting: 'Card Holder Portal',
    'copyright-text':
      '© 2019. Berkeley Payment Solutions. All Rights Reserved.',
    'terms-and-conditions-external-link': 'Terms and Conditions',
    'privacy-policy-external-link': 'Privacy Policy',
    'submit-button': 'Submit',
    'cancel-button': 'Cancel',
    'delete-button': 'Delete',
    'select-placeholder-option': 'Select...',
    // 'header-locale-text': 'Change Language',
    ...aboutEn,
    ...homeEn,
    ...profileEn,
    ...navEn,
    ...authEn,
    ...issuerStatementEn,
    ...TRANSLATIONS.en,
    ...alertEn,
    ...transferEn,
    ...personalAccountEn,
    ...accountToAccountEn,
    ...recipientAccountEn,
    ...personToPersonEn,
    ...personToExternalEn,
    ...etransferPushEn,
    ...etransferPullEn,
    ...directsendPushEn,
    ...directsendPullEn,
    ...labelsEn,
    ...tableHeadersEn,
    ...inputsEn,
    ...cookieEn,
  },
  fr: {
    greeting: 'Portal du Cardholder',
    'copyright-text': '© 2019. Berkeley. Tous droits réservés.',
    'terms-and-conditions-external-link': 'Termes et conditions',
    'privacy-policy-external-link': 'Politique de confidentialité',
    'submit-button': 'Soumettre',
    'cancel-button': 'Annuler',
    'delete-button': 'Effacer',
    'select-placeholder-option': 'Choisir...',
    // 'header-locale-text': 'Changer de langue',
    ...aboutFr,
    ...homeFr,
    ...profileFr,
    ...navFr,
    ...authFr,
    ...issuerStatementFr,
    ...TRANSLATIONS.fr,
    ...alertFr,
    ...transferFr,
    ...personalAccountFr,
    ...accountToAccountFr,
    ...recipientAccountFr,
    ...personToPersonFr,
    ...etransferPushFr,
    ...etransferPullFr,
    ...directsendPushFr,
    ...directsendPullFr,
    ...personToExternalFr,
    ...labelsFr,
    ...tableHeadersFr,
    ...inputsFr,
    ...cookieFr,
  },
};
