// Translations File for Cookie

export const cookieEn = {
  'consent-text': 'PayAccount uses cookies to enhance our user experience and ensure correct functionality. By browsing our website you are agreeing to our cookie policy. For more information please visit',
  'cookie-button-text': 'Accept and Close',
  'policy-page-message': 'Our Policy Page',
};

export const cookieFr = {
  'consent-text': 'PayAccount utilise des cookies pour améliorer notre expérience utilisateur et garantir le bon fonctionnement. En naviguant sur notre site, vous acceptez notre politique en matière de cookies. Pour plus d`informations, veuillez visiter',
  'cookie-button-text': 'Accepter et Fermer',
  'policy-page-message': 'Notre page de politique',
};
