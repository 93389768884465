import styled from 'styled-components';
import UnstyledCard from '../../../../components/Card';

export const Card = styled(UnstyledCard)`
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  & > * {
    text-align: center;
    margin: 0 auto;
  }

  & > *:first-child {
    margin-bottom: 20px;
  }

  & > *:last-child {
    margin-bottom: 10px;
  }
`;

export const Section = styled.div`
  padding: 25px;
`;
