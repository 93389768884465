import styled from 'styled-components';

// Wrapper for Header inside Card and Icon Button
export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  h3 {
    margin: 20px 0;
  }
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 50px;

  ${({ theme }) => theme.media.tabletLandscapeUp`
    flex-direction: row;

    & > *:not(:last-child) {
      margin-right: 10px;
    }
  `}
`;
