import styled from 'styled-components';

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  & > h3 {
    padding-top: 5px;
    margin-bottom: 5px;
  }

  & > button {
    width: auto;
    margin-bottom: 5px;
  }

  ${({ theme }) => theme.media.tabletPortraitUp`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `}

  & > button {
    margin-bottom: 0;
  }
`;

export const TextContainer = styled.div`
  padding-bottom: 15px;
`;
