import styled from 'styled-components';

// Contains Header, Subtext and Button
export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.tabletPortraitUp`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `}
`;

// Wraps Transactions Header on Dashboard
export const TransactionsHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  ${({ theme }) => theme.media.tabletPortraitUp`
    flex-direction: row;
    justify-content: space-between;
  `}
`;

// Wraps Date Range and Date Picker for Transactions
export const DatesContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const WidgetGroup = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 15px;
  }

  ${({ theme }) => theme.media.tabletLandscapeUp`
    flex-direction: row;

    & > *:not(:last-child) {
      margin-bottom: 0;
      margin-right: 15px;
    }
  `}
`;

export const ExportsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
