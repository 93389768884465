import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import {
  Modal,
  ActionBar,
  ButtonGroup,
  Loader,
  Input,
} from 'shared-components';
import { post } from 'shared-components/src/utils/http';
import { StyledLabel, ModalFormContainer, InputRow, InputItem } from './styles';

import SubmitButton from '../../../../components/SubmitButton';
import CancelButton from '../../../../components/CancelButton';
import Alert from '../../../../components/Alert';

const CreateExternalDirectsendAccountV2Modal = ({
  toggleModal,
  setSuccess,
  setExternalAccounts,
  page,
}) => {
  const intl = useIntl();
  const contactExternalAccountName = useRef(null);

  const contactInputFullName = useRef(null);
  const contactInputCardNumber = useRef(null);
  const contactInputExpiry = useRef(null);
  const contactInputCVV = useRef(null);
  const contactInputAddressLineOne = useRef(null);
  const contactInputAddressLineTwo = useRef(null);
  const contactInputCity = useRef(null);
  const contactInputState = useRef(null);
  const contactInputCountry = useRef(null);
  const contactInputPostalCode = useRef(null);

  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);

  const addContact = async () => {
    const name = contactExternalAccountName.current.value;
    const fullName = contactInputFullName.current.value;
    const cardNumber = contactInputCardNumber.current.value;
    const [expiryMonth, expiryYear] = contactInputExpiry.current.value.split(
      '/'
    );
    const cvv = contactInputCVV.current.value;
    const addressLineOne = contactInputAddressLineOne.current.value;
    const addressLineTwo = contactInputAddressLineTwo.current.value;
    const city = contactInputCity.current.value;
    const state = contactInputState.current.value;
    const country = contactInputCountry.current.value;
    const postalCode = contactInputPostalCode.current.value;

    try {
      const externalAccountPayload = {
        name,
        card: {
          name: fullName,
          number: cardNumber,
          cvv,
          expiry_month: expiryMonth,
          expiry_year: expiryYear,
          address_line1: addressLineOne,
          address_line2: addressLineTwo,
          address_city: city,
          address_state: state,
          address_country: country,
          address_postal_code: postalCode,
        },
        type:
          page === 'directsend-push'
            ? 'personal_funding_account'
            : 'personal_source_account',
      };

      const { data } = await post(
        '/api/v2/cardholder_portal/cardholder/external_accounts',
        externalAccountPayload
      );

      if (!data || !data.id) {
        throw new Error('Failed to create external account');
      }

      return data;
    } catch (error) {
      throw new Error(error.message || 'Failed to create external account');
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    setAlert(null);

    try {
      const newContact = await addContact();
      setExternalAccounts(externalAccounts => [
        ...externalAccounts,
        newContact,
      ]);

      setSuccess({
        type: 'success',
        message: intl.messages[`${page}-success-create-alert`],
        messageFor: 'success-create',
        component: page,
        page,
      });

      setLoading(false);
      toggleModal(false);
    } catch (error) {
      setAlert({ type: 'danger', message: error.message });
      setLoading(false);
    }
  };

  return (
    <Modal>
      <ModalFormContainer>
        {alert && (
          <Alert
            alert={alert.message}
            alertFor={alert.messageFor}
            page={page}
            type={alert.type}
          />
        )}
        <h2>{intl.messages[`${page}-create-contact-heading`]}</h2>
        {loading ? (
          <Loader />
        ) : (
          <form onSubmit={e => handleSubmit(e)}>
            <InputRow>
              <InputItem>
                <StyledLabel htmlFor="contact-name-input">
                  <span>*</span>{' '}
                  {intl.messages[`${page}-contact-name-input-label`]}
                </StyledLabel>
                <Input
                  id="contact-name-input"
                  type="text"
                  ref={contactExternalAccountName}
                  required
                />
              </InputItem>
              <InputItem>
                <StyledLabel htmlFor="contact-full-cardholder-name-input">
                  <span>*</span>{' '}
                  {
                    intl.messages[
                      `${page}-contact-full-cardholder-name-input-label`
                    ]
                  }
                </StyledLabel>
                <Input
                  id="contact-full-cardholder-name-input"
                  type="text"
                  ref={contactInputFullName}
                  required
                />
              </InputItem>
            </InputRow>
            <InputRow>
              <InputItem>
                <StyledLabel htmlFor="contact-card-number-input">
                  <span>*</span>{' '}
                  {intl.messages[`${page}-contact-card-number-input-label`]}
                </StyledLabel>
                <Input
                  id="contact-card-number-input"
                  type="text"
                  ref={contactInputCardNumber}
                  required
                  pattern="\d{16}"
                  title="Please enter a 16-digit card number"
                />
              </InputItem>
              <InputItem>
                <InputRow>
                  <InputItem>
                    <StyledLabel htmlFor="contact-expiry-input">
                      <span>*</span>{' '}
                      {intl.messages[`${page}-contact-expiry-input-label`]}
                    </StyledLabel>
                    <Input
                      id="contact-expiry-input"
                      type="text"
                      ref={contactInputExpiry}
                      required
                      pattern="(0[1-9]|1[0-2])\/[0-9]{4}"
                      title="Please enter a valid MM/YYYY"
                    />
                  </InputItem>
                  <InputItem>
                    <StyledLabel htmlFor="contact-cvv-input">
                      <span>*</span>{' '}
                      {intl.messages[`${page}-contact-cvv-input-label`]}
                    </StyledLabel>
                    <Input
                      id="contact-cvv-input"
                      type="text"
                      ref={contactInputCVV}
                      required
                      pattern="\d{3}"
                      title="Please enter a 3-digit CVV"
                    />
                  </InputItem>
                </InputRow>
              </InputItem>
            </InputRow>
            <StyledLabel htmlFor="contact-address-line-one-input">
              <span>*</span>{' '}
              {intl.messages[`${page}-contact-address-line-one-input-label`]}
            </StyledLabel>
            <Input
              id="contact-address-line-one-input"
              type="text"
              ref={contactInputAddressLineOne}
              required
            />
            <StyledLabel htmlFor="contact-address-line-two-input">
              {intl.messages[`${page}-contact-address-line-two-input-label`]}
            </StyledLabel>
            <Input
              id="contact-address-line-two-input"
              type="text"
              ref={contactInputAddressLineTwo}
            />
            <InputRow>
              <InputItem>
                <StyledLabel htmlFor="contact-city-input">
                  <span>*</span>{' '}
                  {intl.messages[`${page}-contact-city-input-label`]}
                </StyledLabel>
                <Input
                  id="contact-city-input"
                  type="text"
                  ref={contactInputCity}
                  required
                />
              </InputItem>
              <InputItem>
                <StyledLabel htmlFor="contact-state-input">
                  <span>*</span>{' '}
                  {intl.messages[`${page}-contact-state-input-label`]}
                </StyledLabel>
                <Input
                  id="contact-state-input"
                  type="text"
                  ref={contactInputState}
                  required
                  pattern="[A-Z]{2}"
                  title="Please enter a 2-letter state abbreviation"
                />
              </InputItem>
            </InputRow>
            <InputRow>
              <InputItem>
                <StyledLabel htmlFor="contact-postal-code-input">
                  <span>*</span>{' '}
                  {intl.messages[`${page}-contact-postal-code-input-label`]}
                </StyledLabel>
                <Input
                  id="contact-postal-code-input"
                  type="text"
                  ref={contactInputPostalCode}
                  required
                  pattern="[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d"
                  title="Please enter a valid postal code (e.g., A1A 1A1 or A1A1A1)"
                />
              </InputItem>
              <InputItem>
                <StyledLabel htmlFor="contact-country-input">
                  <span>*</span>{' '}
                  {intl.messages[`${page}-contact-country-input-label`]}
                </StyledLabel>
                <Input
                  id="contact-country-input"
                  type="text"
                  ref={contactInputCountry}
                  required
                  title="The country code must be 'CAN'"
                  pattern="CAN" // Only allows "CAN" for now
                />
              </InputItem>
            </InputRow>
            <ActionBar>
              <ButtonGroup reverse>
                <SubmitButton />
                <CancelButton
                  onClick={e => {
                    e.preventDefault();
                    toggleModal(false);
                  }}
                />
              </ButtonGroup>
            </ActionBar>
          </form>
        )}
      </ModalFormContainer>
    </Modal>
  );
};

CreateExternalDirectsendAccountV2Modal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
  setExternalAccounts: PropTypes.func.isRequired,
  page: PropTypes.string.isRequired,
};

export default CreateExternalDirectsendAccountV2Modal;
