import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
// Import Global Components
import { Loader, ErrorComponent } from 'shared-components';
import { get, post } from 'shared-components/src/utils/http';
// Import Global Hooks
import useAlert from '../../hooks/useAlert';
// Import Contexts
import { ProgramConfigContext } from '../../contexts/ProgramConfigContext';
// Import Global Components
import Heading from '../../components/Heading';
import Card from '../../components/Card';
import Alert from '../../components/Alert';
// Import Translations
import { profileEn } from '../../i18n/profile';
// Import Utils
import { getCurrentAccount } from '../../utils/accounts';
import {
  checkSelectedState,
  stateKeys,
  provinceKeys,
} from '../../utils/profile';
// Import Local Components
import CardInfo from './components/CardInfo';
// Import Local Layouts
import ProfileEdit from './layouts/ProfileEdit';
import ProfileShow from './layouts/ProfileShow';
import LoginCredentialsForm from './components/LoginCredentialsForm';
// Import Component Styles
import { HeadingWrapper } from './styles';

const Profile = ({ location, history }) => {
  // Define States
  // component states
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [personalInfoAlert, setPersonalInfoAlert] = useAlert();
  const [cardInfoAlert, setCardInfoAlert] = useAlert();
  const [loginCredentialsAlert, setLoginCredentialsAlert] = useAlert();

  // data states
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [cardholder, setCardholder] = useState(null);
  const [editCardholder, setEditCardholder] = useState(false);
  const [currentAccount, setCurrentAccount] = useState(null);
  const [programProcessor, setProgramProcessor] = useState(null);
  const [editLoginCredentials, setEditLoginCredentials] = useState(false);

  const programConfigs = useContext(ProgramConfigContext);

  const page = 'profile';

  // Define effects
  useEffect(() => {
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);

      try {
        const cardholderData = await get(
          '/api/v1/cardholder_portal/cardholder'
        );

        setFirstName(cardholderData.data.first_name);
        setLastName(cardholderData.data.last_name);

        const account = getCurrentAccount(cardholderData.data);
        setCurrentAccount(account);

        setCardholder(cardholderData.data);
      } catch (error) {
        setIsError(true);
      }

      setIsLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const processorData = await get(
          '/api/v1/cardholder_portal/cardholder/program_processor'
        );

        setProgramProcessor(processorData.data);
      } catch (error) {
        console.log(error); //eslint-disable-line
      }
    };

    fetchData();
  }, []);

  // Galileo will redirect to cardholder portal after the direct render set pin form is completed
  // If redirected from galileo set pin direct render form, URL will have query params set_pin_state
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const setPinState = query.get('set_pin_state');
    // Handle success/failure of Galileo hosted direct render form by displaying alert banners
    if (setPinState === 'fail') {
      // Use history.replace() so that when users clicks back on history navigation,
      // 
      history.push('/profile', {
        cardInfoAlert: {
          message: profileEn[`${page}-failed-set-pin-alert`],
          messageFor: 'failed-set-pin',
          type: 'danger',
        },
      });
    } else if (setPinState === 'success') {
      // New PIN is only staged after set pin form completion. A commit is needed to complete set PIN
      // cardInfoAlert in the history state allows display of the success/failed alerts
      const commitPin = async () => {
        try {
          await post(
            `/api/v1/cardholder_portal/cardholder/accounts/${query.get(
              'account_id'
            )}/pin/commit`,
            {
              last_four_digits: query.get('last_four_digits'),
            }
          );
          history.push('/profile', {
            cardInfoAlert: {
              message: profileEn[`${page}-successful-set-pin-alert`],
              messageFor: 'successful-set-pin',
              type: 'success',
            },
          });
        } catch (e) {
          history.push('/profile', {
            cardInfoAlert: {
              message: profileEn[`${page}-failed-set-pin-alert`],
              messageFor: 'failed-set-pin',
              type: 'danger',
            },
          });
        }
      };
      commitPin();
    }
  }, [location.search, history]);

  useEffect(() => {
    if (location.state && location.state.cardInfoAlert) {
      setCardInfoAlert(location.state.cardInfoAlert);
    }
  }, [location.state, setCardInfoAlert]);

  if (isError) return <ErrorComponent />;
  if (isLoading) return <Loader />;

  const updateCardholderProfile = async input => {
    setIsLoading(true);

    try {
      await post('/api/v1/cardholder_portal/cardholder/update', input);

      // Update Cardholder with input information
      setCardholder(input);

      setPersonalInfoAlert({
        message: profileEn[`${page}-profile-updated-alert`],
        messageFor: 'profile-updated',
        type: 'success',
      });
      setEditCardholder(false);
    } catch (e) {
      setPersonalInfoAlert({
        message: e.message,
        type: 'danger',
      });
    }
    setIsLoading(false);
  };

  // Define Handlers
  const handleViewChange = () => {
    setPersonalInfoAlert(null);
    setEditCardholder(!editCardholder);
  };

  const handleSubmit = input => {
    // get state keys to check input against based on the input country
    const keys = input.country === '840' ? stateKeys : provinceKeys;
    // check that the selected state is valid for the selected country
    if (!checkSelectedState(input, keys, input.country))
      return setPersonalInfoAlert({
        message: profileEn[`${page}-invalid-state-alert`],
        messageFor: 'invalid-state',
        type: 'danger',
      });

    return updateCardholderProfile(input);
  };

  const handleIconType = () => {
    setLoginCredentialsAlert(null);
    setEditLoginCredentials(!editLoginCredentials);
  };


  return (
    <>
      <HeadingWrapper>
        <Heading
          page="profile"
          heading="{name} Profile"
          values={{
            name: `${firstName} ${lastName}`,
          }}
        />
      </HeadingWrapper>
      <Card>
        {cardInfoAlert && (
          <Alert
            type={cardInfoAlert.type}
            alert={cardInfoAlert.message}
            page={page}
            alertFor={cardInfoAlert.messageFor}
          />
        )}
        <CardInfo
          currentAccount={currentAccount}
          programProcessor={programProcessor}
          programConfigs={programConfigs}
        />
      </Card>
      <Card>
        {personalInfoAlert && (
          <Alert
            type={personalInfoAlert.type}
            alert={personalInfoAlert.message}
            page={page}
            alertFor={personalInfoAlert.messageFor}
          />
        )}
        {editCardholder ? (
          <ProfileEdit
            onClick={handleViewChange}
            cardholder={cardholder}
            onSubmit={handleSubmit}
          />
        ) : (
          <ProfileShow cardholder={cardholder} onClick={handleViewChange} />
        )}
      </Card>
      <Card>
        {loginCredentialsAlert && (
          <Alert
            type={loginCredentialsAlert.type}
            alert={loginCredentialsAlert.message}
            page={page}
            alertFor={loginCredentialsAlert.messageFor}
          />
        )}
        <LoginCredentialsForm
          iconType={editLoginCredentials ? 'x' : 'edit'}
          onClick={handleIconType} 
          username={cardholder.username}
        />
      </Card>
    </>
  );
};
Profile.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
    state: PropTypes.shape({
      cardInfoAlert: PropTypes.shape({
        message: PropTypes.string,
        messageFor: PropTypes.string,
        type: PropTypes.string,
      }),
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
export default Profile;
