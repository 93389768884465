import React from 'react';
import PropTypes from 'prop-types';
// import { useIntl } from 'react-intl';
// Import Components
import Network from './components/Network';

import { StyledSelectNetwork } from './styles';

// External Transfer to a Personal Bank Account
const SelectNetwork = ({ networks, selectedNetwork, setSelectedNetwork }) => {
  return (
    <StyledSelectNetwork>
      {networks.includes('visa') && (
        <Network
          network="visa"
          selectedNetwork={selectedNetwork}
          setSelectedNetwork={setSelectedNetwork}
        />
      )}
      {networks.includes('eft') && (
        <Network
          network="eft"
          selectedNetwork={selectedNetwork}
          setSelectedNetwork={setSelectedNetwork}
        />
      )}
      {networks.includes('etransfer') && (
        <Network
          network="etransfer"
          selectedNetwork={selectedNetwork}
          setSelectedNetwork={setSelectedNetwork}
        />
      )}
    </StyledSelectNetwork>
  );
};

SelectNetwork.propTypes = {
  networks: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedNetwork: PropTypes.string.isRequired,
  setSelectedNetwork: PropTypes.func.isRequired,
};

export default SelectNetwork;
