export const directsendPullComponentName = 'directsend-pull';

export const updateFeeAmounts = data => {
  const successFee = data.visa_pull_success;

  const update = {};
  if (typeof successFee === 'number' && successFee > 0) {
    update.create = (Number(successFee) / 100).toFixed(2);
  }

  return update;
};
