import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import {
  Modal,
  ActionBar,
  ButtonGroup,
  Loader,
  Input,
} from 'shared-components';
import { post } from 'shared-components/src/utils/http';
import { StyledLabel, ModalFormContainer } from './styles';

import SubmitButton from '../../../../components/SubmitButton';
import CancelButton from '../../../../components/CancelButton';
import Alert from '../../../../components/Alert';

const CreateExternalEtransferAccountV2Modal = ({
  toggleModal,
  setSuccess,
  setExternalAccounts,
  page,
}) => {
  const intl = useIntl();
  const contactExternalAccountName = useRef(null);
  const contactInputFirstName = useRef(null);
  const contactInputLastName = useRef(null);
  const contactInputEmail = useRef(null);
  const contactConfirmInputEmail = useRef(null);

  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);

  const addContact = async () => {
    const name = contactExternalAccountName.current.value;
    const firstName = contactInputFirstName.current.value;
    const lastName = contactInputLastName.current.value;
    const email = contactInputEmail.current.value;
    const confirmEmail = contactConfirmInputEmail.current.value;

    if (email !== confirmEmail) {
      throw new Error('Emails do not match.');
    }

    try {
      const externalAccountPayload = {
        name,
        etransfer: {
          name: `${firstName} ${lastName}`,
          email
        },
        type: page === 'etransfer-push' ? 'external_funding_account' : 'external_source_account',
      };

      const { data } = await post(
        '/api/v2/cardholder_portal/cardholder/external_accounts',
        externalAccountPayload
      );

      if (!data || !data.id) {
        throw new Error('Failed to create external account');
      }

      return data;
    } catch (error) {
      throw new Error(error.message || 'Failed to create external account');
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    setAlert(null);

    try {
      const newContact = await addContact();
      setExternalAccounts(externalAccounts => [
        ...externalAccounts,
        newContact,
      ]);

      setSuccess({
        type: 'success',
        message: intl.messages[`${page}-success-create-alert`],
        messageFor: 'success-create',
        component: page,
        page,
      });

      setLoading(false);
      toggleModal(false);
    } catch (error) {
      setAlert({ type: 'danger', message: error.message });
      setLoading(false);
    }
  };

  return (
    <Modal>
      <ModalFormContainer>
        {alert && (
          <Alert
            alert={alert.message}
            alertFor={alert.messageFor}
            page={page}
            type={alert.type}
          />
        )}
        <h2>{intl.messages[`${page}-create-contact-heading`]}</h2>
        {loading ? (
          <Loader />
        ) : (
          <form onSubmit={e => handleSubmit(e)}>
            <StyledLabel htmlFor="contact-name-input">
              <span>*</span> {intl.messages[`${page}-contact-name-input-label`]}
            </StyledLabel>
            <Input
              id="contact-name-input"
              type="text"
              ref={contactExternalAccountName}
              required
            />
            <StyledLabel htmlFor="contact-first-name-input">
              <span>*</span> {intl.messages[`${page}-contact-first-name-input-label`]}
            </StyledLabel>
            <Input
              id="contact-first-name-input"
              type="text"
              ref={contactInputFirstName}
              required
            />
            <StyledLabel htmlFor="contact-last-name-input">
              <span>*</span> {intl.messages[`${page}-contact-last-name-input-label`]}
            </StyledLabel>
            <Input
              id="contact-last-name-input"
              type="text"
              ref={contactInputLastName}
              required
            />
            <StyledLabel htmlFor="contact-email-input">
              <span>*</span> {intl.messages[`${page}-contact-email-input-label`]}
            </StyledLabel>
            <Input
              id="contact-email-input"
              type="email"
              ref={contactInputEmail}
              autoComplete="email"
              minLength="10"
              maxLength="254"
              required
              pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
            />
            <StyledLabel htmlFor="contact-confirm-email-input">
              <span>*</span> {intl.messages[`${page}-contact-confirm-email-input-label`]}
            </StyledLabel>
            <Input
              id="contact-confirm-email-input"
              type="email"
              ref={contactConfirmInputEmail}
              autoComplete="email"
              minLength="10"
              maxLength="254"
              required
              pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
            />
            <ActionBar>
              <ButtonGroup reverse>
                <SubmitButton />
                <CancelButton
                  onClick={e => {
                    e.preventDefault();
                    toggleModal(false);
                  }}
                />
              </ButtonGroup>
            </ActionBar>
          </form>
        )}
      </ModalFormContainer>
    </Modal>
  );
};

CreateExternalEtransferAccountV2Modal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
  setExternalAccounts: PropTypes.func.isRequired,
  page: PropTypes.string.isRequired,
};

export default CreateExternalEtransferAccountV2Modal;
