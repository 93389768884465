import styled from 'styled-components';
// Import Components
import { Input } from 'shared-components';

// Wrapper for Input and Label
export const InputWrapper = styled.div`
  margin-bottom: 15px;
`;

// Form Input
export const TextInput = styled(Input)`
  text-transform: uppercase;

  &::placeholder {
    text-transform: uppercase;
  }
`;

// Wrapper for Header inside Card and Icon Button
export const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  button {
    margin-top: 45px;
    width: auto;
  }

  h1 {
    margin-bottom: 0;
  }
`;
