import styled from 'styled-components';
import { Link, ExternalLink, IconButton } from 'shared-components';
import Container from '../Container';

// Header Logo Styles *****
// External link for Logo Image
export const LogoLink = styled(ExternalLink)`
  display: flex;
  align-items: center;
  height: 60px;

  max-width: 85%;

  ${({ theme }) => theme.media.tabletPortraitUp`
   max-width: 75%;
  `}

  ${({ theme }) => theme.media.desktopUp`
    max-width: 450px;
  `}

  img {
    max-width: 100%;
  }
`;

export const LogoLinkDefault = styled(Link)`
  display: flex;
  align-items: center;
  height: 60px;

  max-width: 85%;

  ${({ theme }) => theme.media.tabletPortraitUp`
   max-width: 75%;
  `}

  ${({ theme }) => theme.media.desktopUp`
    max-width: 450px;
  `}

  img {
    max-width: 100%;
  }
`;
// Wraps the Logo and Navigation Links
export const Header = styled(Container)`
  width: 100%;
  flex: 1;
  height: 90px;
  display: flex;
  align-items: center;

  ${({ theme }) => theme.media.desktopUp`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `}
`;

// Icon that toggles menu for small breakpoints
export const MenuButton = styled(IconButton)`
  margin-left: auto;
  height: 100%;

  ${({ theme }) => theme.media.desktopUp`
    display: none;
  `}
`;

// Top level element of the header
export const HeaderWrapper = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 50px;
  padding: 0 20px;
  ${({ theme }) =>
    theme.brand.headerBackground.includes('https')
      ? `background: url(${theme.brand.headerBackground});
         background-size: cover;
      `
      : `background: ${theme.brand.headerBackground};`}
  color: ${({ theme }) => theme.brand.headerText};

  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 3;
`;
