import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const Legend = ({ page, legend, legendFor, values, ...props }) => (
  <legend {...props}>
    <FormattedMessage
      id={`${page}-${legendFor}-legend`}
      description={`Text for ${legendFor}`}
      defaultMessage={legend}
      values={values}
    />
  </legend>
);

Legend.propTypes = {
  legend: PropTypes.string.isRequired,
  legendFor: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
  values: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

Legend.defaultProps = {
  values: undefined,
};

export default Legend;
