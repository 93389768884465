// Translation file for Profile page

export const profileEn = {
  'profile-heading': '{name}\'s Profile',
  'profile-card-info-title': 'Card Information',
  'profile-show-sensitive-card-info-button': 'Show sensitive card information',
  'profile-card-number-text': 'Card Number:',
  'profile-card-cvv-text': 'CVV:',
  'profile-card-expiry-text': 'Expiry date:',
  'profile-personal-info-title': 'Personal Information',
  'profile-personal-mailing-title': 'Mailing Address',
  'profile-personal-billing-title': 'Billing Address',
  'profile-firstName-label': 'First Name',
  'profile-middleName-label': 'Middle Name',
  'profile-lastName-label': 'Last Name',
  'profile-email-label': 'Email Address',
  'profile-phone-label': 'Phone Number',
  'profile-addressLine1-label': 'Address 1',
  'profile-addressLine2-label': 'Address 2',
  'profile-country-label': 'Country',
  'profile-province-label': 'Province',
  'profile-city-label': 'City',
  'profile-postalCode-label': 'Postal Code',
  'profile-update-button': 'Update',
  'profile-profile-updated-alert': 'Your profile was updated successfully.',
  'profile-danger-alert': 'An error occurred',
  'profile-get-pin-heading': 'View PIN',
  'profile-get-pin-text': 'Your PIN is <b>{pin}</b>',
  'profile-set-pin-button': 'Change PIN',
  'profile-set-pin-heading': 'Change PIN',
  'profile-get-pin-wait-text': 'This may take a minute...',
  'profile-loader-text': 'This may take a minute...',
  'profile-close-button': 'Close',
  'profile-new-pin-label': 'New PIN:',
  'profile-pin-changed-alert': 'Your PIN was changed successfully.',
  'profile-different-pin-alert':
    'Your new PIN should be different from your current PIN.',
  'profile-view-pin-button': 'View Card PIN',
  'profile-invalid-state-alert':
    'Selected Province/State does not match selected Country.',
  'profile-successful-set-pin-alert':
    'Pin successfully updated.',
  'profile-failed-set-pin-alert':
    'Pin could not be updated.',
  'profile-login-credentials':
    'Login Credentials',
  'profile-username-label':
    'Username',
  'profile-password-label':
    'Password'
};

export const profileFr = {
  'profile-heading': 'Profil de {name}',
  'profile-card-info-title': 'Informations sur la carte',
  'profile-show-sensitive-card-info-button':
    'Afficher les informations sensibles de la carte',
  'profile-card-number-text': 'Numéro de la carte:',
  'profile-card-cvv-text': 'VVC:',
  'profile-card-expiry-text': 'Date d\'expiration:',
  'profile-personal-info-title': 'Informations personnelles',
  'profile-personal-mailing-title': 'Adresse Postale',
  'profile-personal-billing-title': 'Adresse de Facturation',
  'profile-firstName-label': 'Prénom',
  'profile-middleName-label': 'Deuxième nom',
  'profile-lastName-label': 'Nom de famille',
  'profile-email-label': 'Adresse courriel',
  'profile-phone-label': 'Numéro de téléphone',
  'profile-addressLine1-label': 'Adresse 1',
  'profile-addressLine2-label': 'Adresse 2',
  'profile-country-label': 'Pays',
  'profile-province-label': 'Province',
  'profile-city-label': 'Ville',
  'profile-postalCode-label': 'Code Postal',
  'profile-update-button': 'Mise à jour',
  'profile-profile-updated-alert': 'Votre profil a été mis à jour avec succès.',
  'profile-danger-alert': 'Une erreur est survenue',
  'profile-get-pin-heading': 'Afficher NIP',
  'profile-get-pin-text': 'Votre code NIP est <b>{pin}</b>',
  'profile-set-pin-button': 'Changer NIP',
  'profile-set-pin-heading': 'Changer NIP',
  'profile-get-pin-wait-text': 'Cela peut prendre une minute ...',
  'profile-loader-text': 'Cela peut prendre une minute ...',
  'profile-close-button': 'Proche',
  'profile-new-pin-label': 'Nouveau NIP:',
  'profile-pin-changed-alert': 'Votre NIP a été modifié avec succès.',
  'profile-different-pin-alert':
    'Votre nouvelle NIP doit être différente de votre NIP actuelle.',
  'profile-view-pin-button': 'Afficher NIP de la carte',
  'profile-invalid-state-alert':
    'La province / l\'état sélectionné ne correspond pas au pays sélectionné.',
  'profile-successful-set-pin-alert':
    'Pin mis à jour avec succès.',
  'profile-failed-set-pin-alert':
    'La broche n\'a pas pu être mise à jour.',
  'profile-login-credentials':
    'Informations d\'identification',
  'profile-username-label':
    'Nom d\'utilisateur',
  'profile-password-label':
    'Mot de passe'
};
