import React, { useState } from 'react';
import PropTypes from 'prop-types';
// Import Shared Components
import {
  Modal,
  ActionBar,
  ButtonGroup,
  Loader,
  Input,
} from 'shared-components';
// Import Shared Utils
import { post } from 'shared-components/src/utils/http';
// Import Global Components
import Heading from '../../../../components/Heading';
import Alert from '../../../../components/Alert';
import CancelButton from '../../../../components/CancelButton';
import SubmitButton from '../../../../components/SubmitButton';
// Import Global Layouts
import Form from '../../../../layouts/Form';
import InputRow from '../../../../layouts/InputRow';
import InputGroup from '../../../../layouts/InputGroup';
// Import Translations
import { profileEn } from '../../../../i18n/profile';
// Import Local Layouts
import PinModal from '../../layouts/PinModal';

const SetPinModal = ({
  toggleModal,
  currentAccount,
  pin,
  setPin,
  alert,
  setAlert,
}) => {
  const [loading, setLoading] = useState(false);
  const [pinInput, setPinInput] = useState(pin);

  const page = 'profile';

  const updatePin = async params => {
    setLoading(true);
    try {
      await post(
        `api/v1/cardholder_portal/cardholder/accounts/${currentAccount.id}/set_pin`,
        params
      );

      setPin(pinInput);
      setAlert({
        message: profileEn[`${page}-pin-changed-alert`],
        type: 'success',
        messageFor: 'pin-changed',
      });

      toggleModal();
    } catch (e) {
      setAlert({ type: 'danger', message: e.message });
    }
    setLoading(false);
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (pin === pinInput)
      return setAlert({
        message: profileEn[`${page}-different-pin-alert`],
        type: 'danger',
        messageFor: 'different-pin',
      });

    const params = {
      last_four_digits: currentAccount.cards[0].last_four_digits,
      pin: pinInput,
    };

    return updatePin(params);
  };

  return (
    <Modal>
      <PinModal>
        {alert && (
          <Alert
            alert={alert.message}
            alertFor={alert.messageFor}
            page={page}
            type={alert.type}
          />
        )}
        <Heading
          page={`${page}-set-pin`}
          heading={profileEn[`${page}-set-pin-heading`]}
        />
        {loading ? (
          <Loader />
        ) : (
          <Form onSubmit={handleSubmit}>
            <InputRow>
              <InputGroup
                label={profileEn[`${page}-new-pin-label`]}
                labelFor="new-pin"
                page={page}
              >
                <Input
                  value={pinInput}
                  onChange={e => setPinInput(e.target.value)}
                  dataTestid="pin"
                  name="pin"
                  min="4"
                  maxLength="4"
                  pattern="\d{4}"
                  title="Pin must be four digits"
                />
              </InputGroup>
            </InputRow>
            <ActionBar>
              <ButtonGroup reverse>
                <SubmitButton />
                <CancelButton
                  onClick={e => {
                    e.preventDefault();
                    toggleModal(false);
                  }}
                />
              </ButtonGroup>
            </ActionBar>
          </Form>
        )}
      </PinModal>
    </Modal>
  );
};

SetPinModal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  pin: PropTypes.string.isRequired,
  setPin: PropTypes.func.isRequired,
  currentAccount: PropTypes.shape({
    id: PropTypes.number.isRequired,
    cards: PropTypes.array.isRequired, //eslint-disable-line
  }).isRequired,
  alert: PropTypes.shape({
    message: PropTypes.string.isRequired,
    messageFor: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  setAlert: PropTypes.func.isRequired,
};

export default SetPinModal;
