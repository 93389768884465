import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Text } from './styles';

const TextWrapper = ({ page, text, textFor, values, ...props }) => (
  <Text {...props}>
    <FormattedMessage
      id={`${page}-${textFor}-text`}
      description={`Text for ${textFor}`}
      defaultMessage={text}
      values={values}
    />
  </Text>
);

TextWrapper.propTypes = {
  text: PropTypes.string.isRequired,
  textFor: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
  values: PropTypes.object // eslint-disable-line react/forbid-prop-types
};

TextWrapper.defaultProps = {
  values: undefined
};

export default TextWrapper;
