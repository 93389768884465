import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Loader, ErrorComponent } from 'shared-components';
import { get, post, put } from 'shared-components/src/utils/http';
import Heading from '../../../../components/Heading';
import Title from '../../../../components/Title';
import Alert from '../../../../components/Alert';
import Button from '../../../../components/Button';
import Card from '../../../../components/Card';
import Text from '../../../../components/Text';
import useAlert from '../../../../hooks/useAlert';
import PersonToExternalTransferForm from './components/PersonToExternalTransferForm';
import { TitleContainer, TextContainer } from './styles';
import { HeadingWrapper } from '../../styles';
import CreateExternalAccountModal from '../../components/CreateExternalAccountModal';
import Transfers from './components/Transfers';
import TransferModal from '../../components/TransferModal';
import {
  requestFundsComponentName,
  transfersComponentName,
  updateFeeAmounts,
} from './utils';

const PersonToExternalTransfer = () => {
  const intl = useIntl();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useAlert();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [externalAccounts, setExternalAccounts] = useState([]);
  const [pullParams, setPullParams] = useState(null);
  const [cancelParams, setCancelParams] = useState(null);
  const [feeAmounts, setFeeAmounts] = useState({
    create: '',
    cancel: '',
  });
  const [limits, setLimits] = useState({
    max_value_per_transaction: 0,
    min_value_per_transaction: 0,
  });

  useEffect(() => {
    const fetchExternalAccounts = async () => {
      try {
        const [externalAccountsData] = await Promise.all([
          get(
            '/api/v1/cardholder_portal/cardholder/external_accounts/?type=person_to_external'
          ),
        ]);

        setExternalAccounts(externalAccountsData.data);
      } catch (e) {
        setError({ type: 'danger', message: e });
      }
      setLoading(false);
    };

    const fetchProgramFeeConfig = async () => {
      try {
        const res = await get(
          '/api/v1/cardholder_portal/cardholder/fee_configs'
        );
        const fees = updateFeeAmounts(res.data);
        setFeeAmounts(fees);
      } catch (e) {
        setError(e);
      }
    };

    const fetchLimits = async () => {
      try {
        const res = await get(
          '/api/v1/cardholder_portal/cardholder/carta_limits'
        );
        if (res.data) {
          setLimits(res.data);
        }
      } catch (e) {
        setError(e);
      }
    };

    fetchProgramFeeConfig();
    fetchExternalAccounts();
    fetchLimits();
  }, []);

  const page = 'person-to-external';

  const renderAlerts = component => {
    return (
      alert &&
      component === alert.component && (
        <Alert
          type={alert.type}
          alert={alert.message}
          alertFor={alert.messageFor}
          page={alert.page || page}
        />
      )
    );
  };

  const renderNewContactButton = () => {
    return (
      <Button
        buttonText={intl.messages[`${page}-new-contact-button`]}
        buttonFor="new-contact"
        page={page}
        onClick={e => {
          e.preventDefault();
          setShowCreateModal(true);
        }}
        type="primary"
      />
    );
  };

  const createPullTransfer = async params => {
    setLoading(true);
    try {
      // send transfer request
      await post(
        '/api/v1/cardholder_portal/cardholder/transactions/pull',
        params
      );

      // set success alert
      setAlert({
        type: 'success',
        message: intl.messages[`${page}-transfer-created-alert`],
        messageFor: 'transfer-created',
        component: requestFundsComponentName,
      });
      setLoading(false);
      setShowConfirmModal(false);
    } catch (e) {
      setAlert({
        type: 'danger',
        message: e.message,
        messageFor: 'transfer-error',
        component: requestFundsComponentName,
      });
      setLoading(false);
      setShowConfirmModal(false);
    }
  };

  const cancelPullTransfer = async params => {
    setLoading(true);
    try {
      await put(
        `/api/v1/cardholder_portal/cardholder/transactions/pull/${params.id}/cancel`,
        params
      );

      setAlert({
        type: 'success',
        message: intl.messages[`${page}-transfer-cancelled-alert`],
        messageFor: 'transfer-cancelled',
        component: transfersComponentName,
      });
      setLoading(false);
      setShowCancelModal(false);
    } catch (e) {
      setAlert({
        type: 'danger',
        message: 'Failed to cancel transfer',
        messageFor: 'transfer-error',
        component: transfersComponentName,
      });
      setLoading(false);
      setShowCancelModal(false);
    }
  };

  if (error) return <ErrorComponent />;
  if (loading) return <Loader />;

  return (
    <>
      <HeadingWrapper>
        <Heading page={page} heading={intl.messages[`${page}-heading`]} />
      </HeadingWrapper>
      <Card>
        {externalAccounts.length === 0 ? (
          <>
            {renderAlerts(requestFundsComponentName)}
            <TitleContainer>
              <Title
                title={intl.messages[`${page}-form-title`]}
                titleFor="form"
                page={page}
              />
              {renderNewContactButton()}
            </TitleContainer>
            <br />
            <TextContainer>
              <Alert
                type="info"
                alert={intl.messages[`${page}-add-contact-alert`]}
                alertFor="add-contact"
                page={page}
              />
            </TextContainer>
          </>
        ) : (
          <>
            {renderAlerts(requestFundsComponentName)}
            <TitleContainer>
              <Title
                title={intl.messages[`${page}-form-title`]}
                titleFor="form"
                page={page}
              />
              {renderNewContactButton()}
            </TitleContainer>
            <TextContainer>
              <Text
                page={page}
                textFor="form"
                text={intl.messages[`${page}-form-text`]}
              />
            </TextContainer>
            <PersonToExternalTransferForm
              setPullParams={setPullParams}
              setShowConfirmModal={setShowConfirmModal}
              disableSubmit={externalAccounts.length === 0}
              externalAccounts={externalAccounts}
              feeAmount={feeAmounts.create}
              limits={limits}
            />
          </>
        )}
      </Card>
      <Card>
        {renderAlerts(transfersComponentName)}
        <Transfers
          setShowCancelModal={setShowCancelModal}
          setCancelParams={setCancelParams}
        />
      </Card>
      {showCreateModal && (
        <CreateExternalAccountModal
          toggleModal={setShowCreateModal}
          setSuccess={setAlert}
          setExternalAccounts={setExternalAccounts}
        />
      )}
      {showConfirmModal && (
        <TransferModal
          toggleModal={setShowConfirmModal}
          confirmCallback={createPullTransfer}
          params={pullParams}
          feeAmount={feeAmounts.create}
          type="create"
          description="Text for the total amount of fee applied for a successful transfer"
        />
      )}
      {showCancelModal && (
        <TransferModal
          toggleModal={setShowCancelModal}
          confirmCallback={cancelPullTransfer}
          feeAmount={feeAmounts.cancel}
          params={cancelParams}
          type="cancel"
          description="Text for the total amount of fee applied for a cancelled transfer"
        />
      )}
    </>
  );
};

export default PersonToExternalTransfer;
