export const etransferPushEn = {
  'etransfer-push-heading': 'Send Funds',
  'etransfer-push-add-contact-alert':
    'No Contacts found, please add a new contact to continue',
  'etransfer-push-new-contact-button': 'Add Contact',
  'etransfer-push-create-contact-heading': 'Add Contact',
  'etransfer-push-update-contact-heading': 'Update Contact',
  'etransfer-push-delete-heading': 'Delete Contact',
  'etransfer-push-contact-name-input-label': 'External Account Name',
  'etransfer-push-contact-first-name-input-label': 'First Name',
  'etransfer-push-contact-last-name-input-label': 'Last Name',
  'etransfer-push-contact-email-input-label': 'Email',
  'etransfer-push-contact-confirm-email-input-label': 'Confirm Email',
  'etransfer-push-create-contact': 'add Contact',
  'etransfer-push-success-create-alert': 'Contact successfully added',
  'etransfer-push-account-deleted-alert': 'Contact successfully deleted',
  'etransfer-push-success-update-alert': 'Contact successfully updated',
  'etransfer-push-form-title': 'Send Funds to my Account',
  'etransfer-push-form-text': 'To External Contact with Interac e-Transfer',
  'etransfer-push-delete-form-text':
    'Are you sure you want to delete your contact - {name} ?',
  'etransfer-push-form-select-external-account-label':
    'Select a contact to send funds to',
  'etransfer-push-amount-label': 'Amount',
  'etransfer-push-exceeds-available-amount-alert':
    'Amount exceeds available balance',
  'etransfer-push-transfer-created-alert': 'Funds sent successfully',
  'etransfer-push-confirm-transfer-heading': 'Confirm Transfer',
  'etransfer-push-message-label': 'Message',
  'etransfer-push-confirm-transfer-message':
    'This transfer has a fee of ${feeAmount}. Do you wish to continue with this send request?', // eslint-disable-line no-template-curly-in-string
  'etransfer-push-confirm-transfer-no-fee-message':
    'Do you wish to continue with this send request?',
  'etransfer-push-security-question-label': 'Security Question:',
  'etransfer-push-security-answer-label': 'Security Answer:',
};

export const etransferPushFr = {
  'etransfer-push-heading': 'Envoyer des fonds',
  'etransfer-push-add-contact-alert':
    'Aucun contact trouvé, veuillez ajouter un nouveau contact pour continuer',
  'etransfer-push-new-contact-button': 'Ajouter un contact',
  'etransfer-push-contact-name-input-label': 'Nom de compte externe',
  'etransfer-push-contact-first-name-input-label': 'Prénom',
  'etransfer-push-contact-last-name-input-label': 'Nom de famille',
  'etransfer-push-contact-email-input-label': 'E-mail',
  'etransfer-push-contact-confirm-email-input-label': 'Confirmer l’e-mail',
  'etransfer-push-create-contact-heading': 'Ajouter un contact',
  'etransfer-push-create-contact': 'Ajouter un contact',
  'etransfer-push-update-contact-heading': 'Mettre à jour le contact',
  'etransfer-push-delete-heading': 'Supprimer le contact',
  'etransfer-push-success-create-alert': 'Contact ajouté avec succès',
  'etransfer-push-account-deleted-alert': 'Contact supprimé avec succès',
  'etransfer-push-success-update-alert': 'Contact mis à jour avec succès',
  'etransfer-push-form-title': 'Envoyer des fonds à mon compte',
  'etransfer-push-form-text':
    'Vers un contact externe avec Interac virement électronique',
  'etransfer-push-delete-form-text':
    'Êtes-vous sûr de vouloir supprimer votre contact - {name} ?',
  'etransfer-push-form-select-external-account-label':
    'Sélectionnez un contact à qui envoyer des fonds',
  'etransfer-push-amount-label': 'Montant',
  'etransfer-push-exceeds-available-amount-alert':
    'Le montant dépasse le solde disponible',
  'etransfer-push-transfer-created-alert': 'Fonds envoyés avec succès',
  'etransfer-push-confirm-transfer-heading': 'Confirmer le transfert',
  'etransfer-push-message-label': 'Message',
  'etransfer-push-confirm-transfer-message':
    'Ce virement comporte des frais de ${feeAmount}. Souhaitez-vous continuer avec cette demande d’envoi?', // eslint-disable-line no-template-curly-in-string
  'etransfer-push-confirm-transfer-no-fee-message':
    'Souhaitez-vous continuer avec cette demande d’envoi?',
  'etransfer-push-security-question-label': 'Question de sécurité :',
  'etransfer-push-security-answer-label': 'Réponse de sécurité:',
};
