import styled from 'styled-components';
import { Card as UnstyledCard } from 'shared-components';

// Wraps Card that contains authorization form and heading
export const AuthWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const Auth = styled.div`
  width: 100%;
  max-width: 700px;
  margin: 60px auto 30px;
`;

// Card that contains authorization form and heading
export const Card = styled(UnstyledCard)`
  width: 100%;
  height: auto;
  padding: 30px 20px 60px;
`;

export const AuthContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  flex: 1;

  ${({ theme }) => theme.media.tabletPortraitUp`
  max-width: 500px;
`}
`;

// Styles Heading and Sub Heading text
export const HeadingContainer = styled.div`
  text-align: center;
  margin-bottom: 30px;

  p {
    margin-top: 5px;
  }
`;

// Styles the form
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ theme }) => theme.media.tabletPortraitUp`
    input[type="submit"] {
      width: 100%;
    }

    button {
      margin-top: 10px;
    }
  `}
`;

// Contains all inputs in a form
export const InputContainer = styled.fieldset`
  box-sizing: border-box;
  margin-bottom: 45px;

  & > *:not(:last-child) {
    margin-bottom: 15px;
  }
`;
