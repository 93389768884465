import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
// Import Shared Components
import { DatePicker, CSVButton } from 'shared-components';
// Import Shared Utils
import {
  getEndOfDay,
  subtractMonth,
  currentDateTime,
  getStartAndEndDates,
} from 'shared-components/src/utils/dates';
import { formatDateForTransactionQuery } from 'shared-components/src/utils/formatDateTime';
// Import Global Components
import Card from '../../../../components/Card';
import Title from '../../../../components/Title';
// Import Local Components
import TransactionsTable from './components/TransactionsTable';
import DateRange from '../DateRange';
// Import Component Styles
import {
  TransactionsHeader,
  DatesContainer,
  ExportsContainer,
} from '../../styles';
// Import transaction CSV helper functions
import { csvHeaders, getTransactionCSVData } from './csv';
import Label from '../../../../components/Label';

const Transactions = ({ accountId, accountBalance }) => {
  // Set data States
  const [dateRange, setDateRange] = useState(1);
  const [startDate, setStartDate] = useState(
    subtractMonth(currentDateTime(), 1)
  );
  const [endDate, setEndDate] = useState(getEndOfDay());
  const [focusedInput, setFocusedInput] = useState(null);
  const [csvData, setCsvData] = useState([]);

  const intl = useIntl();

  const createIntl = key =>
    intl.formatMessage({
      id: `date-${key}-label`,
      defaultMessage: intl.messages[`date-${key}-label`],
      description: `Date ${key} label`,
    });

  const handleCsvData = data => {
    setCsvData(data);
  };

  return (
    <Card>
      <TransactionsHeader>
        <Title
          title={intl.messages['home-transactions-title']}
          titleFor="transactions"
          page="home"
        />
        <DatesContainer>
          <DateRange
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
        </DatesContainer>
        <ExportsContainer>
          <Label labelFor="export" page="home" label="Export to:" />
          <CSVButton
            data={getTransactionCSVData(csvData, intl.locale, accountBalance)}
            // Forces react-CSV component unmount and remount whenever transaction updates
            // Due to a bug in react-CSV
            key={csvData.map((trans, index) => index)}
            headers={csvHeaders}
            filename={`transactions-${startDate.toLocaleDateString()}-${endDate.toLocaleDateString()}`}
          >
            CSV
          </CSVButton>
        </ExportsContainer>
        <DatesContainer>
          <DatePicker
            toPlaceholder="MM/DD/YYYY"
            fromPlaceholder="MM/DD/YYYY"
            startDate={startDate}
            endDate={endDate}
            onDatesChange={({ startDate, endDate }) => {
              setDateRange(null);
              const { validStartDate, validEndDate } = getStartAndEndDates(
                startDate,
                endDate
              );
              setStartDate(validStartDate);
              setEndDate(validEndDate);
            }}
            focusedInput={focusedInput}
            onFocusChange={focusedInput => {
              setFocusedInput(focusedInput);
            }}
            isOutsideRange={() => false}
            showDefaultInputIcon
            toText={createIntl('to')}
            fromText={createIntl('from')}
            label="true"
          />
        </DatesContainer>
      </TransactionsHeader>
      <TransactionsTable
        accountId={accountId}
        startDate={formatDateForTransactionQuery(startDate)}
        endDate={formatDateForTransactionQuery(endDate)}
        handleCsvData={handleCsvData}
        accountBalance={accountBalance}
      />
    </Card>
  );
};

Transactions.propTypes = {
  accountId: PropTypes.number.isRequired,
  accountBalance: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    balance: PropTypes.number.isRequired,
  }).isRequired,
};

export default Transactions;
