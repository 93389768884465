import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Title } from 'shared-components';
import PropTypes from 'prop-types';

const TitleWrapper = ({ title, titleFor, page, values, ...props }) => (
  <Title {...props}>
    <FormattedMessage
      id={`${page}-${titleFor}-title`}
      description={`Title for ${title}`}
      defaultMessage={`${title}`}
      values={values}
    />
  </Title>
);

TitleWrapper.propTypes = {
  page: PropTypes.string.isRequired,
  titleFor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  values: PropTypes.object // eslint-disable-line react/forbid-prop-types
};

TitleWrapper.defaultProps = {
  values: undefined
};

export default TitleWrapper;
