import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { IssuerStatement, Footer } from 'shared-components';

export default () => {
  // Create intl instance
  const intl = useIntl();

  return (
    <Footer
      legalStatements={[
        <IssuerStatement key="1">
          <FormattedMessage
            id="issuer-statement-p1-text"
            description="First paragraph of issuer statment"
            defaultMessage={intl.messages['issuer-statement-p1-text']}
          />
        </IssuerStatement>,
        <IssuerStatement key="2">
          <FormattedMessage
            id="issuer-statement-p2-text"
            description="Second paragraph of issuer statment"
            defaultMessage={intl.messages['issuer-statement-p2-text']}
          />
        </IssuerStatement>,
        <IssuerStatement key="3">
          {intl.messages['issuer-statement-p3-text'] === '' ? null : (
            <FormattedMessage
              id="issuer-statement-p3-text"
              description="Third paragraph of issuer statment"
              defaultMessage={
                intl.messages['issuer-statement-p3-text'] || null
              }
            />
          )}
        </IssuerStatement>,
      ]}
    />
  );};
