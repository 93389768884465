import { getToken } from 'shared-components/src/utils/auth';

// Tokenization Function
export const postTellerApi = async (url, body) => {
  const { token } = getToken();

  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };

  try {
    const res = await fetch(url, options);
    const body = await res.json();

    if (body.error) {
      if (body.error.message) throw new Error(body.error.message);
      throw new Error('Failed to send request.');
    }

    return body.data;
  } catch (e) {
    throw new Error(e.message);
  }
};

// eslint-disable-next-line
const paymentAccountValidation = async body => {
  if (body.cvv)
    return postTellerApi(
      '/api/v1/cardholder_portal/payment_account_validation',
      body
    );
};

export default async cardDetails => {
  const { token } = getToken();

  const url = '/api/v1/tokens';
  const options = {
    method: 'POST',
    body: JSON.stringify(cardDetails),
    headers: {
      authorization: `Bearer ${token}`,
      'content-type': 'application/json',
    },
  };

  try {
    const res = await fetch(url, options);
    const body = await res.json();

    if (body.error) throw new Error(body.error.message);
    return body.data;
  } catch (e) {
    throw new Error(e.message);
  }
};

export const createTokenV2 = async params => {
  try {
    const data = await postTellerApi(
      '/api/v1/cardholder_portal/tokens',
      params
    );

    const { token } = data;
    const { cvv } = params.card;
    try {
      await Promise.all([
        // If card tokenizes and cvv details are present in params we should run a payment account validation call
        paymentAccountValidation({ token, cvv }),
        postTellerApi('/api/v1/cardholder_portal/funds_transfer_inquiry', {
          token,
        }),
        postTellerApi('/api/v1/cardholder_portal/watchlist_inquiry', { token }),
      ]);
    } catch (e) {
      throw new Error(e.message);
    }

    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};

export const createTokenV1 = async params => {
  try {
    const data = await postTellerApi(
      '/api/v1/cardholder_portal/tokens',
      params
    );

    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};

