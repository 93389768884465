import { PROVINCES, STATES } from 'shared-components/src/utils/provinces';
import { getToken } from 'shared-components/src/utils/auth';

// Checks if dropdown options for state are for the selected country
export const checkSelectedState = (cardholder, keys, countryCode) => keys.includes(cardholder.state) && cardholder.country === countryCode;

// Retrieves an array of state options keys for the dropdown input
export const stateKeys = STATES.map(s => s.key);
export const provinceKeys = PROVINCES.map(p => p.key);

// verify login credentials so user can change password
export const verifyLoginCreds = async payload => {
  const { token } = getToken();

  return fetch('/api/v1/cardholder_portal/verify_login_credentials', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  })
    .then(res => res.json())
    .then(body => {
      if (body.error) throw new Error(body.error.message);
      return body;
    });
};