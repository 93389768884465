import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
// Import Global Components
import {
  getEndOfDay,
  subtractMonth,
  currentDateTime
} from 'shared-components/src/utils/dates';
// Import Global Components
import Label from '../../../../../../components/Label';
// Import Component Styles
import { DateRange, DateRangeButtons, Button } from './styles';

const DateRangeWrapper = ({ setStartDate, setEndDate, dateRange, setDateRange }) => {
  const intl = useIntl();

  // Set Handlers
  const handleOnClick = (e, key) => {
    e.preventDefault();
    const today = getEndOfDay();
    const dateInPast = subtractMonth(currentDateTime(), key);
    setEndDate(today);
    setStartDate(dateInPast);
    setDateRange(key);
  };
  // Date ranges for viewing
  const ranges = [1, 3, 6];

  return (
    <DateRange>
      <Label labelFor='view' page='home' label='View' />
      <DateRangeButtons>
        {ranges.map(range => (
          <Button
            page='home'
            buttonFor='month'
            buttonText={intl.messages['home-month-button']}
            key={range}
            values={{
              range
            }}
            variant='text'
            type={range === dateRange ? 'primary' : 'default'}
            active={range === dateRange}
            onClick={e => handleOnClick(e, range)}
          />
        ))}
      </DateRangeButtons>
    </DateRange>
  );
};

DateRangeWrapper.propTypes = {
  setStartDate: PropTypes.func.isRequired,
  setEndDate: PropTypes.func.isRequired,
  dateRange: PropTypes.number.isRequired,
  setDateRange: PropTypes.func.isRequired
};

export default DateRangeWrapper;
