import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
// Import Shared Components
import { Modal, ActionBar, ButtonGroup, Loader } from 'shared-components';
// Import Global Components
import Heading from '../../../../components/Heading';
import Text from '../../../../components/Text';
import DeleteButton from '../../../../components/DeleteButton';
import CancelButton from '../../../../components/CancelButton';
import Alert from '../../../../components/Alert';
// Import Global Layouts
import { ModalFormContainer } from '../../../../layouts/Form';

const ConfirmDeleteModal = ({
  toggleModal,
  loading,
  alert,
  deleteItemValue,
  deleteItemName,
  deleteCallback,
  page,
}) => {
  const intl = useIntl();

  const handleSubmit = e => {
    e.preventDefault();
    deleteCallback(deleteItemValue);
  };

  return (
    <Modal>
      <ModalFormContainer>
        {alert && (
          <Alert
            alert={alert.message}
            alertFor={alert.type}
            page={page}
            type={alert.type}
          />
        )}
        <Heading
          page={`${page}-delete`}
          heading={intl.messages[`${page}-delete-heading`]}
        />
        {loading ? (
          <Loader />
        ) : (
          <>
            <Text
              text={intl.messages[`${page}-delete-form-text`]}
              textFor="delete-form"
              page={page}
              values={{
                b: chunks => <b>{chunks}</b>,
                name: deleteItemName,
              }}
            />
            <form onSubmit={e => handleSubmit(e)}>
              <input type="hidden" value={deleteItemValue} />
              <ActionBar>
                <ButtonGroup reverse>
                  <DeleteButton />
                  <CancelButton onClick={e => toggleModal(e)} />
                </ButtonGroup>
              </ActionBar>
            </form>
          </>
        )}
      </ModalFormContainer>
    </Modal>
  );
};

ConfirmDeleteModal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  deleteCallback: PropTypes.func.isRequired,
  alert: PropTypes.objectOf({
    message: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    messageFor: PropTypes.string,
  }),
  page: PropTypes.string.isRequired,
  deleteItemValue: PropTypes.string.isRequired,
  deleteItemName: PropTypes.string.isRequired,
};

ConfirmDeleteModal.defaultProps = {
  alert: null,
};

export default ConfirmDeleteModal;
