import styled from 'styled-components';
import Button from '../../../../components/Button';

export const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.brand.headerText};
  padding: 0;
  opacity: 0.9;

  &:hover {
    opacity: 1;
    text-decoration: underline;
  }

  &:active {
    opacity: 0.5;
  }
`;
