import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ color, size }) => (
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 115 115">
    <defs>
      <style jsx="true">{`.expiry-1{opacity:0.2;}.expiry-2{fill:${color};}`}</style>
    </defs>
    <title>images</title>
    <g className="expiry-1">
      <circle className="expiry-2" cx="56.5" cy="56.5" r="55.5" />
    </g>
    <path className="expiry-2" d="M56.5,113A56.5,56.5,0,1,1,113,56.5,56.56,56.56,0,0,1,56.5,113Zm0-111A54.5,54.5,0,1,0,111,56.5,54.56,54.56,0,0,0,56.5,2Z" />
    <path className="expiry-2" d="M57.39,74.28V60.06h16V50.27H39.61V76.06H57.39Zm8-22.23h6.23v6.23H65.39Zm-8,0h6.23v6.23H57.39ZM47.61,74.28H41.39V68.06h6.22Zm0-8H41.39V60.06h6.22Zm0-8H41.39V52.05h6.22Zm8,16H49.39V68.06h6.23Zm0-8H49.39V60.06h6.23Zm-6.23-8V52.05h6.23v6.23Z" />
    <path className="expiry-2" d="M57.58,81.39H33.38V44.94H79.62V59.17a.89.89,0,0,0,.89.89h0a.89.89,0,0,0,.89-.89V34.27a.89.89,0,0,0-.89-.89H74.28V30.71a.88.88,0,0,0-.88-.88H67.17a.89.89,0,0,0-.89.89v2.67H46.72V30.72a.89.89,0,0,0-.89-.89H39.61a.89.89,0,0,0-.89.89v2.67H32.49a.89.89,0,0,0-.89.89v48a.89.89,0,0,0,.89.89H57.58a.89.89,0,0,0,.89-.89h0A.89.89,0,0,0,57.58,81.39ZM68.06,31.61H72.5v5.33H68.06Zm-27.56,0h4.45v5.33H40.5Zm-7.11,3.56h5.33v2.67a.89.89,0,0,0,.89.89h6.23a.89.89,0,0,0,.89-.89V35.16H66.28v2.67a.89.89,0,0,0,.89.89h6.23a.88.88,0,0,0,.88-.88V35.16h5.34v8H33.38Z" />
    <path className="expiry-2" d="M79.16,77.94H72.35a1,1,0,0,1-1-1V70.22a1,1,0,0,1,2,0v5.72h5.81a1,1,0,0,1,0,2Z" />
    <path className="expiry-2" d="M73.39,62.9A12.38,12.38,0,1,0,85.78,75.28,12.38,12.38,0,0,0,73.39,62.9Zm0,22.9A10.52,10.52,0,1,1,83.92,75.28,10.53,10.53,0,0,1,73.39,85.8Z" />
    <script xmlns="" />
  </svg>
);

Icon.propTypes = {
  color: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired
};

export default Icon;