import React from 'react';
import PropTypes from 'prop-types';
// Import Global Components
import Card from '../../../../../../components/Card';
import Label from '../../../../../../components/Label';
// Import Local Components
import Icon from '../WidgetIcon';
// Import Component Styles
import { WidgetData, WidgetContainer, WidgetDataContainer } from './styles';

const Widget = ({ widgetLabel, widgetType, children }) => (
  <Card>
    <WidgetContainer>
      <Icon type={widgetType} />
      <WidgetDataContainer>
        <Label page="home" labelFor={widgetType} label={widgetLabel} />
        <WidgetData>{children}</WidgetData>
      </WidgetDataContainer>
    </WidgetContainer>
  </Card>
);

Widget.propTypes = {
  widgetLabel: PropTypes.string.isRequired,
  widgetType: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Widget;
