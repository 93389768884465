import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Icon } from 'shared-components';
// Import Translations
import { authEn } from '../../../../i18n/auth';
// Import Component Styles
import { Alert as StyledAlert, IconWrapper } from './styles';

function messageToRender(type) {
  switch (type) {
  case 'alreadyactivated':
    return (
      <p>
        <FormattedMessage
          id="auth-card-already-activated-message"
          description="Card already activated message"
          defaultMessage={authEn['auth-card-already-activated-message']}
          values={{
            a: chunks => (
              <a href="/register" target="_blank">
                {chunks}
              </a>
            ),
          }}
        />
      </p>
    );
  case 'newlyactivated':
    return (
      <p>
        <FormattedMessage
          id="auth-card-activated-message"
          description="Card already activated message"
          defaultMessage={authEn['auth-card-activated-message']}
          values={{
            a: chunks => (
              <a href="/register" target="_blank">
                {chunks}
              </a>
            ),
          }}
        />
      </p>
    );
  default:
    return null;
  }
}

const ActivationMessage = ({ type }) => (
  <>
    <StyledAlert type="success">
      <IconWrapper>
        <Icon type="success" />
      </IconWrapper>
      {messageToRender(type)}
    </StyledAlert>
    <StyledAlert type="success">
      <IconWrapper>
        <Icon type="success" />
      </IconWrapper>
      <p>
        <FormattedMessage
          id="auth-card-registered-login-link"
          description="Go to login"
          defaultMessage={authEn['auth-card-registered-login-link']}
          values={{
            a: chunks => <a href="/login" target="_blank">{chunks}</a>
          }}
        />
      </p>
    </StyledAlert>
  </>
);

ActivationMessage.propTypes = {
  type: PropTypes.oneOf(['alreadyactivated', 'newlyactivated']).isRequired,
};

export default ActivationMessage;
