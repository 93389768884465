export const accountToAccountEn = {
  'account-to-account-heading': 'Send Money',
  'account-to-account-manage-accounts-button': 'Manage Accounts',
  'account-to-account-form-title': 'Card to Card',
  'account-to-account-form-text':
    'Move money from your card to another within the same program.',
  'account-to-account-recipient-label': 'Recipient:',
  'account-to-account-amount-label': 'Amount:',
  'account-to-account-transfer-name-label': 'Transfer Name:',
  'account-to-account-message-label': 'Message:',
  'account-to-account-name-label': 'Name:',
  'account-to-account-email-label': 'Email:',
  'account-to-account-proxy-label': 'Proxy Number:',
  'account-to-account-create-heading': 'Add New Recipient',
  'account-to-account-new-account-button': 'Add New Recipient',
  'account-to-account-manage-recipients-button': 'Manage Recipients',
  'account-to-account-information1-text':
    'Please enter the name of the person you want to send money to, including their email address and the proxy number associated with their Prepaid Card.',
  'account-to-account-information2-text':
    '*Note that you will need to ask your friend or family member to provide you with their “proxy card number” which can be found on the back of their card.',
  'account-to-account-success-create-alert':
    'Your recipients\' account was created successfully',
  'account-to-account-transfer-created-alert':
    'A transfer to your recipients\' account was created successfully.',
  'account-to-account-add-recipient-alert':
    'Please add your first recipient account to begin creating card to card transfers.',
  'account-to-account-exceeds-available-amount-alert':
    'The amount you wish to send exceeds available account balance.',
  'account-to-account-total-fee-text':
    // eslint-disable-next-line no-template-curly-in-string
    'There is a ${feeAmount} fee for this transfer.',
};

export const accountToAccountFr = {
  'account-to-account-heading': 'Envoyer de l\'argent',
  'account-to-account-form-title': 'Carte à carte',
  'account-to-account-form-text':
    'Transférez de l\'argent de votre carte à une autre dans le cadre du même programme.',
  'account-to-account-recipient-label': 'Bénéficiaire:',
  'account-to-account-amount-label': 'Montant:',
  'account-to-account-transfer-name-label': 'Nom du transfert:',
  'account-to-account-message-label': 'Le message:',
  'account-to-account-name-label': 'Nom:',
  'account-to-account-email-label': 'Email:',
  'account-to-account-proxy-label': 'Numéro de proxy:',
  'account-to-account-create-heading': 'Ajouter un nouveau destinataire',
  'account-to-account-new-account-button': 'Ajouter un nouveau destinataire',
  'account-to-account-manage-recipients-button': 'Gérer les destinataires',
  'account-to-account-information1-text':
    'Veuillez saisir le nom de la personne à laquelle vous souhaitez envoyer de l\'argent, y compris son adresse e-mail et le numéro de procuration associé à sa carte prépayée.',
  'account-to-account-information2-text':
    '* Notez que vous devrez demander à votre ami ou membre de votre famille de vous fournir son «numéro de carte proxy» qui se trouve au dos de sa carte.',
  'account-to-account-success-create-alert':
    'Le compte de vos destinataires a été créé avec succès.',
  'account-to-account-transfer-created-alert':
    'Un transfert vers le compte de vos destinataires a été créé avec succès.',
  'account-to-account-add-recipient-alert':
    'S\'il vous plaît ajouter votre premier compte destinataire pour commencer à créer carte pour les transferts de carte.',
  'account-to-account-exceeds-available-amount-alert':
    'Le montant du transfert dépasse le solde disponible du compte.',
  'account-to-account-total-fee-text':
    'Il y a des frais de {feeAmount} $ pour ce transfert.',
};
