import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
// Import Shared Utils
import { post } from 'shared-components/src/utils/http';
// Import Local Components
import CreateEtransferForm from './components/CreateEtransferForm';
import CreatePaymentCardForm from './components/CreatePaymentCardForm';
// Import Utils
import {
  createTokenV2,
  createTokenV1,
} from '../../../../../../utils/tokenization';

// External Transfer to a Personal Bank Account
const AddPaymentMethod = ({
  setAlert,
  personalAccounts,
  setPersonalAccounts,
  etransferConfig,
  selectedNetwork,
}) => {
  const intl = useIntl();

  // Component States
  const [loading, setLoading] = useState(false);

  const page = 'personal-account';

  const createPersonalAccount = async (
    tokenizationParams,
    externalAccountParams
  ) => {
    try {
      // tokenize card
      const tokenizationData = tokenizationParams.card
        ? await createTokenV2(tokenizationParams)
        : await createTokenV1(tokenizationParams);

      // create external account
      const res = await post(
        '/api/v1/cardholder_portal/cardholder/external_accounts',
        {
          token: tokenizationData.token,
          ...externalAccountParams,
        }
      );

      setPersonalAccounts([...personalAccounts, res.data]);

      setAlert({
        type: 'success',
        message: intl.messages[`${page}-success-create-alert`],
        messageFor: 'success-create',
      });
      setLoading(false);
    } catch (e) {
      setAlert({ type: 'danger', message: e.message });
      setLoading(false);
    }
  };

  // eslint-disable-next-line consistent-return
  const renderBody = () => {
    if (selectedNetwork === 'visa')
      return (
        <CreatePaymentCardForm
          setAlert={setAlert}
          createPersonalAccount={createPersonalAccount}
          loading={loading}
          setLoading={setLoading}
        />
      );

    if (selectedNetwork === 'etransfer')
      return (
        <CreateEtransferForm
          setAlert={setAlert}
          createPersonalAccount={createPersonalAccount}
          loading={loading}
          setLoading={setLoading}
          etransferConfig={etransferConfig}
        />
      );
  };
  return renderBody();
};

AddPaymentMethod.propTypes = {
  setAlert: PropTypes.func.isRequired,
  personalAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  setPersonalAccounts: PropTypes.func.isRequired,
  etransferConfig: PropTypes.string.isRequired,
  selectedNetwork: PropTypes.string.isRequired,
};

export default AddPaymentMethod;
