import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
// Import Shared Components
import { ActionBar, ButtonGroup, Loader, Input } from 'shared-components';
// Import Global Layouts
import InputGroup from '../../../../../../../layouts/InputGroup';
import InputRow from '../../../../../../../layouts/InputRow';
import Form from '../../../../../../../layouts/Form';
import Fieldset from '../../../../../../../layouts/Fieldset';
// Import Global Components
import Text from '../../../../../../../components/Text';
import SubmitButton from '../../../../../../../components/SubmitButton';
// Import Local Components
import NotificationMethod from './NotificationMethod';
// Import component styles
import { HeadingContainer } from '../styles';
import { Legend } from '../../../styles';

// Form to link to Cardholder's Bank Account through Interac
const CreateEtransferForm = ({
  setAlert,
  createPersonalAccount,
  etransferConfig,
  loading,
  setLoading,
}) => {
  const intl = useIntl();

  // Input refs
  const accountNameInput = useRef(null);
  const nameInput = useRef(null);
  const emailInput = useRef(null);
  const phoneInput = useRef(null);

  const initialNotificationMethod =
    etransferConfig === 'both' ? 'email' : etransferConfig;

  // Data states
  const [notificationMethod, setNotificationMethod] = useState(
    initialNotificationMethod
  );

  const page = 'personal-account';

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    setAlert(null);

    const basicParams = { name: nameInput.current.value };

    const tokenizationParams =
      notificationMethod === 'email'
        ? { etransfer: { email: emailInput.current.value, ...basicParams } }
        : {
          etransfer: {
            phone_number: phoneInput.current.value,
            ...basicParams,
          },
        };
    const externalAccountParams = {
      external_account_name: accountNameInput.current.value,
      type: 'personal_account',
    };

    createPersonalAccount(tokenizationParams, externalAccountParams);
  };

  // eslint-disable-next-line consistent-return
  const renderInput = () => {
    if (notificationMethod === 'email')
      return (
        <InputRow>
          <InputGroup
            label={intl.messages['email-label']}
            labelFor="email"
            required
          >
            <Input
              ref={emailInput}
              required
              dataTestId="email"
              name="email"
              autocomplete="email"
              type="email"
            />
          </InputGroup>
        </InputRow>
      );

    if (notificationMethod === 'sms')
      return (
        <InputRow>
          <InputGroup
            label={intl.messages['phone-label']}
            labelFor="phone"
            required
          >
            <Input
              ref={phoneInput}
              required
              dataTestId="phone"
              name="phone"
              autocomplete="phone"
              type="tel"
              pattern="[0-9]{10}"
              title={intl.formatMessage({ id: 'phone-input-title' })}
            />
          </InputGroup>
        </InputRow>
      );
  };

  return (
    <>
      <HeadingContainer>
        <Text
          text={intl.messages[`${page}-etransfer-info-text`]}
          textFor="etransfer-info"
          page={page}
        />
      </HeadingContainer>
      {loading ? (
        <Loader />
      ) : (
        <Form onSubmit={e => handleSubmit(e)}>
          <InputRow>
            <InputGroup
              label={intl.messages['account-name-label']}
              labelFor="account-name"
              required
            >
              <Input
                ref={accountNameInput}
                required
                dataTestId="account-name"
                name="account-name"
                min="1"
                max="150"
                autocomplete="account-name"
                placeholder="Ex: TD Visa Debit"
              />
            </InputGroup>
          </InputRow>
          <Fieldset>
            <Legend
              legend={intl.messages[`${page}-etransfer-details-section-legend`]}
              legendFor="etransfer-details-section"
              page={page}
            />
            <InputRow>
              <InputGroup
                label={intl.messages['name-label']}
                labelFor="name"
                required
              >
                <Input
                  ref={nameInput}
                  required
                  dataTestId="etransfer-name"
                  name="etransfer-name"
                  min="1"
                  max="150"
                  autocomplete="etransfer-name"
                />
              </InputGroup>
            </InputRow>
            {etransferConfig === 'both' && (
              <InputRow style={{ marginBottom: '5px' }}>
                <NotificationMethod
                  method="email"
                  labelFor="email-2"
                  setNotificationMethod={setNotificationMethod}
                  selectedMethod={notificationMethod}
                />
                <NotificationMethod
                  method="sms"
                  labelFor="sms"
                  setNotificationMethod={setNotificationMethod}
                  selectedMethod={notificationMethod}
                />
              </InputRow>
            )}
            {renderInput()}
          </Fieldset>
          <ActionBar>
            <ButtonGroup reverse>
              <SubmitButton />
            </ButtonGroup>
          </ActionBar>
        </Form>
      )}
    </>
  );
};

CreateEtransferForm.propTypes = {
  setAlert: PropTypes.func.isRequired,
  createPersonalAccount: PropTypes.func.isRequired,
  etransferConfig: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
};

export default CreateEtransferForm;
