import React from 'react';
import { withRouter } from 'react-router-dom';
// Import I18n Translations
import { authEn } from '../../../i18n/auth';
// Import Global Components
import Text from '../../../components/Text';
// Import Local Layouts
import AuthLayout from '../layouts/Auth';
import { AuthNonForm } from '../layouts/InnerAuth';

export default withRouter(props => {
  const page = 'invalid-link';

  return (
    <AuthLayout
      page={page}
      heading={authEn[`${page}-heading`]}
      subHeader={authEn[`${page}-subheader-text`]}
    >
      <AuthNonForm
        page={page}
        onClick={() => props.history.push('/forgot-password')}
        buttonText={authEn[`${page}-auth-button`]}
      >
        <Text
          page={page}
          text={authEn[`${page}-reason-text`]}
          textFor="reason"
        />
      </AuthNonForm>
    </AuthLayout>
  );
});
