import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
// Import Component Styles
import { Heading } from './styles';

const HeadingWrapper = ({ page, heading, values }) => (
  <Heading>
    <FormattedMessage
      id={`${page}-heading`}
      description={`Heading for ${page} page`}
      defaultMessage={heading}
      values={values}
    />
  </Heading>
);

HeadingWrapper.propTypes = {
  page: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.object,
};

HeadingWrapper.defaultProps = {
  values: undefined,
};

export default HeadingWrapper;
