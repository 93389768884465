import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
// Import Shared Components
import {
  Modal,
  ActionBar,
  ButtonGroup,
  Loader,
  Input,
} from 'shared-components';
// Import Shared Utils
import { post } from 'shared-components/src/utils/http';
// Import Global Layouts
import InputGroup from '../../../../layouts/InputGroup';
import InputRow from '../../../../layouts/InputRow';
import Form, { ModalFormContainer } from '../../../../layouts/Form';
// Import Global Components
import Heading from '../../../../components/Heading';
import Alert from '../../../../components/Alert';
import Text from '../../../../components/Text';
import SubmitButton from '../../../../components/SubmitButton';
import CancelButton from '../../../../components/CancelButton';
// Import Translations
import { accountToAccountEn } from '../../../../i18n/accountToAccount';

import { HeadingContainer } from './styles';

const CreateRecipientAccountModal = ({
  toggleModal,
  setSuccess,
  recipientAccounts,
  setRecipientAccounts,
}) => {
  const recipientNameInput = useRef(null);
  const recipientEmailInput = useRef(null);
  const processReferenceInput = useRef(null);

  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);

  const page = 'account-to-account';

  // post request to handle creation of recipient account
  const createRecipientAccount = async params => {
    try {
      // create external account
      const res = await post(
        '/api/v1/cardholder_portal/cardholder/recipient_accounts',
        params
      );

      setRecipientAccounts([res.data, ...recipientAccounts]);
      setSuccess({
        type: 'success',
        message: accountToAccountEn[`${page}-success-create-alert`],
        messageFor: 'success-create',
        page,
      });
      setLoading(false);
      toggleModal(false);
    } catch (e) {
      setAlert({ type: 'danger', message: e.message });
      setLoading(false);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    setAlert(null);

    const params = {
      recipient_params: {
        name: recipientNameInput.current.value,
        email: recipientEmailInput.current.value,
      },
      processor_reference: processReferenceInput.current.value,
    };

    createRecipientAccount(params);
  };

  return (
    <Modal>
      <ModalFormContainer>
        {alert && (
          <Alert
            alert={alert.message}
            alertFor={alert.messageFor}
            page={page}
            type={alert.type}
          />
        )}
        <HeadingContainer>
          <Heading
            page={`${page}-create`}
            heading={accountToAccountEn[`${page}-create-heading`]}
          />
          <Text
            text={accountToAccountEn[`${page}-information1-text`]}
            textFor="information1"
            page={page}
          />
          <Text
            text={accountToAccountEn[`${page}-information2-text`]}
            textFor="information2"
            page={page}
          />
        </HeadingContainer>
        {loading ? (
          <Loader />
        ) : (
          <Form onSubmit={e => handleSubmit(e)}>
            <InputRow>
              <InputGroup
                label={accountToAccountEn[`${page}-name-label`]}
                labelFor="name"
                page={page}
                required
              >
                <Input
                  ref={recipientNameInput}
                  required
                  dataTestid="name"
                  name="name"
                  min="1"
                  max="30"
                  autocomplete="name"
                />
              </InputGroup>
            </InputRow>
            <InputRow>
              <InputGroup
                label={accountToAccountEn[`${page}-email-label`]}
                labelFor="email"
                page={page}
                required
              >
                <Input
                  ref={recipientEmailInput}
                  required
                  dataTestId="email"
                  name="email"
                  min="13"
                  max="19"
                  autocomplete="email"
                  type="email"
                />
              </InputGroup>
            </InputRow>
            <InputRow>
              <InputGroup
                label={accountToAccountEn[`${page}-proxy-label`]}
                labelFor="proxy"
                page={page}
                required
              >
                <Input
                  ref={processReferenceInput}
                  required
                  dataTestId="proxy"
                  name="proxy"
                  min="13"
                  max="19"
                  autocomplete="proxy"
                />
              </InputGroup>
            </InputRow>

            <ActionBar>
              <ButtonGroup reverse>
                <SubmitButton />
                <CancelButton
                  onClick={e => {
                    e.preventDefault();
                    toggleModal(false);
                  }}
                />
              </ButtonGroup>
            </ActionBar>
          </Form>
        )}
      </ModalFormContainer>
    </Modal>
  );
};

CreateRecipientAccountModal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
  recipientAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      cardholder_id: PropTypes.number.isRequired,
      created_at: PropTypes.string.isRequired,
      dest_account_id: PropTypes.number.isRequired,
      email: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      updated_at: PropTypes.string.isRequired,
    })
  ).isRequired,
  setRecipientAccounts: PropTypes.func.isRequired,
};

export default CreateRecipientAccountModal;
