import { getFormattedTransactionDate } from 'shared-components/src/utils/cardholders';

export const csvHeaders = [
  { label: 'Transfer Date', key: 'created_at' },
  { label: 'Transfer Type', key: 'transfer_type' },
  { label: 'Status', key: 'status' },
  { label: 'Name', key: 'name' },
  { label: 'Email', key: 'email' },
  { label: 'Amount', key: 'amount' },
];

export const getTransfersCSVData = (transfers, locale) => {
  return transfers.map(trans => {
    return {
      amount: trans.amount,
      transfer_type: trans.transfer_type,
      name: trans.name,
      email: trans.email,
      status: trans.status,
      created_at: getFormattedTransactionDate(trans.created_at, locale),
    };
  });
};
