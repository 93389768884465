export const inputsEn = {
  'phone-input-title':
    'Must contain 10 digits that includes area code and phone number. May not contain dashes or spaces',
  'account-name-input-placeholder': 'Ex: TD Visa Debit',
  'cvv-input-title': 'Please enter 3 or 4 Digit CVV',
  'expiry-year-input-title': 'Please enter expiry year as YYYY',
  'expiry-month-input-title': 'Please enter expiry month as MM',
  'card-number-input-title': 'Please enter a valid payment card number',
  'select-placeholder': 'Select...',
};

export const inputsFr = {
  'phone-input-title':
    'Doit contenir 10 chiffres comprenant l\'indicatif régional et le numéro de téléphone. Ne peut contenir ni tirets ni espaces ',
  'account-name-input-placeholder': 'Ex: débit Visa TD ',
  'cvv-input-title': 'Veuillez saisir un CVV à 3 ou 4 chiffres',
  'expiry-year-input-title':
    'Veuillez saisir l\'année d\'expiration sous la forme AAAA',
  'expiry-month-input-title':
    'Veuillez saisir le mois d\'expiration sous la forme MM ',
  'card-number-input-title':
    'Veuillez saisir un numéro de carte de paiement valide ',
  'select-placeholder': 'choisir...',
};
