import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
// Import Shared Components
import { ActionBar, ButtonGroup, Loader, Input } from 'shared-components';
// Import Shared Utils
import PROVINCES from 'shared-components/src/utils/provinces';
import COUNTRIES from 'shared-components/src/utils/countries';
import { VISA_ANI_VALIDATION } from 'shared-components/src/utils/services';
// Import Global Layouts
import InputGroup from '../../../../../../../layouts/InputGroup';
import InputRow from '../../../../../../../layouts/InputRow';
import Form from '../../../../../../../layouts/Form';
import Fieldset from '../../../../../../../layouts/Fieldset';
// Import Global Components
import Text from '../../../../../../../components/Text';
import SubmitButton from '../../../../../../../components/SubmitButton';
// Import component styles
import { HeadingContainer } from '../styles';
import { Legend } from '../../../styles';
import { ServiceConfigContext } from '../../../../../../../contexts/ServiceConfigContext';

// Form to link to Cardholder's Bank Account through Visa
const CreatePaymentCardForm = ({
  setAlert,
  createPersonalAccount,
  loading,
  setLoading,
}) => {
  const intl = useIntl();

  const cardholderNameInput = useRef(null);
  const cardNumberInput = useRef(null);
  const firstNameInput = useRef(null);
  const middleNameInput = useRef(null);
  const lastNameInput = useRef(null);
  const expiryMonthInput = useRef(null);
  const expiryYearInput = useRef(null);
  const cvvInput = useRef(null);
  const addressLine1Input = useRef(null);
  const addressLine2Input = useRef(null);
  const addressPostalCodeInput = useRef(null);
  const addressCityInput = useRef(null);
  const addressStateInput = useRef(null);
  const addressCountryInput = useRef(null);
  const accountNameInput = useRef(null);

  const serviceConfiguration = useContext(ServiceConfigContext);

  const page = 'personal-account';

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    setAlert(null);

    const tokenizationParams = {
      card: {
        name: cardholderNameInput.current.value,
        number: cardNumberInput.current.value,
        cvv: cvvInput.current.value,
        expiry_month: expiryMonthInput.current.value,
        expiry_year: expiryYearInput.current.value,
        address_city: addressCityInput.current.value,
        address_country: addressCountryInput.current.value,
        address_line1: addressLine1Input.current.value,
        address_line2: addressLine2Input.current.value,
        address_state: addressStateInput.current.value,
        address_postal_code: addressPostalCodeInput.current.value,
      },
    };

    if (serviceConfiguration && serviceConfiguration[VISA_ANI_VALIDATION]) {
      tokenizationParams.card.first_name = firstNameInput.current.value;
      tokenizationParams.card.middle_name = middleNameInput.current.value;
      tokenizationParams.card.last_name = lastNameInput.current.value;
    }

    const personalAccountParams = {
      external_account_name: accountNameInput.current.value,
      type: 'personal_account',
    };

    createPersonalAccount(tokenizationParams, personalAccountParams);
  };

  return (
    <>
      <HeadingContainer>
        <Text
          text={intl.messages[`${page}-information-text`]}
          textFor="information"
          page={page}
        />
      </HeadingContainer>
      {loading ? (
        <Loader />
      ) : (
        <Form onSubmit={e => handleSubmit(e)}>
          <Fieldset>
            <InputRow>
              <InputGroup
                label={intl.messages['account-name-label']}
                labelFor="account-name"
                required
              >
                <Input
                  ref={accountNameInput}
                  required
                  dataTestId="account-name"
                  name="account-name"
                  min="1"
                  max="150"
                  autocomplete="account-name"
                  placeholder={intl.formatMessage({
                    id: 'account-name-input-placeholder',
                  })}
                />
              </InputGroup>
            </InputRow>
          </Fieldset>
          <Fieldset>
            <Legend
              legend={intl.messages[`${page}-card-details-section-legend`]}
              legendFor="card-details-section"
              page={page}
            />
            <InputRow>
              <InputGroup
                label={intl.messages[`${page}-card-name-label`]}
                labelFor="card-name"
                page={page}
                required
              >
                <Input
                  ref={cardholderNameInput}
                  required
                  dataTestid="cc-name"
                  name="cc-name"
                  min="1"
                  max="30"
                  autocomplete="cc-name"
                />
              </InputGroup>
            </InputRow>
            {serviceConfiguration && serviceConfiguration[VISA_ANI_VALIDATION] && (
              <>
                <InputRow>
                  <InputGroup
                    label={intl.messages[`${page}-given-name-label`]}
                    labelFor="given-name"
                    page={page}
                  >
                    <Input
                      ref={firstNameInput}
                      dataTestid="given-name"
                      name="given-name"
                      min="1"
                      max="35"
                      autocomplete="given-name"
                    />
                  </InputGroup>
                </InputRow>
                <InputRow>
                  <InputGroup
                    label={intl.messages[`${page}-additional-name-label`]}
                    labelFor="additional-name"
                    page={page}
                  >
                    <Input
                      ref={middleNameInput}
                      dataTestid="additional-name"
                      name="additional-name"
                      min="1"
                      max="35"
                      autocomplete="additional-name"
                    />
                  </InputGroup>
                </InputRow>
                <InputRow>
                  <InputGroup
                    required
                    label={intl.messages[`${page}-family-name-label`]}
                    labelFor="family-name"
                    page={page}
                  >
                    <Input
                      ref={lastNameInput}
                      required
                      dataTestid="family-name"
                      name="family-name"
                      min="1"
                      max="35"
                      autocomplete="family-name"
                    />
                  </InputGroup>
                </InputRow>
              </>
            )}
            <InputRow>
              <InputGroup
                label={intl.messages[`${page}-card-number-label`]}
                labelFor="card-number"
                page={page}
                required
              >
                <Input
                  ref={cardNumberInput}
                  required
                  dataTestId="cc-number"
                  name="cc-number"
                  min="13"
                  max="19"
                  autocomplete="cc-number"
                  title={intl.formatMessage({
                    id: 'card-number-input-title',
                  })}
                  pattern="[0-9]{13,19}"
                  uncontrolled
                />
              </InputGroup>
            </InputRow>
            <InputRow>
              <InputGroup
                label={intl.messages[`${page}-expiry-month-label`]}
                labelFor="expiry-month"
                page={page}
                required
              >
                <Input
                  ref={expiryMonthInput}
                  required
                  dataTestid="cc-exp-month"
                  name="cc-exp-month"
                  placeholder="MM"
                  min="2"
                  max="2"
                  autocomplete="cc-exp-month"
                  pattern="[0-9]{2}"
                  title={intl.formatMessage({
                    id: 'expiry-month-input-title',
                  })}
                  uncontrolled
                />
              </InputGroup>
              <InputGroup
                label={intl.messages[`${page}-expiry-year-label`]}
                labelFor="expiry-year"
                page={page}
                required
              >
                <Input
                  ref={expiryYearInput}
                  required
                  placeholder="YYYY"
                  dataTestid="cc-exp-year"
                  name="cc-exp-year"
                  min="4"
                  max="4"
                  autocomplete="cc-exp-year"
                  pattern="[0-9]{4}"
                  title={intl.formatMessage({
                    id: 'expiry-year-input-title',
                  })}
                  uncontrolled
                />
              </InputGroup>
              <InputGroup
                label={intl.messages[`${page}-cvv-label`]}
                labelFor="cvv"
                page={page}
                required
              >
                <Input
                  ref={cvvInput}
                  required
                  type="password"
                  dataTestid="cc-csc"
                  name="cc-csc"
                  min="3"
                  max="4"
                  autocomplete="cc-csc"
                  pattern="[0-9]{3,4}"
                  title={intl.formatMessage({
                    id: 'cvv-input-title',
                  })}
                />
              </InputGroup>
            </InputRow>
          </Fieldset>
          <Fieldset>
            <Legend
              legend={intl.messages[`${page}-billing-details-section-legend`]}
              legendFor="billing-details-section"
              page={page}
            />
            <InputRow>
              <InputGroup
                label={intl.messages[`${page}-address-1-label`]}
                labelFor="address-1"
                page={page}
                required
              >
                <Input
                  ref={addressLine1Input}
                  required
                  dataTestid="address-line1"
                  name="address-line1"
                  min="1"
                  maxLength="35"
                  autocomplete="address-line1"
                />
              </InputGroup>
            </InputRow>
            <InputRow>
              <InputGroup
                label={intl.messages[`${page}-address-2-label`]}
                labelFor="address-2"
                page={page}
              >
                <Input
                  ref={addressLine2Input}
                  dataTestid="address-line2"
                  name="address-line2"
                  min="1"
                  maxLength="35"
                  autocomplete="address-line2"
                />
              </InputGroup>
              <InputGroup
                label={intl.messages[`${page}-city-label`]}
                labelFor="city"
                page={page}
                required
              >
                <Input
                  ref={addressCityInput}
                  required
                  dataTestid="address-city"
                  name="address-city"
                  min="1"
                  max="25"
                  autocomplete="address-city"
                />
              </InputGroup>
            </InputRow>
            <InputRow>
              <InputGroup
                label={intl.messages[`${page}-province-label`]}
                labelFor="province"
                page={page}
                required
              >
                <Input ref={addressStateInput} required as="select">
                  <option disabled selected value="">
                    {intl.formatMessage({ id: 'select-placeholder' })}
                  </option>
                  ,
                  {PROVINCES.map(p => (
                    <option value={p.key}>{p.val}</option>
                  ))}
                </Input>
              </InputGroup>
              <InputGroup
                label={intl.messages[`${page}-postal-code-label`]}
                labelFor="postal-code"
                page={page}
                required
              >
                <Input
                  ref={addressPostalCodeInput}
                  required
                  dataTestid="postal-code"
                  name="postal-code"
                  min="5"
                  max="9"
                  autocomplete="postal-code"
                />
              </InputGroup>
              <InputGroup
                label={intl.messages[`${page}-country-label`]}
                labelFor="country"
                page={page}
                required
              >
                <Input ref={addressCountryInput} required as="select">
                  {' '}
                  <option disabled selected value="">
                    {intl.formatMessage({ id: 'select-placeholder' })}
                  </option>
                  ,
                  {COUNTRIES.map(p => (
                    <option value={p.key}>{p.val}</option>
                  ))}
                </Input>
              </InputGroup>
            </InputRow>
          </Fieldset>
          <ActionBar>
            <ButtonGroup reverse>
              <SubmitButton />
            </ButtonGroup>
          </ActionBar>
        </Form>
      )}
    </>
  );
};

CreatePaymentCardForm.propTypes = {
  setAlert: PropTypes.func.isRequired,
  createPersonalAccount: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
};

export default CreatePaymentCardForm;
