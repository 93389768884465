import styled from 'styled-components';
// Import Global Components
import UnstyledButton from '../../../../../../components/Button';

// Wraps entire Component
export const DateRange = styled.div`
  display: none;

  ${({ theme }) => theme.media.desktopUp`
    display: block;
    margin-right: 60px;
  `}
`;

// Wraps Transaction date range view buttons
export const DateRangeButtons = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: -16px;
  margin-top: -5px;
`;

// Date Range Button
export const Button = styled(UnstyledButton)`
  border-bottom: 1px solid
    ${({ theme, active }) => (active ? theme.brand.primary : 'transparent')};
  border-radius: 0;

  &:hover {
    border-bottom: 1px solid;
  }
`;
