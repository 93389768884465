import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
// Import Shared Components
import { IconButton, Input, Dropdown } from 'shared-components';
// Import Shared Utils
import { getCountryCode } from 'shared-components/src/utils/formatCountry';
import { PROVINCES, STATES } from 'shared-components/src/utils/provinces';
// Import Global Components
import Title from '../../../../components/Title';
// Import Global Layouts
import InputGroup from '../../../../layouts/InputGroup';
import InputRow from '../../../../layouts/InputRow';
// Import Global Utils
import {
  checkSelectedState,
  stateKeys,
  provinceKeys,
} from '../../../../utils/profile';
// Import Component Styles
import { TitleWrapper } from './styles';

const ProfileForm = ({ disabled, iconType, onClick, onChange, cardholder }) => {
  // create Intl instance
  const intl = useIntl();
  const page = 'profile';

  const getSelected = () => {
    if (
      checkSelectedState(cardholder, stateKeys, '840') ||
      checkSelectedState(cardholder, provinceKeys, '124')
    )
      return cardholder.state;

    return null;
  };

  return (
    <>
      <TitleWrapper>
        <Title
          title={intl.messages['profile-personal-info-title']}
          titleFor="personal-info"
          page="profile"
        />
        <IconButton iconType={iconType} onClick={onClick} />
      </TitleWrapper>
      <InputRow>
        <InputGroup
          label={intl.messages[`${page}-firstName-label`]}
          labelFor="firstName"
          page={page}
          required
        >
          <Input
            value={cardholder.first_name}
            id="profile-firstName"
            autocomplete="first-name"
            onChange={e =>
              onChange({ key: 'first_name', value: e.target.value })}
            disabled="disabled"
            maxLength={40}
            required
          />
        </InputGroup>
        <InputGroup
          label={intl.messages[`${page}-middleName-label`]}
          labelFor="middleName"
          page={page}
        >
          <Input
            value={cardholder.middle_name}
            id="profile-middleName"
            autocomplete="middle-name"
            onChange={e =>
              onChange({ key: 'middle_name', value: e.target.value })}
            disabled="disabled"
            maxLength={40}
          />
        </InputGroup>
        <InputGroup
          label={intl.messages[`${page}-lastName-label`]}
          labelFor="lastName"
          page={page}
          required
        >
          <Input
            value={cardholder.last_name}
            id="profile-lastName"
            autocomplete="last-name"
            onChange={e =>
              onChange({ key: 'last_name', value: e.target.value })}
            disabled="disabled"
            maxLength={40}
            required
          />
        </InputGroup>
      </InputRow>
      <InputRow>
        <InputGroup
          label={intl.messages[`${page}-email-label`]}
          labelFor="email"
          page={page}
        >
          <Input
            value={cardholder.email}
            id="profile-email"
            autocomplete="email"
            onChange={e => onChange({ key: 'email', value: e.target.value })}
            disabled={disabled}
            type="email"
          />
        </InputGroup>
        <InputGroup
          label={intl.messages[`${page}-phone-label`]}
          labelFor="phone"
          page={page}
          required
        >
          <Input
            value={cardholder.phone}
            id="profile-phone"
            autocomplete="phone"
            onChange={e =>
              onChange({ key: 'phone', value: e.target.value })}
            disabled={disabled}
            pattern="^\d{7,16}$"
            title="Phone number must be digits"
            required
            type="tel"
            maxLength={16}
          />
        </InputGroup>
      </InputRow>

      <TitleWrapper>
        <Title
          title={intl.messages['profile-personal-mailing-title']}
          titleFor="personal-mailing"
          page="profile"
        />
      </TitleWrapper>
      <InputRow>
        <InputGroup
          label={intl.messages[`${page}-addressLine1-label`]}
          labelFor="addressLine1"
          page={page}
          required
        >
          <Input
            required
            value={cardholder.address1}
            id="profile-addressLine1"
            autocomplete="addressLine1"
            onChange={e => onChange({ key: 'address1', value: e.target.value })}
            disabled={disabled}
            pattern="^[a-zA-Z0-9 ',-.]{1,40}$"
            title="Address may only contain 40 characters of numbers, letters and the characters ',-."
          />
        </InputGroup>
        <InputGroup
          label={intl.messages[`${page}-addressLine2-label`]}
          labelFor="addressLine2"
          page={page}
        >
          <Input
            value={cardholder.address2}
            id="profile-addressLine2"
            autocomplete="addressLine2"
            onChange={e => onChange({ key: 'address2', value: e.target.value })}
            disabled={disabled}
            pattern="^[a-zA-Z0-9 '#,-.]{1,30}$"
            title="Address 2 may only contains 30 characters of numbers, letters, and the characters '#,-."
          />
        </InputGroup>
      </InputRow>

      <InputRow>
        <InputGroup
          label={intl.messages[`${page}-country-label`]}
          labelFor="country"
          page={page}
          required
        >
          <Dropdown
            required
            options={[
              { key: '124', val: 'CANADA' },
              { key: '840', val: 'USA' },
            ]}
            updateItem={value => onChange({ key: 'country', value })}
            selected={getCountryCode(cardholder.country)}
            id="profile-country"
            disabled={disabled}
          />
        </InputGroup>
        <InputGroup
          label={intl.messages[`${page}-province-label`]}
          labelFor="province"
          page={page}
          required
        >
          <Dropdown
            required
            selected={getSelected()}
            options={cardholder.country === '840' ? STATES : PROVINCES}
            updateItem={value => onChange({ key: 'state', value })}
            id="profile-province"
            disabled={disabled}
          />
        </InputGroup>
      </InputRow>

      <InputRow>
        <InputGroup
          required
          label={intl.messages[`${page}-city-label`]}
          labelFor="city"
          page={page}
        >
          <Input
            required
            value={cardholder.city}
            id="profile-city"
            autocomplete="city"
            onChange={e => onChange({ key: 'city', value: e.target.value })}
            disabled={disabled}
          />
        </InputGroup>
        <InputGroup
          required
          label={intl.messages[`${page}-postalCode-label`]}
          labelFor="postalCode"
          page={page}
        >
          <Input
            required
            value={cardholder.postal_code}
            id="profile-postalCode"
            autocomplete="postalCode"
            onChange={e =>
              onChange({ key: 'postal_code', value: e.target.value })}
            disabled={disabled}
            min={5}
            maxLength={10}
          />
        </InputGroup>
      </InputRow>
    </>
  );
};

ProfileForm.propTypes = {
  onClick: PropTypes.func.isRequired,
  iconType: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  cardholder: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

ProfileForm.defaultProps = {
  disabled: false,
  onChange: () => {},
};

export default ProfileForm;
