import styled from 'styled-components';

// Styles the form
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ theme }) => theme.media.tabletPortraitUp`
    input[type="submit"] {
      width: 100%;
    }

    button {
      margin-top: 10px;
    }
  `}
`;

// Contains all inputs in a form
export const InputContainer = styled.fieldset`
  box-sizing: border-box;
  margin-bottom: 45px;

  & > *:not(:last-child) {
    margin-bottom: 15px;
  }
`;
