import styled from 'styled-components';
import UnstyledCard from '../../components/Card';

// Wrapper for Header inside Card and Icon Button
export const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  button {
    margin-top: 45px;
    width: auto;
  }

  h1 {
    margin-bottom: 0;
  }
`;

export const Card = styled(UnstyledCard)`
  padding-top: 45px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  & > h3 {
    padding-top: 25px;
  }

  & > button {
    width: auto;
    margin-bottom: 15px;
  }

  ${({ theme }) => theme.media.tabletPortraitUp`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `}

  & > button {
    margin-bottom: 0;
  }
`;
