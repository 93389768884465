import styled from 'styled-components';
import { ModalFormContainer } from '../../../layouts/Form';

export default styled(ModalFormContainer)`
  button.close-modal {
    position: absolute
    top: 30px;
    right: 30px;
  }

  ${({ theme }) => theme.media.tabletPortraitUp`
    max-width: 500px;
    min-width: 400px;
  `}
`;
