import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import {
  ActionButton,
  ActionButtons,
  DropdownButton,
  DropdownContainer,
  DropdownItem,
  DropdownList,
  EditIcon,
  OptionName,
  OptionNameContainer,
  TrashIcon,
} from './styles';

const EditableDropdown = ({
  options,
  selectedOption,
  onSelect,
  onEdit,
  onDelete,
  page,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const intl = useIntl();

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSelect = option => {
    onSelect(option);
    setIsOpen(false);
  };

  return (
    <DropdownContainer ref={dropdownRef}>
      <DropdownButton
        onClick={() => setIsOpen(!isOpen)}
        type="button"
        aria-haspopup="listbox"
        aria-expanded={isOpen}
      >
        {selectedOption
          ? selectedOption.name
          : intl.formatMessage({
            id: `${page}-form-select-external-account-label`,
          })}
        <span aria-hidden="true">▼</span>
      </DropdownButton>
      {isOpen && (
        <DropdownList role="listbox">
          {options.map(option => (
            <DropdownItem
              key={option.id}
              role="option"
              aria-selected={selectedOption && selectedOption.id === option.id}
              onClick={() => handleSelect(option)}
            >
              <OptionNameContainer>
                <OptionName>{option.name}</OptionName>
              </OptionNameContainer>
              <ActionButtons>
                <ActionButton
                  onClick={e => {
                    e.stopPropagation();
                    onEdit(option);
                  }}
                  aria-label={`Edit ${option.name}`}
                  type="button"
                >
                  <EditIcon />
                </ActionButton>
                <ActionButton
                  onClick={e => {
                    e.stopPropagation();
                    onDelete(option);
                  }}
                  aria-label={`Delete ${option.name}`}
                  type="button"
                >
                  <TrashIcon />
                </ActionButton>
              </ActionButtons>
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </DropdownContainer>
  );
};

EditableDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedOption: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  page: PropTypes.string.isRequired,
};

export default EditableDropdown;
