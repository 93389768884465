import styled from 'styled-components';

export const StyledSelectNetwork = styled.div`
  display: flex;
  flex-direction: column;

  & > button:not(:last-child) {
    margin-bottom: 15px;
  }

  ${({ theme }) => theme.media.tabletPortraitUp`
  flex-direction: row;

  & > button:not(:last-child) {
    margin-right: 15px;
    margin-bottom: 0;
  }

  & > * {
    width 33%;
  }
  `};
`;
