import { verifyCardValidations } from 'shared-components/src/validations/cardValidations';
import { verifyRegisterValidations } from 'shared-components/src/validations/cardholderValidations';

export const validateCardNumber = (setValidationErrors, cardInfo) =>
  setValidationErrors({
    key: 'cardNumber',
    value: !verifyCardValidations.cardNumber(cardInfo.cardNumber),
  });
export const validateCVV = (setValidationErrors, cardInfo) =>
  setValidationErrors({
    key: 'cvv',
    value: !verifyCardValidations.cvv(cardInfo.cvv),
  });
export const validateExpiry = (setValidationErrors, cardInfo) =>
  setValidationErrors({
    key: 'expiry',
    value: !verifyCardValidations.expiry(cardInfo.expiry),
  });

export const validateUsername = (setValidationErrors, userInfo) => {
  setValidationErrors({
    key: 'username',
    value: !verifyRegisterValidations.username(userInfo.username),
  });
};
export const validateCurrentPassword = (setValidationErrors, userInfo) => {
  setValidationErrors({
    key: 'currentPassword',
    value: !verifyRegisterValidations.password(userInfo.currentPassword),
  });
};
export const validatePassword = (setValidationErrors, userInfo) => {
  setValidationErrors({
    key: 'password',
    value: !verifyRegisterValidations.password(userInfo.password),
  });
};
export const validatePasswordConfirmation = (setValidationErrors, userInfo) => {
  setValidationErrors({
    key: 'passwordConfirmation',
    value: !verifyRegisterValidations.password(userInfo.passwordConfirmation),
  });
};

export const checkForValidationErrors = (validationErrors, setError) => {
  if (
    Object.keys(validationErrors).find(field => {
      return validationErrors[field] === true;
    })
  ) {
    return setError(
      'Form contains invalid or blank inputs, please correct them before submitting'
    );
  }

  return false;
};
