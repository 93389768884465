import React, { useState } from 'react';
import PropTypes from 'prop-types';
// Import Shared Components
import {
  Modal,
  ActionBar,
  ButtonGroup,
  Loader,
  Input,
} from 'shared-components';
// Import Shared Utils
import { post } from 'shared-components/src/utils/http';
// Import Global Layouts
import InputGroup from '../../../../../../layouts/InputGroup';
import InputRow from '../../../../../../layouts/InputRow';
import Form, { ModalFormContainer } from '../../../../../../layouts/Form';
// Import Global Components
import Heading from '../../../../../../components/Heading';
import Alert from '../../../../../../components/Alert';
import SubmitButton from '../../../../../../components/SubmitButton';
import CancelButton from '../../../../../../components/CancelButton';
// Import Translations
import { recipientAccountEn } from '../../../../../../i18n/recipientAccount';

const UpdateRecipientAccountModal = ({
  toggleModal,
  setSuccess,
  recipientAccounts,
  recipientAccount,
  setRecipientAccount,
  setRecipientAccounts,
}) => {
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);

  const [recipientNameInput, setRecipientNameInput] = useState(
    recipientAccount.name
  );
  const [recipientEmailInput, setRecipientEmailInput] = useState(
    recipientAccount.email
  );

  const page = 'recipient-account';

  const updateRecipientAccount = async params => {
    try {
      // create external account
      const res = await post(
        `/api/v1/cardholder_portal/cardholder/recipient_accounts/${recipientAccount.id}`,
        params
      );
      const idx = recipientAccounts.findIndex(
        account => account.id === res.data.id
      );

      // remove old recipient account and add updated recipient account
      setRecipientAccounts([
        ...recipientAccounts.slice(0, idx),
        res.data,
        ...recipientAccounts.slice(idx + 1),
      ]);

      setSuccess({
        type: 'success',
        message: recipientAccountEn[`${page}-success-update-alert`],
        messageFor: 'success-update',
      });
      setLoading(false);
      toggleModal(false);
      setRecipientAccount(null);
    } catch (e) {
      setAlert({ type: 'danger', message: e.message });
      setLoading(false);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    setAlert(null);

    const params = {
      name: recipientNameInput.trim(),
      email: recipientEmailInput.trim(),
    };

    updateRecipientAccount(params);
  };

  return (
    <Modal>
      <ModalFormContainer>
        {alert && (
          <Alert
            alert={alert.message}
            alertFor={alert.type}
            page={page}
            type={alert.type}
          />
        )}
        <Heading
          page={`${page}-update`}
          heading={recipientAccountEn[`${page}-update-heading`]}
        />
        {loading ? (
          <Loader />
        ) : (
          <Form onSubmit={e => handleSubmit(e)}>
            <InputRow>
              <InputGroup
                label={recipientAccountEn[`${page}-name-label`]}
                labelFor="name"
                page={page}
                required
              >
                <Input
                  value={recipientNameInput}
                  onChange={e => setRecipientNameInput(e.target.value)}
                  required
                  dataTestid="name"
                  name="name"
                  autocomplete="name"
                />
              </InputGroup>
            </InputRow>
            <InputRow>
              <InputGroup
                label={recipientAccountEn[`${page}-email-label`]}
                labelFor="email"
                page={page}
                required
              >
                <Input
                  value={recipientEmailInput}
                  onChange={e => setRecipientEmailInput(e.target.value)}
                  required
                  dataTestId="email"
                  name="email"
                  autocomplete="email"
                  type="email"
                />
              </InputGroup>
            </InputRow>

            <ActionBar>
              <ButtonGroup reverse>
                <SubmitButton />
                <CancelButton
                  onClick={e => {
                    e.preventDefault();
                    toggleModal(false);
                  }}
                />
              </ButtonGroup>
            </ActionBar>
          </Form>
        )}
      </ModalFormContainer>
    </Modal>
  );
};

UpdateRecipientAccountModal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
  recipientAccount: PropTypes.object.isRequired, // eslint-disable-line
  recipientAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      cardholder_id: PropTypes.number.isRequired,
      created_at: PropTypes.string.isRequired,
      dest_account_id: PropTypes.number.isRequired,
      email: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      updated_at: PropTypes.string.isRequired,
    })
  ).isRequired,
  setRecipientAccount: PropTypes.func.isRequired,
  setRecipientAccounts: PropTypes.func.isRequired,
};

export default UpdateRecipientAccountModal;
