export const personalAccountEn = {
  'personal-account-heading': 'Move Money',
  'personal-account-balance-label': 'Available Balance',
  'personal-account-with-account-title': 'Transfer Funds to My Account',
  'personal-account-transfer-name-label': 'Transfer Name:',
  'personal-account-amount-label': 'Amount:',
  'personal-account-account-label': 'Account:',
  'personal-account-description-label': 'Description:',
  'personal-account-create-button': 'Send',
  'personal-account-add-account-button': 'Add Bank Account',
  'personal-account-make-transfer-title': 'Make Transfer',
  'personal-account-to-my-account-text': 'To My Account',
  'personal-account-card-name-label': 'Full Cardholder Name:',
  'personal-account-given-name-label': 'First Name:',
  'personal-account-additional-name-label': 'Middle Name:',
  'personal-account-family-name-label': 'Last Name:',
  'personal-account-card-number-label': 'Card Number:',
  'personal-account-expiry-month-label': 'Expiry Month:',
  'personal-account-expiry-year-label': 'Expiry Year:',
  'personal-account-cvv-label': 'CVV:',
  'personal-account-address-1-label': 'Address Line 1:',
  'personal-account-address-2-label': 'Address Line 2:',
  'personal-account-city-label': 'City:',
  'personal-account-province-label': 'Province/State:',
  'personal-account-postal-code-label': 'Postal Code:',
  'personal-account-country-label': 'Country:',
  'personal-account-card-details-section-legend': 'Card Details',
  'personal-account-billing-details-section-legend': 'Billing Details',
  'personal-account-name-label': 'Card Name',
  'personal-account-add-payment-card-title': 'Add Bank Account Through Visa',
  'personal-account-delete-heading': 'Delete Bank Account',
  'personal-account-update-heading': 'Update Bank Account',
  'personal-account-transfer-created-alert':
    'A transfer to your account was created successfully.',
  'personal-account-add-banking-alert': 'Please add your banking information.',
  'personal-account-success-update-alert':
    'Your bank account was updated successfully',
  'personal-account-invalid-name-alert':
    'Please use a new name to update your bank account.',
  'personal-account-exceeds-available-amount-alert':
    'Transfer amount exceeds available account balance.',
  'personal-account-success-delete-alert':
    'Your bank account has been successfully deleted.',
  'personal-account-information-text':
    'Add your bank account entering your Visa Debit card or any ATM or debit card that carries the PLUS network mark.',
  'personal-account-delete-form-text':
    'Are you sure you want to delete your account, <b>{name}</b>?',
  'personal-account-success-create-alert':
    'Your bank account was created successfully.',
  'personal-account-total-fee-text':
    // eslint-disable-next-line no-template-curly-in-string
    'There is a ${feeAmount} fee for this transfer.',
  'personal-account-add-payment-method-title': 'Add Payment Method',
  'personal-account-select-network-title': 'Select Network',
  'personal-account-etransfer-details-section-legend': 'E-transfer Details',
  'personal-account-etransfer-info-text':
    'Move money to your bank account through Interac e-transfer.',
  'personal-account-no-integrations-alert':
    'Your payment provider has no payment network integrations at this time. Due to this, transfers to your bank account is not available.',
  'personal-account-interac-details-text':
    'Note that if you have direct deposit enabled, the money will automatically be deposited into your account. With that, we still require these fields at this time',
  'personal-account-visa-network-text': 'Quick and Instant Payment',
  'personal-account-etransfer-network-text': 'Email Payment',
  'personal-account-eft-network-text': 'Payment in 3-5 Working Days',
  'personal-account-interac-details-legend': 'Interac Details',
  'personal-account-transfer-details-legend': 'Transfer Details',
};

export const personalAccountFr = {
  'personal-account-heading': 'Déplacer de l\'argent',
  'personal-account-balance-label': 'Vers mon compte',
  'personal-account-with-account-title': 'Transférer des fonds sur mon compte',
  'personal-account-transfer-name-label': 'Nom du transfert:',
  'personal-account-amount-label': 'Montant:',
  'personal-account-account-label': 'Compte:',
  'personal-account-description-label': 'La description:',
  'personal-account-create-button': 'Envoyer',
  'personal-account-add-account-button': 'Ajouter un compte bancaire',
  'personal-account-make-transfer-title': 'Effectuer un transfert',
  'personal-account-to-my-account-text': 'Vers mon compte',
  'personal-account-card-name-label': 'Nom complet du Titulaire de la carte:',
  'personal-account-given-name-label': 'Prénom:',
  'personal-account-additional-name-label': 'Deuxième prénom:',
  'personal-account-family-name-label': 'Nom de famille:',
  'personal-account-card-number-label': 'Numéro de carte:',
  'personal-account-expiry-month-label': 'Mois d\'expiration:',
  'personal-account-expiry-year-label': 'Année d\'expiration:',
  'personal-account-cvv-label': 'CVV:',
  'personal-account-address-1-label': 'Adresse Ligne 1:',
  'personal-account-address-2-label': 'Adresse Ligne 2:',
  'personal-account-city-label': 'Ville:',
  'personal-account-province-label': 'Province / État:',
  'personal-account-postal-code-label': 'Code postal:',
  'personal-account-country-label': 'Pays:',
  'personal-account-card-details-section-legend': 'Détails de la carte',
  'personal-account-billing-details-section-legend':
    'Détails de la facturation',
  'personal-account-name-label': 'Nom de la carte',
  'personal-account-create-heading': 'Ajouter un compte bancaire',
  'personal-account-delete-heading': 'Supprimer le compte bancaire',
  'personal-account-update-heading': 'Mettre à jour le compte bancaire',
  'personal-account-transfer-created-alert':
    'Un transfert à votre compte a été créé avec succès.',
  'personal-account-add-banking-alert':
    'Veuillez ajouter vos informations bancaires.',
  'personal-account-success-update-alert':
    'Votre compte bancaire a été mis à jour avec succès.',
  'personal-account-invalid-name-alert': 'S\'il vous plaît utiliser un nouveau nom pour mettre à jour votre compte bancaire.',
  'personal-account-exceeds-available-amount-alert':
    'Le montant du transfert dépasse le solde disponible du compte.',
  'personal-account-success-delete-alert':
    'Votre compte bancaire a été supprimé.',
  'personal-account-information-text':
    'Ajoutez votre compte bancaire en saisissant votre carte Visa Débit ou tout GAB ou carte de débit portant la marque de réseau PLUS.',
  'personal-account-delete-form-text':
    'Voulez-vous vraiment supprimer votre compte, <b>{name}</b>?',
  'personal-account-success-create-alert':
    'Votre compte bancaire a été créé avec succès.',
  'personal-account-total-fee-text':
    'Il y a des frais de {feeAmount} $ pour ce transfert.',
  'personal-account-add-payment-method-title': 'Ajouter un mode de paiement ',
  'personal-account-select-network-title': 'Sélectionnez réseau ',
  'personal-account-etransfer-details-section-legend':
    'Détails du transfert électronique',
  'personal-account-interac-details-text': 'Notez que si vous avez activé le dépôt direct, l\'argent sera automatiquement déposé dans votre compte. Compte tenu de cela, nous avons toujours besoin de ces champs pour le moment ',
  'personal-account-visa-network-text': 'Quick and Instant Payment',
  'personal-account-etransfer-network-text': 'Email Payment',
  'personal-account-eft-network-text': 'Payment in 3-5 Working Days',
  'personal-account-interac-details-legend': 'Détails Interac ',
  'personal-account-transfer-details-legend': 'Détails du transfert',
};
