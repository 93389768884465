import styled from 'styled-components';

export const StyledLabel = styled.label`
  margin: 20px 0 10px;
`;

export const ModalFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: 600px;
  min-width: 300px;
  padding: 30px;
  max-height: 80vh;
  overflow-y: auto;
`;

export const InputRow = styled.div`
  display: flex;
  gap: 20px;
`;

export const InputItem = styled.div`
  flex: 1;
`;