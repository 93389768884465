import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
// Import Shared Components
import { ActionBar, Input, Dropdown } from 'shared-components';
import { useUpdateStateObject } from 'shared-components/src/hooks/useUpdateStateObject';
import { personToPersonAccount } from 'shared-components/src/constants/propTypes';
import { numberDollarsToCents } from 'shared-components/src/utils/currency';
// Import Global Layouts
import InputGroup from '../../../../../layouts/InputGroup';
import InputRow from '../../../../../layouts/InputRow';
import Form from '../../../../../layouts/Form';
// Import Global Components
import SubmitButton from '../../../../../components/SubmitButton';
import TransferFeeTooltip from '../../../../../components/TransferFeeTooltip';

const PersonToPersonTransferForm = ({
  setAlert,
  balance,
  createPersonToPersonTransfer,
  createPersonToPersonExternalAccountAndTransfer,
  personToPersonAccounts,
}) => {
  const intl = useIntl();

  const descriptionInput = useRef(null);
  const [amountInput, setAmountInput] = useState(0);

  // Define States
  const [transferNameInput, setTransferNameInput] = useState(
    `Transfer ${Date.now()}`
  );
  const [recipientId, setRecipientId] = useState(null);

  // Define Reducers
  const [newRecipientInput, onChange] = useUpdateStateObject({
    first_name: '',
    last_name: '',
    email: '',
  });

  const page = 'person-to-person';

  const handleSubmit = e => {
    e.preventDefault();
    setAlert(null);

    // Check that entered amount is less than available balance
    if (parseInt(amountInput, 10) > parseInt(balance, 10))
      return setAlert({
        type: 'danger',
        message: intl.messages['exceeds-available-amount-alert'],
        messageFor: 'exceeds-available-amount',
      });

    // basic parameters for person to person transfer
    const params = {
      amount: numberDollarsToCents(amountInput),
      external_tag: transferNameInput,
      message: descriptionInput.current.value,
      transfer_type: 'direct_send_transfer',
    };

    // Need to create a new external account if selected
    if (recipientId === 'create') {
      return createPersonToPersonExternalAccountAndTransfer(
        {
          type: 'person_to_person',
          ...newRecipientInput,
        },
        params
      );
    }
    return createPersonToPersonTransfer({
      external_account_id: recipientId,
      ...params,
    });
  };

  const personToPersonAccountOpts = personToPersonAccounts
    ? personToPersonAccounts.map(account => {
      return { key: account.id, val: account.name };
    })
    : [];

  return (
    <Form onSubmit={e => handleSubmit(e)}>
      <InputRow>
        <InputGroup
          label={intl.messages['recipient-label']}
          labelFor="recipient"
          page={page}
          required
        >
          <Dropdown
            required
            options={[
              { key: 'create', val: 'New Recipient' },
              ...personToPersonAccountOpts,
            ]}
            updateItem={value => setRecipientId(value)}
            selected={recipientId}
          />
        </InputGroup>
      </InputRow>
      {recipientId && recipientId === 'create' && (
        <>
          <InputRow>
            <InputGroup
              label={intl.messages['firstName-label']}
              labelFor="firsName"
              required
            >
              <Input
                max="150"
                name="firsName"
                value={newRecipientInput.first_name}
                onChange={e =>
                  onChange({ key: 'first_name', value: e.target.value.trim() })}
                required
              />
            </InputGroup>
            <InputGroup
              label={intl.messages['lastName-label']}
              labelFor="lasName"
              required
            >
              <Input
                max="150"
                name="lasName"
                value={newRecipientInput.last_name}
                onChange={e =>
                  onChange({ key: 'last_name', value: e.target.value.trim() })}
                required
              />
            </InputGroup>
          </InputRow>
          <InputRow>
            <InputGroup
              label={intl.messages['email-label']}
              labelFor="email"
              required
            >
              <Input
                name="email"
                type="email"
                value={newRecipientInput.email}
                onChange={e =>
                  onChange({ key: 'email', value: e.target.value.trim() })}
                required
              />
            </InputGroup>
          </InputRow>
        </>
      )}
      <InputRow>
        <InputGroup
          required
          label={intl.messages['transfer-name-label']}
          labelFor="transfer-name"
        >
          <Input
            max="50"
            name="transfer-name"
            pattern="[a-zA-Z0-9\- ]*"
            title="Please use letters, numbers, and dashes only"
            value={transferNameInput}
            onChange={e => setTransferNameInput(e.target.value)}
          />
        </InputGroup>
        <InputGroup
          required
          label={intl.messages['amount-label']}
          labelFor="amount"
        >
          <Input
            name="amount"
            pattern="[0-9\.]"
            title="Please enter a valid amount"
            placeholder="00.00"
            type="number"
            step="0.01"
            min={1}
            value={amountInput}
            onChange={e => setAmountInput(e.target.value)}
            required
          />
        </InputGroup>
      </InputRow>
      <InputRow>
        <TransferFeeTooltip
          transferAmount={amountInput}
          feeType="external_recipient"
          page={page}
        />
      </InputRow>
      <InputRow>
        <InputGroup
          label={intl.messages['description-label']}
          labelFor="description"
        >
          <Input ref={descriptionInput} as="textarea" max="300" />
        </InputGroup>
      </InputRow>

      <ActionBar>
        <SubmitButton disabled={!recipientId} />
      </ActionBar>
    </Form>
  );
};

PersonToPersonTransferForm.propTypes = {
  setAlert: PropTypes.func.isRequired,
  createPersonToPersonTransfer: PropTypes.func.isRequired,
  createPersonToPersonExternalAccountAndTransfer: PropTypes.func.isRequired,
  balance: PropTypes.string.isRequired,
  personToPersonAccounts: PropTypes.arrayOf(personToPersonAccount).isRequired,
};

export default PersonToPersonTransferForm;
