import React, { useState, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
// Import Shared Components
import { Input, Checkbox } from 'shared-components';
import { ThemeContext } from 'styled-components';
// Import Shared Hooks
import { useUpdateStateObject } from 'shared-components/src/hooks/useUpdateStateObject';
// Import Shared Utils
import { post } from 'shared-components/src/utils/http';
// Import Global Layouts
import InputGroup from '../../../layouts/InputGroup';
// Import Local Layouts
import AuthLayout from '../layouts/Auth';
import { AuthForm } from '../layouts/InnerAuth';
// Import Local Components
import ActivationMessage from '../components/ActivationMessage';
// Improt Component Styles
import { FormRow } from '../styles';

import {
  validateCardNumber,
  validateCVV,
  validateExpiry,
} from '../../../utils/validationHandlers';

import { authEn } from '../../../i18n/auth';
import CardholderAgreementLink  from './components/CardholderAgreementLink';
import PrivacyPolicyAgreementLink  from './components/PrivacyPolicyAgreementLink';
import ElectronicComConsentLink  from './components/ElectronicComConsentLink';

export default () => {
  const intl = useIntl();
  const page = 'activate';
  const theme = useContext(ThemeContext);

  // // Set States
  const initialCardInfoState = {
    cardNumber: '',
    cvv: '',
    expiry: '',
    consent: false,
  };
  const initialValidationErrors = {
    cardNumber: '',
    cvv: '',
    expiry: '',
    consent: false,
  };
  const [formError, setFormError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activationMessage, setActivationMessage] = useState(null);

  // Create reducer with initial card information state
  // Returns the updated state to be passed to components and a function to update the state
  const [cardInfo, updateCardInfo] = useUpdateStateObject(initialCardInfoState);
  const [validationErrors, setValidationErrors] = useUpdateStateObject(
    initialValidationErrors
  );
  const [termsConsent, setTermsConsent] = useState(false);
  const [cardholderAgreement, setCardholderAgreement] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [electronicDisclosure, setElectronicDisclosure] = useState(false);

  // Set Handlers
  // eslint-disable-next-line consistent-return
  async function verifyCard() {
    // Run function to check for form errors.
    // Throws a generic error message if any of the fields are not valid
    if (
      Object.keys(validationErrors).find(field => {
        return validationErrors[field] === true;
      })
    )
      return setFormError('invalid-form-submit-error');

    // Extract expiry month and year
    const expiryMatches = cardInfo.expiry.match(/^(\d{2})(\d{4})$/);
    const expiryMonth = expiryMatches[1];
    const expiryYear = expiryMatches[2];

    const verifyPayload = {
      card_number: cardInfo.cardNumber,
      cvv: cardInfo.cvv,
      expiry_month: expiryMonth,
      expiry_year: expiryYear,
    };

    try {
      setActivationMessage(null);
      setLoading(true);
      setFormError(false);

      let activationState = 'newlyactivated';

      const res = await post(
        '/api/v1/cardholder_portal/activate_card',
        verifyPayload
      );

      if (res.data.code === 'already_activated') {
        activationState = 'alreadyactivated';
      }

      // Clear All Input Fields and Error Values
      updateCardInfo({ cardNumber: '', cvv: '', expiry: '' });
      updateCardInfo({ key: 'cardNumber', value: '' });
      updateCardInfo({ key: 'cvv', value: '' });
      updateCardInfo({ key: 'expiry', value: '' });

      setValidationErrors({ key: 'cardNumber', value: '' });
      setValidationErrors({ key: 'cvv', value: '' });
      setValidationErrors({ key: 'expiry', value: '' });

      // Render Success Message
      setActivationMessage({ type: activationState });
    } catch (error) {
      setTermsConsent(false);
      setCardholderAgreement(false);
      setPrivacyPolicy(false);
      setElectronicDisclosure(false);
      setFormError(error.message);
    }
    setLoading(false);
  }

  const handleActivateKeyUp = async e => {
    if (e.keyCode === 13) {
      await verifyCard();
    }
  };

  const areAllAgreementsChecked = () => {
    if (theme.brand.activateCardholderAgreementEn && theme.brand.activateCardholderAgreementFr && !cardholderAgreement) {
      return false;
    }
    if (theme.brand.activatePrivacyPolicyEn && theme.brand.activatePrivacyPolicyFr && !privacyPolicy) {
      return false;
    }
    if (theme.brand.activateElectronicComConsentEn && theme.brand.activateElectronicComConsentFr && !electronicDisclosure) {
      return false;
    }
    if ((!theme.brand.activateCardholderAgreementEn || !theme.brand.activateCardholderAgreementFr)) {
      return termsConsent;
    }

    return !termsConsent;
  };

  const submitButtonDisabled = !areAllAgreementsChecked();

  // TODO: Need to get correct wording for this page from Operations
  return (
    <AuthLayout
      page={page}
      heading={intl.messages[`${page}-heading`]}
      subHeaderOne={intl.messages[`${page}-subheader1-text`]}
      subHeaderTwo={intl.messages[`${page}-subheader2-text`]}
      error={formError}
    >
      <AuthForm
        page={page}
        onSubmit={e => {
          e.preventDefault();
          verifyCard();
        }}
        onKeyUp={handleActivateKeyUp}
        loading={loading}
        submitButtonDisabled={submitButtonDisabled}
      >
        {activationMessage && (
          <ActivationMessage type={activationMessage.type} />
        )}

        <InputGroup
          label={intl.messages['auth-card-number-label']}
          labelFor="card-number"
          page="auth"
          required
        >
          <Input
            value={cardInfo.cardNumber}
            onBlur={() => validateCardNumber(setValidationErrors, cardInfo)}
            minLength={13}
            maxLength={19}
            onChange={e => {
              return updateCardInfo({
                key: 'cardNumber',
                value: e.target.value.trim(),
              });
            }}
            required
          />
        </InputGroup>
        <FormRow>
          <InputGroup
            label={intl.messages['auth-cvv-label']}
            labelFor="cvv"
            page="auth"
            required
          >
            <Input
              value={cardInfo.cvv}
              onBlur={() => validateCVV(setValidationErrors, cardInfo)}
              minLength={3}
              maxLength={4}
              type="password"
              onChange={e => {
                return updateCardInfo({
                  key: 'cvv',
                  value: e.target.value.trim(),
                });
              }}
              required
            />
          </InputGroup>
          <InputGroup
            label={intl.messages['auth-expiry-label']}
            labelFor="expiry"
            page="auth"
            required
          >
            <Input
              value={cardInfo.expiry.replace(/^(\d{2})(\d{4})/, '$1 / $2')}
              onBlur={() => validateExpiry(setValidationErrors, cardInfo)}
              placeholder="MM / YYYY"
              maxLength={6}
              minLength={6}
              onChange={e => {
                return updateCardInfo({
                  key: 'expiry',
                  value: e.target.value.trim(),
                });
              }}
              required
            />
          </InputGroup>
        </FormRow>
        {(!theme.brand.activateCardholderAgreementEn || !theme.brand.activateCardholderAgreementFr) && (
          <FormRow>
            <Checkbox
              label={intl.messages['activate-terms-consent-label']}
              labelFor="termsConsentInput"
              id="terms-consent-input"
              value={termsConsent}
              onChange={e => setTermsConsent(e.target.checked)}
              required
            />
          </FormRow>
        )}
        {(theme.brand.activateCardholderAgreementEn && theme.brand.activateCardholderAgreementFr) && (
          <FormRow>
            <Checkbox
              label={(
                <FormattedMessage
                  id="activate-cardholder-agreement-label"
                  description="Label for Cardholder Agreement"
                  defaultMessage={authEn['activate-cardholder-agreement-label']}
                  values={{
                    link: (
                      <CardholderAgreementLink />
                    ),
                  }}
                />
              )}
              labelFor="activateCardholderAgreementInput"
              id="activate-cardholder-agreement-input"
              value={cardholderAgreement}
              onChange={e => setCardholderAgreement(e.target.checked)}
            />
          </FormRow>
        )}
        {(theme.brand.activatePrivacyPolicyEn && theme.brand.activatePrivacyPolicyFr) && (
          <FormRow>
            <Checkbox
              label={(
                <FormattedMessage
                  id="activate-privacy-policy-label"
                  description="Label for Privacy Policy"
                  defaultMessage={authEn['activate-privacy-policy-label']}
                  values={{
                    link: (
                      <PrivacyPolicyAgreementLink />
                    ),
                  }}
                />
              )}
              labelFor="activatePrivacyPolicyInput"
              id="activate-privacy-policy-input"
              value={privacyPolicy}
              onChange={e => setPrivacyPolicy(e.target.checked)}
              required
            />
          </FormRow>
        )}
        {(theme.brand.activateElectronicComConsentEn && theme.brand.activateElectronicComConsentFr)  && (

          <FormRow>
            <Checkbox
              label={(
                <FormattedMessage
                  id="activate-electronic-com-consent-label"
                  description="Label for Electronic Communication Consent"
                  defaultMessage={authEn['activate-electronic-com-consent-label']}
                  values={{
                    link: (
                      <ElectronicComConsentLink />
                    ),
                  }}
                />
              )}
              labelFor="activateElectronicCommConsentInput"
              id="activate-electronic-comm-consent-input"
              value={electronicDisclosure}
              onChange={e => setElectronicDisclosure(e.target.checked)}
              required
            />
          </FormRow>
        )}
      </AuthForm>
    </AuthLayout>
  );
};
