import { getToken } from './auth';

export const get = async url => {
  const { token } = getToken();

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then(res => res.json())
    .then(body => {
      if (body.error) {
        if (body.error.message) throw new Error(body.error.message);
        throw new Error('Failed to fetch data.');
      }
      return body;
    });

  return response;
};

export const post = async (url, body) => {
  const { token } = getToken();

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  })
    .then(res => res.json())
    .then(body => {
      if (body.error) {
        if (body.error.message) throw new Error(body.error.message);
        throw new Error('Failed to send request.');
      }
      return body;
    });

  return response;
};

export const put = async (url, body) => {
  const { token } = getToken();

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    throw new Error('Failed to fetch data');
  }

  const data = await response.json();
  return data;
};

export const deleteHTTP = async (url, body) => {
  const { token } = getToken();

  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  })
    .then(res => res.json())
    .then(body => {
      if (body.error) {
        if (body.error.message) throw new Error(body.error.message);
        throw new Error('Failed to send request.');
      }
      return body;
    });

  return response;
};
