import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
// Import Shared Components
import { PasswordRequirements, Input, IconButton, Alert } from 'shared-components';
// Import Shared Hooks
import { useUpdateStateObject } from 'shared-components/src/hooks/useUpdateStateObject';
// Import Shared Validations
import { verifyRegisterValidations } from 'shared-components/src/validations/cardholderValidations';
// Import Global Components
import InfoTooltip from '../../../../components/InfoTooltip';
import Title from '../../../../components/Title';
import SubmitButton from '../../../../components/SubmitButton';
// Import Global Layouts
import InputGroup from '../../../../layouts/InputGroup';
import InputRow from '../../../../layouts/InputRow';
import Form from '../../../../layouts/Form';
// Import Utils
import { resetPassword } from '../../../../utils/auth';
import { verifyLoginCreds } from '../../../../utils/profile';
import {
  validateCurrentPassword,
  validatePassword,
  validatePasswordConfirmation,
  checkForValidationErrors,
} from '../../../../utils/validationHandlers';
import { TitleWrapper } from '../Form/styles';

const LoginCredentialsForm = ({ username, iconType, onClick }) => {
  // Set States
  const initialRegisterInfo = {
    username: '',
    currentPassword: '',
    password: '',
    passwordConfirmation: '',
  };
  const initialValidationErrors = {
    username: '',
    currentPassword: '',
    password: '',
    passwordConfirmation: '',
  };
  const [formError, setFormError] = useState(null);
  const [success, setSuccess] = useState(null);

  // create intl instance
  const intl = useIntl();
  
  // Create reducer with initial card information state
  // Returns the updated state to be passed to components and a function to update the state
  const [resetPasswordInfo, setResetPasswordInfo] = useUpdateStateObject(
    initialRegisterInfo
  );
  const [validationErrors, setValidationErrors] = useUpdateStateObject(
    initialValidationErrors
  );

  const [showPasswords, setShowPasswords] = useState(false);

  const passwordInputType = showPasswords ? 'text' : 'password';
      
  const handleResetPassword = async payload => {
    try {
      const verifyPayload = {
        username,
        password: resetPasswordInfo.currentPassword,
      };
    
      await verifyLoginCreds(verifyPayload);
      setFormError(null);
      await resetPassword(payload);
      setResetPasswordInfo({ key: 'username', value: '' });
      setResetPasswordInfo({ key: 'currentPassword', value: '' });
      setResetPasswordInfo({ key: 'password', value: '' });
      setResetPasswordInfo({ key: 'passwordConfirmation', value: '' });
      setValidationErrors({ key: 'username', value: '' });
      setValidationErrors({ key: 'currentPassword', value: '' });
      setValidationErrors({ key: 'password', value: '' });
      setValidationErrors({ key: 'passwordConfirmation', value: '' });
      setShowPasswords(false);
      setSuccess('password-reset-success-alert');
    } catch (e) {
      setFormError(e.message);
    }
  };

  // Set Handlers
  // eslint-disable-next-line consistent-return
  const handleSubmit = async () => {
    // Check if current password meets requirements
    if (!verifyRegisterValidations.password(resetPasswordInfo.currentPassword))
      return setFormError('invalid-current-password-format-error');

    // Check if password meets requirements
    if (!verifyRegisterValidations.password(resetPasswordInfo.password))
      return setFormError('invalid-password-format-error');
    // Check if password and password confirmation match
    if (resetPasswordInfo.password !== resetPasswordInfo.passwordConfirmation)
      return setFormError('invalid-password-confirmation-error');

    // Throws a generic error message if any of the fields are not valid
    if (
      Object.keys(validationErrors).find(field => {
        return validationErrors[field] === true;
      })
    )
      return setFormError('invalid-form-submit-error');

    // Create payload to be sent
    const payload = {
      username,
      password: resetPasswordInfo.password,
      password_confirmation: resetPasswordInfo.passwordConfirmation,
    };

    handleResetPassword(payload);
  };

  const page = 'profile';

  return (
    <>
      <TitleWrapper>
        <Title
          title={intl.messages['profile-login-credentials']}
          titleFor="login-credentials"
          page="profile"
        />
        <IconButton 
          iconType={iconType} 
          onClick={()=> {
            setFormError(null);
            onClick();
          }} 
        />
      </TitleWrapper>
      {iconType === 'edit' ?
        (
          <InputRow>
            <InputGroup
              label={intl.messages[`${page}-username-label`]}
              labelFor="username"
              page={page}
              required
            >
              <Input
                value={username}
                id="profile-username"
                autocomplete="username"
                disabled="disabled"
                maxLength={30}
                required
              />
            </InputGroup>
            <InputGroup
              label={intl.messages[`${page}-password-label`]}
              labelFor="password"
              page={page}
            >
              <Input
                value='********'
                id="profile-password"
                autocomplete="password"
                disabled
              />
            </InputGroup>
          </InputRow>
        ) : (
          <>
            <InputRow>
              <Form
                onSubmit={e => {
                  e.preventDefault();
                  checkForValidationErrors(validationErrors, setFormError);
                  handleSubmit();
                }}
              >
                <InputGroup
                  label={intl.messages['auth-current-password-label']}
                  labelFor="current-password"
                  page={page}
                  required
                >
                  <Input
                    value={resetPasswordInfo.currentPassword}
                    error={validationErrors.currentPassword}
                    onBlur={() =>
                      validateCurrentPassword(setValidationErrors, resetPasswordInfo)}
                    onChange={e =>
                      setResetPasswordInfo({
                        key: 'currentPassword',
                        value: e.target.value.trim(),
                      })}
                    type={passwordInputType}
                    pattern="(?=.*[\d])(?=.*[A-Z])(?=.*[!@#$*])[\w!@#$*]{8,}"
                    minLength={8}
                    required
                  />
                </InputGroup>
                <InputGroup
                  tooltip={(
                    <InfoTooltip
                      position="top"
                      content={<PasswordRequirements messages={intl.messages} />}
                    />
                  )}
                  label={intl.messages['auth-new-password-label']}
                  labelFor="new-password"
                  page={page}
                  required
                >
                  <Input
                    value={resetPasswordInfo.password}
                    error={validationErrors.password}
                    onBlur={() =>
                      validatePassword(setValidationErrors, resetPasswordInfo)}
                    onChange={e =>
                      setResetPasswordInfo({
                        key: 'password',
                        value: e.target.value.trim(),
                      })}
                    type={passwordInputType}
                    pattern="(?=.*[\d])(?=.*[A-Z])(?=.*[!@#$*])[\w!@#$*]{8,}"
                    minLength={8}
                    required
                  />
                </InputGroup>
                <InputGroup
                  label={intl.messages['auth-password-confirmation-label']}
                  labelFor='password-confirmation'
                  page={page}
                  required
                >
                  <Input
                    value={resetPasswordInfo.passwordConfirmation}
                    error={validationErrors.passwordConfirmation}
                    onBlur={() =>
                      validatePasswordConfirmation(
                        setValidationErrors,
                        resetPasswordInfo
                      )}
                    onChange={e =>
                      setResetPasswordInfo({
                        key: 'passwordConfirmation',
                        value: e.target.value.trim(),
                      })}
                    type={passwordInputType}
                    minLength={8}
                    pattern="(?=.*[\d])(?=.*[A-Z])(?=.*[!@#$*])[\w!@#$*]{8,}"
                    required
                  />
                </InputGroup>
                <label htmlFor='show-passwords' style={{position: 'relative', width: '100%'}}>
                  <input 
                    id='show-passwords'
                    type='checkbox' 
                    name='show-passwords'
                    value='Show Passwords'
                    checked={showPasswords} 
                    onChange={()=>setShowPasswords(!showPasswords)}
                    style={{marginRight:'5px'}}
                  />
                    Show Passwords
                </label>
                <SubmitButton />
              </Form>
            </InputRow>
            {formError &&  (
              <Alert type="danger">
                <FormattedMessage
                  id={formError}
                  description="Alert error"
                  defaultMessage={intl.messages[formError]}
                />
              </Alert>
            )}
            {success && !formError && (
              <Alert type="success">
                <FormattedMessage
                  id={success}
                  description="Alert success"
                  defaultMessage={intl.messages[success]}
                />
              </Alert>
            )}
          </>
        )}
    </>
  );
};

LoginCredentialsForm.propTypes = {
  username: PropTypes.string.isRequired,
  onClick:  PropTypes.func.isRequired,
  iconType: PropTypes.string.isRequired,
};

export default LoginCredentialsForm;
