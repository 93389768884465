import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { get, put } from 'shared-components/src/utils/http';
import PropTypes from 'prop-types';
import {
  ActionBar,
  ButtonGroup,
  Input,
  Loader,
  Modal,
} from 'shared-components';
import { InputItem, InputRow, ModalFormContainer, StyledLabel } from './styles';

import SubmitButton from '../../../../components/SubmitButton';
import CancelButton from '../../../../components/CancelButton';
import Alert from '../../../../components/Alert';

const EditExternalDirectsendAccountV2Modal = ({
  toggleModal,
  setSuccess,
  setExternalAccounts,
  page,
  id,
}) => {
  const intl = useIntl();
  const [formData, setFormData] = useState({
    name: '',
    fullName: '',
    cardNumber: '',
    expiry: '',
    cvv: '',
    addressLineOne: '',
    addressLineTwo: '',
    city: '',
    state: '',
    country: '',
    postalCode: '',
  });

  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);
  const [lastFourDigits, setlastFourDigits] = useState('****');

  const fetchExternalAccount = async () => {
    try {
      return await get(
        `/api/v2/cardholder_portal/cardholder/external_accounts/${id}/details`
      );
    } catch (error) {
      throw new Error(error.message || 'Failed to fetch external account');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const externalAccount = await fetchExternalAccount(id);
        if (externalAccount) {
          setlastFourDigits(externalAccount.card.last_four_digits);
          setFormData({
            name: externalAccount.name,
            fullName: externalAccount.card.name,
            expiry: `${externalAccount.card.expiry_month}/${externalAccount.card.expiry_year}`,
            addressLineOne: externalAccount.card.address_line1,
            addressLineTwo: externalAccount.card.address_line2,
            city: externalAccount.card.address_city,
            state: externalAccount.card.address_state,
            country: externalAccount.card.address_country,
            postalCode: externalAccount.card.address_postal_code,
          });
        }
      } catch (error) {
        setAlert({ type: 'danger', message: error.message });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const updateContact = async () => {
    const {
      name,
      fullName,
      cardNumber,
      expiry,
      cvv,
      addressLineOne,
      addressLineTwo,
      city,
      state,
      country,
      postalCode,
    } = formData;
    const [expiryMonth, expiryYear] = expiry.split('/');

    try {
      const externalAccountPayload = {
        name,
        card: {
          name: fullName,
          number: cardNumber,
          cvv,
          expiry_month: expiryMonth,
          expiry_year: expiryYear,
          address_line1: addressLineOne,
          address_line2: addressLineTwo,
          address_city: city,
          address_state: state,
          address_country: country,
          address_postal_code: postalCode,
        },
      };

      await put(
        `/api/v2/cardholder_portal/cardholder/external_accounts/${id}`,
        externalAccountPayload
      );

      const response = await get(
        `/api/v1/cardholder_portal/cardholder/external_accounts/${id}`
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message || 'Failed to update external account');
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    setAlert(null);

    try {
      const updatedContact = await updateContact();
      setExternalAccounts(externalAccounts =>
        externalAccounts.map(account =>
          account.id === updatedContact.id ? updatedContact : account
        )
      );

      setSuccess({
        type: 'success',
        message: intl.messages[`${page}-success-update-alert`],
        messageFor: 'success-update',
        component: page,
        page,
      });

      setLoading(false);
      toggleModal(false);
    } catch (error) {
      setAlert({ type: 'danger', message: error.message });
      setLoading(false);
    }
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Modal>
      <ModalFormContainer>
        {alert && (
          <Alert
            alert={alert.message}
            alertFor={alert.messageFor}
            page={page}
            type={alert.type}
          />
        )}
        <h2>{intl.messages[`${page}-update-contact-heading`]}</h2>
        {loading ? (
          <Loader />
        ) : (
          <form onSubmit={e => handleSubmit(e)}>
            <InputRow>
              <InputItem>
                <StyledLabel htmlFor="contact-name-input">
                  <span>*</span>{' '}
                  {intl.messages[`${page}-contact-name-input-label`]}
                </StyledLabel>
                <Input
                  id="contact-name-input"
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </InputItem>
              <InputItem>
                <StyledLabel htmlFor="contact-full-cardholder-name-input">
                  <span>*</span>{' '}
                  {
                    intl.messages[
                      `${page}-contact-full-cardholder-name-input-label`
                    ]
                  }
                </StyledLabel>
                <Input
                  id="contact-full-cardholder-name-input"
                  type="text"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  required
                />
              </InputItem>
            </InputRow>
            <InputRow>
              <InputItem>
                <StyledLabel htmlFor="contact-card-number-input">
                  <span>*</span>{' '}
                  {intl.messages[`${page}-contact-card-number-input-label`]}
                </StyledLabel>
                <Input
                  id="contact-card-number-input"
                  type="text"
                  name="cardNumber"
                  value={formData.cardNumber}
                  onChange={handleChange}
                  placeholder={`**** **** **** ${lastFourDigits}`}
                  required
                  pattern="\d{16}"
                  title="Please enter a 16-digit card number"
                />
              </InputItem>
              <InputItem>
                <InputRow>
                  <InputItem>
                    <StyledLabel htmlFor="contact-expiry-input">
                      <span>*</span>{' '}
                      {intl.messages[`${page}-contact-expiry-input-label`]}
                    </StyledLabel>
                    <Input
                      id="contact-expiry-input"
                      type="text"
                      name="expiry"
                      value={formData.expiry}
                      onChange={handleChange}
                      required
                      pattern="(0[1-9]|1[0-2])\/[0-9]{4}"
                      title="Please enter a valid MM/YYYY"
                    />
                  </InputItem>
                  <InputItem>
                    <StyledLabel htmlFor="contact-cvv-input">
                      <span>*</span>{' '}
                      {intl.messages[`${page}-contact-cvv-input-label`]}
                    </StyledLabel>
                    <Input
                      id="contact-cvv-input"
                      type="text"
                      name="cvv"
                      value={formData.cvv}
                      onChange={handleChange}
                      placeholder="***"
                      required
                      pattern="\d{3}"
                      title="Please enter a 3-digit CVV"
                    />
                  </InputItem>
                </InputRow>
              </InputItem>
            </InputRow>
            <StyledLabel htmlFor="contact-address-line-one-input">
              <span>*</span>{' '}
              {intl.messages[`${page}-contact-address-line-one-input-label`]}
            </StyledLabel>
            <Input
              id="contact-address-line-one-input"
              type="text"
              name="addressLineOne"
              value={formData.addressLineOne}
              onChange={handleChange}
              required
            />
            <StyledLabel htmlFor="contact-address-line-two-input">
              {intl.messages[`${page}-contact-address-line-two-input-label`]}
            </StyledLabel>
            <Input
              id="contact-address-line-two-input"
              type="text"
              name="addressLineTwo"
              value={formData.addressLineTwo}
              onChange={handleChange}
            />
            <InputRow>
              <InputItem>
                <StyledLabel htmlFor="contact-city-input">
                  <span>*</span>{' '}
                  {intl.messages[`${page}-contact-city-input-label`]}
                </StyledLabel>
                <Input
                  id="contact-city-input"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  required
                />
              </InputItem>
              <InputItem>
                <StyledLabel htmlFor="contact-state-input">
                  <span>*</span>{' '}
                  {intl.messages[`${page}-contact-state-input-label`]}
                </StyledLabel>
                <Input
                  id="contact-state-input"
                  type="text"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  required
                  pattern="[A-Z]{2}"
                  title="Please enter a 2-letter state abbreviation"
                />
              </InputItem>
            </InputRow>
            <InputRow>
              <InputItem>
                <StyledLabel htmlFor="contact-postal-code-input">
                  <span>*</span>{' '}
                  {intl.messages[`${page}-contact-postal-code-input-label`]}
                </StyledLabel>
                <Input
                  id="contact-postal-code-input"
                  type="text"
                  name="postalCode"
                  value={formData.postalCode}
                  onChange={handleChange}
                  required
                  pattern="[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d"
                  title="Please enter a valid postal code (e.g., A1A 1A1 or A1A1A1)"
                />
              </InputItem>
              <InputItem>
                <StyledLabel htmlFor="contact-country-input">
                  <span>*</span>{' '}
                  {intl.messages[`${page}-contact-country-input-label`]}
                </StyledLabel>
                <Input
                  id="contact-country-input"
                  type="text"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  required
                  title="The country code must be 'CAN'"
                  pattern="CAN" // Only allows "CAN" for now
                />
              </InputItem>
            </InputRow>
            <ActionBar>
              <ButtonGroup reverse>
                <SubmitButton />
                <CancelButton
                  onClick={e => {
                    e.preventDefault();
                    toggleModal(false);
                  }}
                />
              </ButtonGroup>
            </ActionBar>
          </form>
        )}
      </ModalFormContainer>
    </Modal>
  );
};

EditExternalDirectsendAccountV2Modal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
  setExternalAccounts: PropTypes.func.isRequired,
  page: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
};

export default EditExternalDirectsendAccountV2Modal;
