export const recipientAccountEn = {
  'recipient-account-heading': 'Recipient Accounts',
  'recipient-account-add-new-button': 'Add New Recipient',
  'recipient-account-list-title': 'List of Recipients',
  'recipient-account-update-heading': 'Update Recipient',
  'recipient-account-name-label': 'Name:',
  'recipient-account-email-label': 'Email:',
  'recipient-account-proxy-label': 'Proxy Number:',
  'recipient-account-success-update-alert':
    'Your recipient\'s account was updated successfully.',
  'recipient-account-success-delete-alert':
    'Your recipient\'s account was deleted successfully.',
  'recipient-account-delete-form-text':
    'Are you sure you want to delete <b>{name}</b> from the list of recipients?',
  'recipient-account-delete-heading': 'Delete Recipient',
};
export const recipientAccountFr = {
  'recipient-account-heading': 'Comptes destinataires',
  'recipient-account-add-new-button': 'Ajouter un nouveau destinataire',
  'recipient-account-list-title': 'Liste des destinataires',
  'recipient-account-update-heading': 'Mettre à jour le destinataire',
  'recipient-account-name-label': 'Nom:',
  'recipient-account-email-label': 'Email:',
  'recipient-account-proxy-label': 'Numéro de proxy:',
  'recipient-account-success-update-alert':
    'Le compte de votre destinataire a été mis à jour avec succès',
  'recipient-account-success-delete-alert':
    'Le compte de votre destinaire a été effacer avec succès',
  'recipient-account-delete-form-text':
    'Êtes-vous sûr que vous voulez effacer <b>{name}</b> de la liste des destinaires',
  'recipient-account-delete-heading': 'Effacer destinaire',
};
