export const etransferPullEn = {
  'etransfer-pull-heading': 'Request Funds',
  'etransfer-pull-add-contact-alert':
    'No Contacts found, please add a new contact to continue',
  'etransfer-pull-new-contact-button': 'Add Contact',
  'etransfer-pull-create-contact-heading': 'Add Contact',
  'etransfer-pull-update-contact-heading': 'Update Contact',
  'etransfer-pull-delete-heading': 'Delete Contact',
  'etransfer-pull-contact-name-input-label': 'External Account Name',
  'etransfer-pull-contact-first-name-input-label': 'First Name',
  'etransfer-pull-contact-last-name-input-label': 'Last Name',
  'etransfer-pull-contact-email-input-label': 'Email',
  'etransfer-pull-contact-confirm-email-input-label': 'Confirm Email',
  'etransfer-pull-create-contact': 'add Contact',
  'etransfer-pull-success-create-alert': 'Contact successfully added',
  'etransfer-pull-account-deleted-alert': 'Contact successfully deleted',
  'etransfer-pull-success-update-alert': 'Contact successfully updated',
  'etransfer-pull-form-title': 'Request Funds to my Account',
  'etransfer-pull-form-text': 'From External Contact with Interac e-Transfer',
  'etransfer-pull-delete-form-text':
    'Are you sure you want to delete your contact - {name} ?',
  'etransfer-pull-form-select-external-account-label':
    'Select a contact to request funds from',
  'etransfer-pull-amount-label': 'Amount',
  'etransfer-pull-exceeds-available-amount-alert':
    'Amount exceeds available balance',
  'etransfer-pull-transfer-created-alert': 'Funds requested successfully',
  'etransfer-pull-confirm-transfer-heading': 'Confirm Transfer',
  'etransfer-pull-message-label': 'Message',
  'etransfer-pull-confirm-transfer-message':
    'This transfer has a fee of ${feeAmount}. Do you wish to continue with this funds request?', // eslint-disable-line no-template-curly-in-string
  'etransfer-pull-confirm-transfer-no-fee-message':
    'Do you wish to continue with this funds request?',
};

export const etransferPullFr = {
  'etransfer-pull-heading': 'Demander des fonds',
  'etransfer-pull-add-contact-alert':
    'Aucun contact trouvé, veuillez ajouter un nouveau contact pour continuer',
  'etransfer-pull-new-contact-button': 'Ajouter un contact',
  'etransfer-pull-contact-name-input-label': 'Nom de compte externe',
  'etransfer-pull-contact-first-name-input-label': 'Prénom',
  'etransfer-pull-contact-last-name-input-label': 'Nom de famille',
  'etransfer-pull-contact-email-input-label': 'E-mail',
  'etransfer-pull-contact-confirm-email-input-label': 'Confirmer l’e-mail',
  'etransfer-pull-create-contact-heading': 'Ajouter un contact',
  'etransfer-pull-create-contact': 'Ajouter un contact',
  'etransfer-pull-update-contact-heading': 'Mettre à jour le contact',
  'etransfer-pull-delete-heading': 'Supprimer le contact',
  'etransfer-pull-success-create-alert': 'Contact ajouté avec succès',
  'etransfer-pull-account-deleted-alert': 'Contact supprimé avec succès',
  'etransfer-pull-success-update-alert': 'Contact mis à jour avec succès',
  'etransfer-pull-form-title': 'Demander des fonds sur mon compte',
  'etransfer-pull-form-text':
    'D’un contact externe avec Interac virement électronique',
  'etransfer-pull-delete-form-text':
    'Êtes-vous sûr de vouloir supprimer votre contact - {name} ?',
  'etransfer-pull-form-select-external-account-label':
    'Sélectionnez un contact à qui demander des fonds',
  'etransfer-pull-amount-label': 'Montant',
  'etransfer-pull-exceeds-available-amount-alert':
    'Le montant dépasse le solde disponible',
  'etransfer-pull-transfer-created-alert': 'Fonds demandés avec succès',
  'etransfer-pull-confirm-transfer-heading': 'Confirmer le transfert',
  'etransfer-pull-message-label': 'Message',
  'etransfer-pull-confirm-transfer-message':
    'Ce virement comporte des frais de ${feeAmount}. Souhaitez-vous continuer avec cette demande de fonds?', // eslint-disable-line no-template-curly-in-string
  'etransfer-pull-confirm-transfer-no-fee-message':
    'Souhaitez-vous continuer avec cette demande de fonds?',
};
