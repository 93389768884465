import React, { useState, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
// Import Shared Components
import {
  PasswordRequirements,
  UsernameRequirements,
  Input,
  Checkbox
} from 'shared-components';
// Import Shared Hooks
import { useUpdateStateObject } from 'shared-components/src/hooks/useUpdateStateObject';
// Import Shared utils
import { post } from 'shared-components/src/utils/http';
import { ThemeContext } from 'styled-components';
// Import Shared Validations
import { verifyRegisterValidations } from 'shared-components/src/validations/cardholderValidations';
// Import Global Components
import InfoTooltip from '../../../components/InfoTooltip';
import InputGroup from '../../../layouts/InputGroup';
// Import Local Components
import AuthLayout from '../layouts/Auth';
import { AuthForm } from '../layouts/InnerAuth';
// Import Component Styles
import { FormGroup, FormRow } from '../styles';
// Import Utils
import { login } from '../../../utils/auth';
import {
  validateCardNumber,
  validateUsername,
  validatePassword,
  validatePasswordConfirmation,
  checkForValidationErrors,
  validateExpiry,
  validateCVV,
} from '../../../utils/validationHandlers';

import { authEn } from '../../../i18n/auth';
import TermsOfUseLink  from './components/TermsOfUseLink';

export default () => {
  // Set States
  const initialRegisterInfo = {
    cardNumber: '',
    expiry: '',
    cvv: '',
    username: '',
    password: '',
    passwordConfirmation: '',
  };

  const initialValidationErrors = {
    cardNumber: '',
    expiry: '',
    cvv: '',
    username: '',
    password: '',
    passwordConfirmation: '',
  };
  const [formError, setFormError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);

  const [termsOfUse, setTermsOfUse] = useState(false);

  // Create intl instance
  const intl = useIntl();
  const theme = useContext(ThemeContext);

  // Create reducer with initial card information state
  // Returns the updated state to be passed to components and a function to update the state
  const [registerInfo, setRegisterInfo] = useUpdateStateObject(
    initialRegisterInfo
  );
  const [validationErrors, setValidationErrors] = useUpdateStateObject(
    initialValidationErrors
  );

  // Set Handlers
  // eslint-disable-next-line consistent-return
  const handleRegisterCard = async () => {
    // Check if username meets requirements
    if (!verifyRegisterValidations.username(registerInfo.username))
      return setFormError('invalid-username-format-error');
    // Check if password meets requirements
    if (!verifyRegisterValidations.password(registerInfo.password))
      return setFormError('invalid-password-format-error');
    // Check if password and password confirmation match
    if (registerInfo.password !== registerInfo.passwordConfirmation)
      return setFormError('invalid-password-confirmation-format-error');

    // Throws a generic error message if any of the fields are not valid
    if (
      Object.keys(validationErrors).find(field => {
        return validationErrors[field] === true;
      })
    )
      return setFormError('invalid-form-submit-error');

    // Extract expiry month and year
    const expiryMatches = registerInfo.expiry.match(/^(\d{2})(\d{4})$/);
    const expiryMonth = expiryMatches[1];
    const expiryYear = expiryMatches[2];

    const activatePayload = {
      card_number: registerInfo.cardNumber,
      expiry_month: expiryMonth,
      expiry_year: expiryYear,
      cvv: registerInfo.cvv,
      username: registerInfo.username,
      password: registerInfo.password,
      password_confirmation: registerInfo.passwordConfirmation,
    };

    try {
      setLoading(true);
      setFormError(false);
      setSuccess(false);

      const res = await post(
        '/api/v1/cardholder_portal/register',
        activatePayload
      );

      if (res.error) {
        throw new Error('register-cardholder-failed-alert');
      }

      setRegisterInfo({ key: 'cardNumber', value: '' });
      setRegisterInfo({ key: 'expiry', value: '' });
      setRegisterInfo({ key: 'cvv', value: '' });
      setRegisterInfo({ key: 'username', value: '' });
      setRegisterInfo({ key: 'password', value: '' });
      setRegisterInfo({ key: 'passwordConfirmation', value: '' });

      setValidationErrors({ key: 'cardNumber', value: '' });
      setValidationErrors({ key: 'expiry', value: '' });
      setValidationErrors({ key: 'cvv', value: '' });
      setValidationErrors({ key: 'username', value: '' });
      setValidationErrors({ key: 'password', value: '' });
      setValidationErrors({ key: 'passwordConfirmation', value: '' });
      setSuccess('register-cardholder-success-alert');

      // Cardholder has registered successfully and page state has been cleared
      // so we can now auto login the Cardholder to the Portal
      await login(registerInfo.username, registerInfo.password);
    } catch (error) {
      setFormError(error.message);
    }
    setLoading(false);
  };

  const handleRegisterKeyUp = async e => {
    if (e.keyCode === 13) {
      await handleRegisterCard();
    }
  };

  const areAllAgreementsChecked = () => {
    if (theme.brand.registerTermsOfUseEn && theme.brand.registerTermsOfUseFr && !termsOfUse) {
      return false;
    }

    return true;
  };

  const submitButtonDisabled = areAllAgreementsChecked();

  // just to keep track
  // TODO: Need to get correct wording for this page from Operations
  return (
    <AuthLayout
      page="register"
      heading={intl.messages['register-heading']}
      subHeaderOne={intl.messages['register-subheader1-text']}
      subHeaderTwo={intl.messages['register-subheader2-text']}
      error={formError}
      success={success}
    >
      <AuthForm
        onSubmit={e => {
          e.preventDefault();
          checkForValidationErrors(validationErrors, setFormError);
          handleRegisterCard();
        }}
        onKeyUp={handleRegisterKeyUp}
        loading={loading}
        submitButtonDisabled={!submitButtonDisabled}
      >
        <FormGroup>
          <InputGroup
            label={intl.messages['auth-card-number-label']}
            labelFor="card-number"
            page="auth"
            required
          >
            <Input
              value={registerInfo.cardNumber}
              onBlur={() =>
                validateCardNumber(setValidationErrors, registerInfo)}
              minLength={13}
              maxLength={19}
              onChange={e => {
                return setRegisterInfo({
                  key: 'cardNumber',
                  value: e.target.value.trim(),
                });
              }}
              required
            />
          </InputGroup>
        </FormGroup>
        <FormRow>
          <InputGroup
            label={intl.messages['auth-cvv-label']}
            labelFor="cvv"
            page="auth"
            required
          >
            <Input
              value={registerInfo.cvv}
              onBlur={() => validateCVV(setValidationErrors, registerInfo)}
              minLength={3}
              maxLength={4}
              type="password"
              onChange={e => {
                return setRegisterInfo({
                  key: 'cvv',
                  value: e.target.value.trim(),
                });
              }}
              required
            />
          </InputGroup>
          <InputGroup
            label={intl.messages['auth-expiry-label']}
            labelFor="expiry"
            page="auth"
            required
          >
            <Input
              value={registerInfo.expiry.replace(/^(\d{2})(\d{4})/, '$1 / $2')}
              onBlur={() => validateExpiry(setValidationErrors, registerInfo)}
              placeholder="MM / YYYY"
              maxLength={6}
              minLength={6}
              onChange={e => {
                return setRegisterInfo({
                  key: 'expiry',
                  value: e.target.value.trim(),
                });
              }}
              required
            />
          </InputGroup>
        </FormRow>
        <FormGroup>
          <InputGroup
            tooltip={(
              <InfoTooltip
                position="top"
                content={<UsernameRequirements messages={intl.messages} />}
              />
            )}
            label={intl.messages['auth-username-label']}
            labelFor="username"
            page="auth"
            required
          >
            <Input
              value={registerInfo.username}
              error={validationErrors.username}
              onBlur={() => validateUsername(setValidationErrors, registerInfo)}
              onChange={e => {
                setRegisterInfo({
                  key: 'username',
                  value: e.target.value.trim(),
                });
              }}
              minLength={3}
              maxLength={30}
              required
            />
          </InputGroup>
          <InputGroup
            tooltip={(
              <InfoTooltip
                position="top"
                content={<PasswordRequirements messages={intl.messages} />}
              />
            )}
            label={intl.messages['auth-password-label']}
            labelFor="password"
            page="auth"
            required
          >
            <Input
              value={registerInfo.password}
              error={validationErrors.password}
              onBlur={() => validatePassword(setValidationErrors, registerInfo)}
              onChange={e => {
                setRegisterInfo({
                  key: 'password',
                  value: e.target.value.trim(),
                });
              }}
              type="password"
              pattern="(?=.*[\d])(?=.*[A-Z])(?=.*[!@#$*])[\w!@#$*]{8,}"
              minLength={8}
              required
            />
          </InputGroup>
          <InputGroup
            label={intl.messages['auth-password-confirmation-label']}
            labelFor="password-confirmation"
            page="auth"
            required
          >
            <Input
              value={registerInfo.passwordConfirmation}
              error={validationErrors.passwordConfirmation}
              onBlur={() =>
                validatePasswordConfirmation(setValidationErrors, registerInfo)}
              onChange={e => {
                setRegisterInfo({
                  key: 'passwordConfirmation',
                  value: e.target.value.trim(),
                });
              }}
              type="password"
              minLength={8}
              pattern="(?=.*[\d])(?=.*[A-Z])(?=.*[!@#$*])[\w!@#$*]{8,}"
              required
            />
          </InputGroup>
        </FormGroup>
        {(theme.brand.registerTermsOfUseEn && theme.brand.registerTermsOfUseFr) && (
          <FormRow>
            <Checkbox
              label={(
                <FormattedMessage
                  id="register-terms-of-use-label"
                  description="Label for Terms of Use"
                  defaultMessage={authEn['register-terms-of-use-label']}
                  values={{
                    link: (
                      <TermsOfUseLink />
                    ),
                  }}
                />
              )}
              labelFor="registerTermsOfUseInput"
              id="register-terms-of-use-input"
              value={termsOfUse}
              onChange={e => setTermsOfUse(e.target.checked)}
            />
          </FormRow>
        )}
      </AuthForm>
    </AuthLayout>
  );
};
