import React, { useState } from 'react';
import { withTheme } from 'styled-components';
// Import Components
import Logo from './components/HeaderLogo';
import Nav from './layouts/HeaderNav';
// Import Component Styles
import { Header, HeaderWrapper, MenuButton } from './styles';

const HeaderSection = withTheme(({ theme, ...props }) => {
  const [open, setOpen] = useState(false);

  return (
    <HeaderWrapper>
      <Header as="nav">
        <Logo locale={props.locale} />
        <MenuButton
          iconType={open ? 'x' : 'menu'}
          open={open}
          color="white"
          iconSize="25"
          onClick={() => setOpen(!open)}
        />
        <Nav {...props} open={open} setOpen={setOpen} page="nav" />
      </Header>
    </HeaderWrapper>
  );
});

export default HeaderSection;
