import styled from 'styled-components';
import {
  Card as UnstyledCard,
  Button as UnstyledButton,
} from '../../../../../../components/TransferTypeButton/styles';

export const Card = styled(UnstyledCard)`
  padding: 10px;
  height: auto;
  min-width: auto;
  background-color: ${({ selected, theme }) => selected && theme.colors.grey};
`;

export const Button = styled(UnstyledButton)`
  padding: 0
  border: none;
`;

export const ImgWrapper = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
`;
