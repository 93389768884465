import React from 'react';
// Import Global Components
import Heading from '../../components/Heading';
import Card from '../../components/Card';
import Title from '../../components/Title';
import Text from '../../components/Text';
// Import Component Styles
import { IntroText, CardSections, AlphaListItem, About } from './styles';
// Import Translations
import { aboutEn } from '../../i18n/about';

export default () => (
  <>
    <Heading page="about" heading="About Your Prepaid Card" />
    <Card>
      <IntroText>
        <Text
          page="about"
          textFor="intro"
          defaultMessage={aboutEn['about-intro-text']}
          values={{
            cardholder: chunks => (
              <b>
                <a
                  href="/cardholder-agreement"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {chunks}
                </a>
              </b>
            ),
          }}
        />
      </IntroText>
      <About>
        <CardSections>
          <div>
            <Title
              page="about"
              titleFor="h1"
              defaultMessage={aboutEn['about-h1-title']}
            />
            <Text
              page="about"
              textFor="p1"
              defaultMessage={aboutEn['about-p1-text']}
            />
          </div>
          <div>
            <Title
              page="about"
              titleFor="h2"
              defaultMessage={aboutEn['about-h2-title']}
            />
            <Text
              page="about"
              textFor="p2"
              defaultMessage={aboutEn['about-p2-text']}
            />
          </div>
          <div>
            <Title
              page="about"
              titleFor="h3"
              defaultMessage={aboutEn['about-h3-title']}
            />
            <Text
              page="about"
              textFor="p3"
              defaultMessage={aboutEn['about-p3-text']}
            />
          </div>
          <div>
            <Title
              page="about"
              titleFor="h4"
              defaultMessage={aboutEn['about-h4-title']}
            />
            <Text
              page="about"
              textFor="p4"
              defaultMessage={aboutEn['about-p4-text']}
            />
          </div>
          <div>
            <Title
              page="about"
              titleFor="h5"
              defaultMessage={aboutEn['about-h5-title']}
            />
            <Text
              page="about"
              textFor="p5"
              defaultMessage={aboutEn['about-p5-text']}
            />
          </div>
          <div>
            <Title
              page="about"
              titleFor="h6"
              defaultMessage={aboutEn['about-h6-title']}
            />
            <Text
              page="about"
              textFor="p6"
              defaultMessage={aboutEn['about-p6-text']}
            />
            <ol>
              <AlphaListItem>
                <Text
                  textFor="li1"
                  page="about"
                  defaultMessage={aboutEn['about-li1-text']}
                />
              </AlphaListItem>
              <AlphaListItem>
                <Text
                  textFor="li2"
                  page="about"
                  defaultMessage={aboutEn['about-li2-text']}
                />
              </AlphaListItem>
              <AlphaListItem>
                <Text
                  textFor="li3"
                  page="about"
                  defaultMessage={aboutEn['about-li3-text']}
                />
              </AlphaListItem>
              <AlphaListItem>
                <Text
                  textFor="li4"
                  page="about"
                  defaultMessage={aboutEn['about-li4-text']}
                />
              </AlphaListItem>
            </ol>
          </div>
          <div>
            <Title
              page="about"
              titleFor="h7"
              defaultMessage={aboutEn['about-h7-title']}
            />
            <Text
              page="about"
              textFor="p7"
              defaultMessage={aboutEn['about-p7-text']}
            />
          </div>
          <div>
            <Title
              page="about"
              titleFor="h8"
              defaultMessage={aboutEn['about-h8-title']}
            />
            <Text
              page="about"
              textFor="p8"
              defaultMessage={aboutEn['about-p8-text']}
            />
          </div>
          <div>
            <Title
              page="about"
              titleFor="h9"
              defaultMessage={aboutEn['about-h9-title']}
            />
            <Text
              page="about"
              textFor="p9"
              defaultMessage={aboutEn['about-p9-text']}
            />
          </div>
          <div>
            <Title
              page="about"
              titleFor="h10"
              defaultMessage={aboutEn['about-h10-title']}
            />
            <Text
              page="about"
              textFor="p10"
              defaultMessage={aboutEn['about-p10-text']}
            />
          </div>
          <div>
            <Title
              page="about"
              titleFor="h11"
              defaultMessage={aboutEn['about-h11-title']}
            />
            <Text
              page="about"
              textFor="p11"
              defaultMessage={aboutEn['about-p11-text']}
            />
          </div>
          <div>
            <Title
              page="about"
              titleFor="h12"
              defaultMessage={aboutEn['about-h12-title']}
            />
            <Text
              page="about"
              textFor="p12"
              defaultMessage={aboutEn['about-p12-text']}
            />
          </div>
        </CardSections>

        <CardSections>
          <div>
            <Title
              page="about"
              titleFor="h13"
              defaultMessage={aboutEn['about-h13-title']}
            />
            <Text
              page="about"
              textFor="p13"
              defaultMessage={aboutEn['about-p13-text']}
            />
          </div>
          <div>
            <Title
              page="about"
              titleFor="h14"
              defaultMessage={aboutEn['about-h14-title']}
            />
            <Text
              page="about"
              textFor="p14"
              defaultMessage={aboutEn['about-p14-text']}
            />
          </div>
          <div>
            <Title
              page="about"
              titleFor="h15"
              defaultMessage={aboutEn['about-h15-title']}
            />
            <Text
              page="about"
              textFor="p15"
              defaultMessage={aboutEn['about-p15-text']}
            />
          </div>
          <div>
            <Title
              page="about"
              titleFor="h16"
              defaultMessage={aboutEn['about-h16-title']}
            />
            <Text
              page="about"
              textFor="p16"
              defaultMessage={aboutEn['about-p16-text']}
            />
          </div>
          <div>
            <Title
              page="about"
              titleFor="h17"
              defaultMessage={aboutEn['about-h17-title']}
            />
            <Text
              page="about"
              textFor="p17"
              defaultMessage={aboutEn['about-p17-text']}
            />
          </div>
          <div>
            <Title
              page="about"
              titleFor="h18"
              defaultMessage={aboutEn['about-h18-title']}
            />
            <Text
              page="about"
              textFor="p18"
              defaultMessage={aboutEn['about-p18-text']}
            />
          </div>
          <div>
            <Title
              page="about"
              titleFor="h19"
              defaultMessage={aboutEn['about-h19-title']}
            />
            <ol>
              <AlphaListItem>
                <Text
                  textFor="li5"
                  page="about"
                  defaultMessage={aboutEn['about-li5-text']}
                />
              </AlphaListItem>
              <AlphaListItem>
                <Text
                  textFor="li6"
                  page="about"
                  defaultMessage={aboutEn['about-li6-text']}
                />
              </AlphaListItem>
              <AlphaListItem>
                <Text
                  textFor="li7"
                  page="about"
                  defaultMessage={aboutEn['about-li7-text']}
                />
              </AlphaListItem>
              <AlphaListItem>
                <Text
                  textFor="li8"
                  page="about"
                  defaultMessage={aboutEn['about-li8-text']}
                />
              </AlphaListItem>
              <AlphaListItem>
                <Text
                  textFor="li9"
                  page="about"
                  defaultMessage={aboutEn['about-li9-text']}
                />
              </AlphaListItem>
            </ol>
          </div>
          <div>
            <Title
              page="about"
              titleFor="h20"
              defaultMessage={aboutEn['about-h20-title']}
            />
            <Text
              page="about"
              textFor="p20"
              defaultMessage={aboutEn['about-p20-text']}
            />
          </div>
          <div>
            <Title
              page="about"
              titleFor="h21"
              defaultMessage={aboutEn['about-h21-title']}
            />
            <Text
              page="about"
              textFor="p21"
              defaultMessage={aboutEn['about-p21-text']}
            />
          </div>
          <div>
            <Title
              page="about"
              titleFor="h22"
              defaultMessage={aboutEn['about-h22-title']}
            />
            <Text
              page="about"
              textFor="p22"
              defaultMessage={aboutEn['about-p22-text']}
            />
          </div>
        </CardSections>
      </About>
    </Card>
  </>
);
