import styled from 'styled-components';

// Immediate child to Modal component.
// Contains Form component and any other components that sit within the Modal.
export const ModalFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  min-width: 300px;

  h1 {
    margin-top: 0;
  }

  ${({ theme }) => theme.media.tabletPortraitUp`
    max-width: 700px;
    min-width: 500px;
    padding: 20px;
  `}

  ${({ theme }) => theme.media.tabletLandscapeUp`
    width: 700px;
  `}
`;

// Styles the form
export default styled.form`
  & > *:not(:last-child) {
    margin-bottom: 15px;
  }

  h3:not(:first-child) {
    padding-top: 20px;
  }

  ${({ theme }) => theme.media.tabletPortraitUp`
    padding: 0 50px;
  `}
`;
