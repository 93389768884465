import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
// Import Shared Components
import { Loader, SubmitButton } from 'shared-components';
// Import Global Components
import Button from '../../../../components/Button';
// Import Component Styles
import { Form, InputContainer } from './styles';

export const AuthForm = ({
  page,
  onSubmit,
  onKeyUp,
  submitButtonDisabled,
  secondaryButtonFunc,
  secondaryButtonText,
  loading,
  children,
}) => {
  // Create intl instance
  const intl = useIntl();

  if (loading) return <Loader />;

  return (
    <Form onSubmit={onSubmit} onKeyUp={onKeyUp}>
      <InputContainer>{children}</InputContainer>
      <SubmitButton
        value={intl.formatMessage({
          id: 'auth-submit-button',
          description: 'Auth form submit button',
          defaultMessage: 'Submit',
        })}
        buttonType="primary"
        size="large"
        disabled={submitButtonDisabled}
      />
      {secondaryButtonFunc && (
        <Button
          buttonText={secondaryButtonText}
          buttonFor="secondary"
          page={page}
          onClick={secondaryButtonFunc}
          variant="text"
        />
      )}
    </Form>
  );
};

AuthForm.propTypes = {
  page: PropTypes.string.isRequired,
  onKeyUp: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  secondaryButtonText: PropTypes.string,
  secondaryButtonFunc: PropTypes.func,
  submitButtonDisabled: PropTypes.bool,
};

AuthForm.defaultProps = {
  secondaryButtonText: null,
  secondaryButtonFunc: null,
  submitButtonDisabled: null,
};

export const AuthNonForm = ({
  page,
  onClick,
  buttonText,
  secondaryButtonFunc,
  secondaryButtonText,
  loading,
  children,
}) => {
  if (loading) return <Loader />;

  return (
    <Form as="div">
      <InputContainer as="div">{children}</InputContainer>
      {onClick && (
        <Button
          buttonText={buttonText}
          buttonFor="primary"
          page={page}
          onClick={onClick}
          type="primary"
          size="large"
        />
      )}
      {secondaryButtonFunc && (
        <Button
          buttonText={secondaryButtonText}
          buttonFor="secondary"
          page={page}
          onClick={secondaryButtonFunc}
          variant="text"
        />
      )}
    </Form>
  );
};

AuthNonForm.propTypes = {
  page: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  buttonText: PropTypes.string,
  secondaryButtonText: PropTypes.string,
  secondaryButtonFunc: PropTypes.func,
};

AuthNonForm.defaultProps = {
  secondaryButtonText: null,
  secondaryButtonFunc: null,
  onClick: null,
  buttonText: null,
  loading: null,
};
