import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
// Import Shared Components
import { ActionBar, Input } from 'shared-components';
import { numberDollarsToCents } from 'shared-components/src/utils/currency';
// Import Global Layouts
import InputGroup from '../../../../../../../layouts/InputGroup';
import InputRow from '../../../../../../../layouts/InputRow';
import Form from '../../../../../../../layouts/Form';
import Fieldset from '../../../../../../../layouts/Fieldset';
// Import Global Components
import SubmitButton from '../../../../../../../components/SubmitButton';
import TransferFeeTooltip from '../../../../../../../components/TransferFeeTooltip';
// Import Local Components
import InteracDetailsText from './InteracDetailsText';
// Import styles
import { Legend } from '../../../styles';

// Form for External Transfer to a Personal Bank Account
const TransferForm = ({
  setAlert,
  balance,
  disableSubmit,
  createPersonalTransfer,
  selectedNetwork,
  children,
}) => {
  const intl = useIntl();

  const descriptionInput = useRef(null);
  const securityQuestionInput = useRef(null);
  const securityAnswerInput = useRef(null);
  const [amountInput, setAmountInput] = useState(0);

  // Define States
  const [transferNameInput, setTransferNameInput] = useState(
    `Transfer ${Date.now()}`
  );

  const page = 'personal-account';

  const handleSubmit = e => {
    e.preventDefault();
    setAlert(null);

    // Check that entered amount is less than available balance
    if (parseInt(amountInput, 10) > parseInt(balance, 10))
      return setAlert({
        type: 'danger',
        message: intl.messages[`${page}-exceeds-available-amount-alert`],
        messageFor: 'exceeds-available-amount',
      });

    const basicParams = {
      amount: numberDollarsToCents(amountInput),
      external_tag: transferNameInput,
      message: descriptionInput.current.value,
    };

    const params =
      selectedNetwork === 'etransfer'
        ? {
          security_question: securityQuestionInput.current.value,
          security_question_answer: securityAnswerInput.current.value,
          ...basicParams,
        }
        : basicParams;

    return createPersonalTransfer(params);
  };
  return (
    <Form onSubmit={e => handleSubmit(e)}>
      <InputRow>{children}</InputRow>
      <Fieldset>
        <Legend
          legend={intl.messages[`${page}-transfer-details-legend`]}
          legendFor="transfer-details"
          page={page}
        />
        <InputRow>
          <InputGroup
            required
            label={intl.messages[`${page}-transfer-name-label`]}
            labelFor="transfer-name"
            page={page}
          >
            <Input
              max="50"
              name="transfer-name"
              pattern="[a-zA-Z0-9\- ]*"
              title="Please use letters, numbers, and dashes only"
              value={transferNameInput}
              onChange={e => setTransferNameInput(e.target.value)}
            />
          </InputGroup>
          <InputGroup
            required
            label={intl.messages[`${page}-amount-label`]}
            labelFor="amount"
            page={page}
          >
            <Input
              name="amount"
              pattern="[0-9\.]"
              title="Please enter a valid amount"
              placeholder="00.00"
              type="number"
              step="0.01"
              min={1}
              value={amountInput}
              onChange={e => setAmountInput(e.target.value)}
              required
            />
          </InputGroup>
        </InputRow>
        <TransferFeeTooltip
          transferAmount={amountInput}
          feeType="external_bank"
          page={page}
        />
        <InputRow>
          <InputGroup
            label={intl.messages[`${page}-description-label`]}
            labelFor="description"
            page={page}
          >
            <Input ref={descriptionInput} as="textarea" max="300" />
          </InputGroup>
        </InputRow>
      </Fieldset>
      {selectedNetwork === 'etransfer' && (
        <Fieldset>
          <Legend
            legend={intl.messages[`${page}-interac-details-legend`]}
            legendFor="interac-details"
            page={page}
            style={{ paddingBottom: '2px' }}
          />
          <InteracDetailsText />
          <InputRow>
            <InputGroup
              required
              label={intl.messages['security-question-label']}
              labelFor="security-question"
            >
              <Input
                max="50"
                name="security-question"
                ref={securityQuestionInput}
                required
              />
            </InputGroup>
          </InputRow>
          <InputRow>
            <InputGroup
              required
              label={intl.messages['security-answer-label']}
              labelFor="security-answer"
            >
              <Input
                name="security-answer"
                ref={securityAnswerInput}
                required
                pattern="[0-9A-Za-z]{3,25}"
                title="Must be alphanumeric and between 3 to 25 characters"
              />
            </InputGroup>
          </InputRow>
        </Fieldset>
      )}
      <ActionBar>
        <SubmitButton disabled={disableSubmit} />
      </ActionBar>
    </Form>
  );
};

TransferForm.propTypes = {
  setAlert: PropTypes.func.isRequired,
  disableSubmit: PropTypes.bool.isRequired,
  selectedNetwork: PropTypes.string.isRequired,
  createPersonalTransfer: PropTypes.func.isRequired,
  balance: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default TransferForm;
