import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// Import Shared Components
import { Loader, ErrorComponent } from 'shared-components';
// Import Shared Utils
import { get, post } from 'shared-components/src/utils/http';
// Import Translations
import { accountToAccountEn } from '../../../../i18n/accountToAccount';
// Import Global Components
import Heading from '../../../../components/Heading';
import Title from '../../../../components/Title';
import Alert from '../../../../components/Alert';
import Button from '../../../../components/Button';
import Card from '../../../../components/Card';
import Text from '../../../../components/Text';
// Import Hooks
import useAlert from '../../../../hooks/useAlert';
// Import Local Components
import AccountBalance from '../../components/AccountBalance';
import CreateRecipientAccountModal from '../../components/CreateRecipientAccountModal';
import AccountToAccountTransferForm from './components/AccountToAccountTransferForm';
// Import Component Styles
import { TitleContainer, TextContainer } from './styles';
import { HeadingWrapper } from '../../styles';

const AccountToAccountTransfer = ({ history, configs }) => {
  // Component states
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [sendingTransfer, setSendingTransfer] = useState(false);
  const [alert, setAlert] = useAlert();
  // Modal show states
  const [showCreateModal, setShowCreateModal] = useState(false);
  // data states
  const [accountBalance, setAccountBalance] = useState(null);
  const [recipientAccounts, setRecipientAccounts] = useState([]);

  // fetch data upon component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Get data for working with external accounts
        const [recipientAccountsData, accountBalanceData] = await Promise.all([
          get('/api/v1/cardholder_portal/cardholder/recipient_accounts'),
          get('/api/v1/cardholder_portal/cardholder/current_account/balance'),
        ]);

        setRecipientAccounts(recipientAccountsData.data);
        setAccountBalance(accountBalanceData.data);
      } catch (e) {
        setError({ type: 'danger', message: e });
      }

      setLoading(false);
    };

    fetchData();
  }, []);

  // Define page states
  if (error) return <ErrorComponent />;
  if (loading) return <Loader />;

  const page = 'account-to-account';

  // Create account to account transfer
  const createAccountToAccountTransfer = async params => {
    setSendingTransfer(true);
    try {
      // prepare payload
      const payload = {
        account_id: accountBalance.id,
        ...params,
      };

      // send transfer request
      await post(
        '/api/v1/cardholder_portal/cardholder/account_to_account_transfers',
        payload
      );

      // retrieve new account balance
      const accountBalanceData = await get(
        '/api/v1/cardholder_portal/cardholder/current_account/balance'
      );

      // set updated account balance after transfer
      setAccountBalance(accountBalanceData.data);

      // set success alert
      setAlert({
        type: 'success',
        message: accountToAccountEn[`${page}-transfer-created-alert`],
        messageFor: 'transfer-created',
      });

      // update loading state
      setSendingTransfer(false);
    } catch (e) {
      setAlert({
        type: 'danger',
        message: e.message,
      });
      setSendingTransfer(false);
    }
  };

  return (
    <>
      <HeadingWrapper>
        <Heading page={page} heading={accountToAccountEn[`${page}-heading`]} />
        {recipientAccounts.length > 0 ? (
          <AccountBalance
            balance={accountBalance.balance}
            currency={accountBalance.currency}
          />
        ) : (
          <Button
            buttonText={accountToAccountEn[`${page}-new-account-button`]}
            buttonFor="new-account"
            page={page}
            onClick={e => {
              e.preventDefault();
              setShowCreateModal(true);
            }}
            type="primary"
          />
        )}
      </HeadingWrapper>
      <Card>
        {recipientAccounts.length === 0 ? (
          <>
            {alert && (
              <Alert
                type={alert.type}
                alert={alert.message}
                alertFor={alert.messageFor}
                page={alert.page ? alert.page : page}
              />
            )}
            <Alert
              type="info"
              alert={accountToAccountEn[`${page}-add-recipient-alert`]}
              alertFor="add-recipient"
              page={page}
            />
          </>
        ) : (
          <>
            {alert && (
              <Alert
                type={alert.type}
                alert={alert.message}
                alertFor={alert.messageFor}
                page={page}
              />
            )}
            <TitleContainer>
              <Title
                title={accountToAccountEn[`${page}-form-title`]}
                titleFor="form"
                page={page}
              />
              {configs.allow_account_to_account_transfer && (
                <Button
                  buttonText={
                    accountToAccountEn[`${page}-manage-recipients-button`]
                  }
                  buttonFor="manage-recipients"
                  page={page}
                  onClick={e => {
                    e.preventDefault();
                    history.push('/transfers/card-to-card/accounts');
                  }}
                  type="secondary"
                />
              )}
            </TitleContainer>
            <TextContainer>
              <Text
                page={page}
                textFor="form"
                text={accountToAccountEn[`${page}-form-text`]}
              />
            </TextContainer>
            {sendingTransfer ? (
              <Loader />
            ) : (
              <AccountToAccountTransferForm
                setAlert={setAlert}
                balance={accountBalance.balance}
                disableSubmit={recipientAccounts.length === 0}
                createAccountToAccountTransfer={createAccountToAccountTransfer}
                recipientAccounts={recipientAccounts}
                toggleModal={setShowCreateModal}
              />
            )}
          </>
        )}
      </Card>
      {showCreateModal && (
        <CreateRecipientAccountModal
          toggleModal={setShowCreateModal}
          setSuccess={setAlert}
          recipientAccounts={recipientAccounts}
          setRecipientAccounts={setRecipientAccounts}
        />
      )}
    </>
  );
};

AccountToAccountTransfer.propTypes = {
  history: PropTypes.object.isRequired, // eslint-disable-line
  configs: PropTypes.object.isRequired, // eslint-disable-line
};

export default AccountToAccountTransfer;
