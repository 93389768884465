import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { ChangeLocale } from 'shared-components';
// Import Global Components
import ExternalLink from '../../../../components/ExternalLink';
import Link from '../../../../components/Link';
// Import Local Components
import Logout from '../../components/Logout';
// Import Component Styles
import { HeaderNav, LinksGroup, LinksContainer, NavMenu } from './styles';
// Import Links
import LINKS from '../../links';
// Import Translations
import { navEn } from '../../../../i18n/nav';

const HeaderNavWrapper = ({
  setLocale,
  locale,
  localization,
  open,
  setOpen,
  page,
}) => {
  // Create intl instance
  const intl = useIntl();

  const renderLinks = links =>
    links.map((link, idx) => (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions,react/no-array-index-key
      <li key={idx} onClick={() => setOpen(!open)}>
        {link.externalLink ? (
          <ExternalLink
            page={page}
            color="headerText"
            link={link.externalLink}
            linkText={navEn[`nav-${link.for}-link`]}
            linkFor={link.for}
          />
        ) : (
          <Link
            page={page}
            type="headerText"
            to={link.to}
            link={navEn[`nav-${link.for}-link`]}
            linkFor={link.for}
          />
        )}
      </li>
    ));

  return (
    <HeaderNav open={open}>
      <NavMenu>
        <LinksContainer>
          <LinksGroup>{renderLinks(LINKS)}</LinksGroup>
          <li>
            <Logout />
          </li>
        </LinksContainer>
        {localization && (
          <ChangeLocale
            setLocale={setLocale}
            locale={locale}
            messages={intl.messages}
          />
        )}
      </NavMenu>
    </HeaderNav>
  );
};

HeaderNavWrapper.propTypes = {
  setLocale: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  localization: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  page: PropTypes.string.isRequired,
};

export default HeaderNavWrapper;
