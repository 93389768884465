import styled from 'styled-components';

// Styles the body of the entire web page
export default styled.div`
  position: relative;
  min-height: 100vh;
  min-width: 100vw;
  max-width: 100%;
  background: #fff;
`;
