export const alertEn = {
  'invalid-username-format-error': 'Invalid username format',
  'invalid-password-format-error': 'Invalid password format',
  'invalid-cuurent-password-format-error': 'Invalid current password format',
  'invalid-password-confirmation-error':
    'Password and password confirmation does not match',
  'missing-username-error':
    'Please enter your username before submitting the form',
  'invalid-form-submit-error':
    'Form contains invalid or blank inputs, please correct them before submitting',
  'register-cardholder-failed-alert':
    'Failed to register Cardholder, please try again',
  'register-cardholder-success-alert':
    'Card succesfully registered, logging in...',
  'password-reset-success-alert':
    'Password has been reset. You may now login using your new password.',
  'login-failed-error': 'Failed to log in user',
  'exceeds-available-amount-alert':
    'Transfer amount exceeds available account balance.',
  'refresh-failed-error':
    'Failed to refresh the session. Please try again or wait to be logged out.',
  'pin-retrieve-error':
    'Error while retrieving pin. Please retry or contact your Account Manager if you continue to have issues.',
};

export const alertFr = {
  'invalid-username-format-error': 'Format du nom d\'utilisateur invalide',
  'invalid-password-format-error': ' Format du mot de passe invalide',
  'invalid-cuurent-password-format-error': 'Format de mot de passe actuel invalide',
  'invalid-password-confirmation-error':
    'Le mot de passe et la confirmation du mot de passe ne correspondent pas',
  'missing-username-error':
    'Veuillez passer votre nom d\'utilisateur avant de soumettre le formulaire',
  'invalid-form-submit-error':
    'Le formulaire contient des entrées non valables ou vides, veuillez les corriger avant de soumettre',
  'register-cardholder-failed-alert':
    'Le titulaire de la carte n\'a pas été enregistré, veuillez réessayer',
  'register-cardholder-success-alert':
    'Carte enregistrée avec succès, connexion...',
  'password-reset-success-alert':
    'Le mot de passe a été réinitialisé. Vous pouvez maintenant vous connecter en utilisant votre nouveau mot de passe',
  'login-failed-error': 'Échec de la connexion de l\'utilisateur',
  'exceeds-available-amount-alert':
    'Le montant du transfert dépasse le solde disponible du compte.',
  'refresh-failed-error':
    'Échec de l\'actualisation de la session. Veuillez réessayer ou attendre d\'être déconnecté.',
  'pin-retrieve-error':
    'Erreur lors de la récupération du code PIN. Veuillez réessayer ou contacter votre gestionnaire de compte si vous continuez à rencontrer des problèmes.',
};
