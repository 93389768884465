export const personToPersonEn = {
  'person-to-person-heading': 'Send Money',
  'person-to-person-form-title': 'Person to Person',
  'person-to-person-transfer-created-alert':
    'Transfer was successful and an email has been sent to the recipient.',
  'person-to-person-accounts-heading': 'Person to Person Accounts',
  'person-to-person-list-title': 'List of Person to Person Accounts',
  'person-to-person-account-created-alert':
    'Person to person account was created successfully.',
  'person-to-person-create-heading': 'New Person to Person Account',
  'person-to-person-success-delete-alert':
    'Your person to person account was deleted successfully.',
  'person-to-person-delete-form-text':
    'Are you sure you want to delete <b>{name}</b> from the list of person to person recipents?',
  'person-to-person-delete-heading': 'Delete Person to Person Account',
  'person-to-person-update-success-alert':
    'Your person to person account was updated successfully.',
  'person-to-person-update-heading': 'Update Person to Person Account',
  'person-to-person-add-new-button': 'Add New Account',
  'person-to-person-manage-accounts-button': 'Manage Accounts',
  'person-to-person-total-fee-text':
    // eslint-disable-next-line no-template-curly-in-string
    'There is a ${feeAmount} fee for this transfer.',
};

export const personToPersonFr = {
  'person-to-person-heading': 'Envoyer de l\'argent',
  'person-to-person-form-title': 'Personne à personne',
  'person-to-person-transfer-created-alert':
    'Le transfert a réussi et un e-mail a été envoyé au destinataire.',
  'person-to-person-accounts-heading': 'Comptes de personne à personne',
  'person-to-person-list-title': 'Liste des comptes de personne à personne',
  'person-to-person-account-created-alert':
    'Votre compte du personne à personne a été créé avec succès.',
  'person-to-person-create-heading': 'Nouveau compte du personne à personne',
  'person-to-person-success-delete-alert':
    'Votre compte de personne à personne a été effacer avec succès.',
  'person-to-person-delete-form-text':
    'Voulez-vous vraiment supprimer <b> {name} </b> de la liste des comptes du personne à personne?',
  'person-to-person-delete-heading': 'Effacer compte du personne à personne',
  'person-to-person-update-success-alert':
    'Votre compte de personne à personne a été mis à jour avec succès.',
  'person-to-person-update-heading': 'Mis à jour compte du personne à personne',
  'person-to-person-add-new-button': 'Ajouter un nouveau compte',
  'person-to-person-manage-accounts-button': 'Gérer les comptes',
  'person-to-person-total-fee-text':
    'Il y a des frais de {feeAmount} $ pour ce transfert.',
};
