import React from 'react';
import PropTypes from 'prop-types';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
// Import Component Styles
import { TooltipContent } from './styles';

const InfoTooltip = ({ content, position }) => {
  return (
    <Tooltip
      theme="light"
      position={position}
      style={{
        height: '16px',
        color: '#00000099',
        cursor: 'pointer'
      }}
      html={(
        <TooltipContent>
          {content}
        </TooltipContent>
      )}
      arrow="true"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-info"
      >
        <circle cx="12" cy="12" r="10" />
        <line x1="12" y1="16" x2="12" y2="12" />
        <line x1="12" y1="8" x2="12" y2="8" />
      </svg>
    </Tooltip>
  );
};

InfoTooltip.propTypes = {
  content: PropTypes.node.isRequired,
  position: PropTypes.oneOf(['top', 'bottom', 'left', 'right'])
};

InfoTooltip.defaultProps = {
  position: 'top'
};

export default InfoTooltip;
