import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
// Import Shared Components
import { Table, Loader, ErrorComponent } from 'shared-components';
import {
  getFormattedTransferDate,
  getFormattedTransferType,
  getFormattedStatus,
} from 'shared-components/src/utils/cardholders';
// Import Shared Utils
import { get } from 'shared-components/src/utils/http';
import { formatCurrencyV } from 'shared-components/src/utils/formatCurrency';
import { centsToDollars } from 'shared-components/src/utils/currency';
// Import Global Components
import Alert from '../../../../../../../../components/Alert/index';
import CancelButton from '../../../../../../../../components/CancelButton';

const cancellableStatuses = ['awaiting_settlement', 'processing'];

const TransfersTable = ({
  startDate,
  endDate,
  handleCsvData,
  selectedStatus,
  setShowCancelModal,
  setCancelParams,
}) => {
  const intl = useIntl();

  // Set component states
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  // Set data states
  const [transfers, setTransfers] = useState([]);

  // Call to get account balance
  useEffect(() => {
    const fetchData = async () => {
      setError(false);

      try {
        setLoading(true);
        // Create URL query string
        const query = new URLSearchParams({
          start_date: startDate,
          end_date: endDate,
          status: selectedStatus,
          limit: '9999',
          transfer_type: 'direct_send_interac_pull',
        }).toString();

        const transfersData = await get(
          `/api/v1/cardholder_portal/cardholder/transfers?${query}`
        );

        setTransfers(transfersData.data);

        handleCsvData([...transfersData.data]);
      } catch (e) {
        setError(true);
      }
      setLoading(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, selectedStatus]);

  // Define page states
  if (error) return <ErrorComponent />;
  if (loading) return <Loader />;

  const page = 'person-to-external';
  const columns = [
    {
      width: 125,
      Header: () => (
        <FormattedMessage
          id="tfr-date-table-header"
          description="Table Header t date"
          defaultMessage={intl.messages[`${page}-tfr-date-table-header`]}
        />
      ),
      accessor: 'created_at',
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => getFormattedTransferDate(value),
    },
    {
      width: 200,
      Header: () => (
        <FormattedMessage
          id="tfr-description-table-header"
          description="Table Header tfr description"
          defaultMessage={intl.messages[`${page}-tfr-description-table-header`]}
        />
      ),
      accessor: 'transfer_type',
      Cell: ({ value }) => getFormattedTransferType(value),
    },
    {
      width: 125,
      Header: () => (
        <FormattedMessage
          id="tfr-status-table-header"
          description="Table Header tfr status info"
          defaultMessage={intl.messages[`${page}-tfr-status-table-header`]}
        />
      ),
      accessor: 'status',
      Cell: ({ value }) => getFormattedStatus(value),
    },
    {
      width: 125,
      Header: () => (
        <FormattedMessage
          id="tfr-information-name-table-header"
          description="Table Header txn status info"
          defaultMessage={
            intl.messages[`${page}-tfr-information-name-table-header`]
          }
        />
      ),
      accessor: 'name',
    },
    {
      width: 250,
      Header: () => (
        <FormattedMessage
          id="tfr-information-email-table-header"
          description="Table Header txn status info"
          defaultMessage={
            intl.messages[`${page}-tfr-information-email-table-header`]
          }
        />
      ),
      accessor: 'email',
    },
    {
      width: 100,
      Header: () => (
        <FormattedMessage
          id="tfr-amount-table-header"
          description="Table Header txn amount"
          defaultMessage={intl.messages[`${page}-tfr-amount-table-header`]}
        />
      ),
      accessor: 'amount',
      Cell: cell => {
        return (
          <div
            style={{
              width: '100%',
              textAlign: 'right',
            }}
          >
            {formatCurrencyV(centsToDollars(cell.original.amount))}
          </div>
        );
      },
    },
    {
      width: 125,
      Header: () => (
        <FormattedMessage
          id="tfr-cancel-table-heading"
          description="Table Header cancel transfer"
          defaultMessage={intl.messages[`${page}-tfr-cancel-table-heading`]}
        />
      ),
      Cell: cell =>
        cancellableStatuses.includes(cell.original.status) && (
          <CancelButton
            onClick={e => {
              e.preventDefault();
              setShowCancelModal(true);
              setCancelParams({ id: cell.original.direct_send_reference });
            }}
          />
        ),
    },
  ];

  return (
    <>
      {transfers.length === 0 ? (
        <Alert
          type="info"
          alert={intl.messages['home-table-alert']}
          alertFor="table"
          page="home"
        />
      ) : (
        <Table data={[...transfers]} columns={columns} showPagination />
      )}
    </>
  );
};

TransfersTable.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  handleCsvData: PropTypes.func.isRequired,
  selectedStatus: PropTypes.string.isRequired,
  setShowCancelModal: PropTypes.func.isRequired,
  setCancelParams: PropTypes.func.isRequired,
};
export default TransfersTable;
