import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
// Import Shared Components
import { Loader } from 'shared-components';
// Import Shared Utils
import { post } from 'shared-components/src/utils/http';
// Import Global Components
import Button from '../../../components/Button';
// Import Common Utils
import { getInitialStatus, getNewStatus } from '../../../utils/cardStatus';

const ModifyStatusButton = ({
  lastFourDigits,
  accountId,
  cardStatus,
  setAlert,
}) => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(getInitialStatus(cardStatus));

  // Create intl instance
  const intl = useIntl();

  useEffect(() => {
    setStatus(getInitialStatus(cardStatus));
  }, [cardStatus]);

  // Update card status to either suspended or unsuspended.
  const handleUpdateCardStatus = async status => {
    try {
      setLoading(true);

      const body = {
        status,
        last_four_digits: lastFourDigits,
      };

      // Send request to modify card status
      await post(
        `/api/v1/cardholder_portal/cardholder/accounts/${accountId}/modify_status`,
        body
      );

      setAlert({ for: `${status}-card-success`, type: 'success' });
      setStatus(getNewStatus(status));
    } catch (err) {
      setAlert({ for: `${status}-card-error`, type: 'danger' });
    }
    setLoading(false);
  };

  // If cardStatus is closed, we should disabled the ability to modify card status
  const isClosed = cardStatus === 'closed';

  if (loading) return <Loader />;

  return (
    <Button
      page="home"
      buttonFor={status}
      type="secondary"
      size="large"
      buttonText={intl.messages[`home-${status}-button`]}
      onClick={() => handleUpdateCardStatus(status)}
      disabled={isClosed}
      title={
        isClosed &&
        'The card on this account is closed. This action is not available'
      }
    />
  );
};

ModifyStatusButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  lastFourDigits: PropTypes.string.isRequired,
  accountId: PropTypes.number.isRequired,
  setAlert: PropTypes.func.isRequired,
  cardStatus: PropTypes.string.isRequired,
};

export default ModifyStatusButton;
