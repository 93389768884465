export const requestFundsComponentName = 'request-funds';
export const transfersComponentName = 'transfers';

export const updateFeeAmounts = data => {
  const successFee = data.interac_pull_success;
  const cancellationFee = data.interac_pull_cancellation;

  const update = {};
  if (typeof successFee === 'number' && successFee > 0) {
    update.create = (Number(successFee) / 100).toFixed(2);
  }

  if (typeof cancellationFee === 'number' && cancellationFee > 0) {
    update.cancel = (Number(cancellationFee) / 100).toFixed(2);
  }

  return update;
};
