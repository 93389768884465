import React from 'react';
// Import Component Styles
import auth from 'shared-components/src/utils/auth';
import { StyledButton } from './styles';
// Import Utils

export default () => {
  // Handle logging user out of application
  const handleLogout = () => {
    // eslint-disable-next-line no-return-assign
    auth.logout().then(() => (window.location.pathname = '/'));
  };

  return (
    <StyledButton
      variant="text"
      onClick={handleLogout}
      page="nav"
      buttonFor="logout"
      buttonText="Logout"
    />
  );
};
