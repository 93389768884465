import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
// Import Utils
import auth from 'shared-components/src/utils/auth';

const RouteWrapper = ({ priv, ...props }) => {
  const isAuthenticated = !!auth.isAuthenticated();
  // Checks for Authentication
  if (priv && !isAuthenticated) return <Redirect to="/login" />;
  if (!priv && isAuthenticated) return <Redirect to="/" />;
  return <Route {...props} />;
};

RouteWrapper.propTypes = {
  priv: PropTypes.bool
};

RouteWrapper.defaultProps = {
  priv: false
};

export default RouteWrapper;
