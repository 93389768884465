// Translations File

export const aboutEn = {
  'about-heading': 'About Your Prepaid Card',
  'about-intro-text':
    'This page provides important information about your card. Please read it carefully before using the card. If you have questions, please call the number on the back of your card. To view or download your cardholder agreement, please click <cardholder>here</cardholder>.',
  'about-h1-title': 'Is a prepaid card a credit card?',
  'about-p1-text':
    'No. The card is a prepaid card that carries funds loaded on the card by the card sponsor (the organization that pays for the funds on the card). Each time you use the card, the purchase amount is deducted from the available balance.',
  'about-h2-title': 'Where can a prepaid card be used?',
  'about-p2-text':
    'Your card can be used anywhere Visa or Mastercard is accepted, including over the phone and online.',
  'about-h3-title': 'Can I withdraw cash from an ABM using my card?',
  'about-p3-text':
    'Check the card package you received to find out if cash withdrawals are permitted.',
  'about-h4-title': 'Do I have a PIN assigned?',
  'about-p4-text':
    'If cash withdrawals are permitted, you will find your PIN either printed on the card package you received, or under the Profile section of this website.',
  'about-h5-title': 'What is my Access Code and what do I use it for?',
  'about-p5-text':
    'Your Access Code is used to activate your card and to check your balance. You will find it in the card package your received.',
  'about-h6-title':
    'How do I know the initial balance of prepaid card, and how can I check my balance?',
  'about-p6-text':
    'Look for communications from the sponsor company that outlines the initial funds loaded on the card. You have several ways to determine your balance. ',
  'about-li1-text':
    'You can keep a manual running total of your balance by subtracting your purchases from the original loaded value.',
  'about-li2-text':
    'You can visit the website on the back of your card to obtain your balance and a history of your transactions.',
  'about-li3-text':
    'You can call the number on the back of your card and follow the automated prompts. Fees may apply.',
  'about-li4-text':
    'You can speak with a live agent by calling the number shown on the back of your card. Fees may apply.',
  'about-h7-title': 'Can I use my prepaid card at gas stations?',
  'about-p7-text':
    'Yes. Just remember to present the card to the station attendant rather than paying at the pump since automated fuel dispensers are sometimes unable to pre-authorize prepaid cards.',
  'about-h8-title': 'Can I use my prepaid card at restaurants and hotels?',
  'about-p8-text':
    'Yes. However, you should be aware that restaurants and hotels factor in an automatic tip or other incidental expenses when authorizing your purchase transaction. If the total purchase amount including the estimated tip exceeds the amount available on your card, your card may be declined. If you think this will happen, inform the merchant in advance about how much to deduct from your prepaid card and which second payment method (credit card, debit card, cash or cheque) you want to use to cover the difference.',
  'about-h9-title': 'Can I use my prepaid card for online or phone purchases?',
  'about-p9-text':
    'Yes. Your card is intended for use anywhere Visa or Mastercard cards are accepted. You should be aware that some internet merchants use fraud protection software that may decline a prepaid card for an online or phone purchase transaction.',
  'about-h10-title': 'Do I need to sign an agreement?',
  'about-p10-text':
    'No. Each card is distributed with a schedule of fees and summary Cardholder Agreement. You accept the terms and conditions of the Cardholder Agreement by signing the back of the card, using the card, or receiving the card by request.',
  'about-h11-title': 'Will I receive a statement?',
  'about-p11-text':
    'No. You can review your current balance, free of charge, by visiting the website provided on the back of the card. In addition, you can call the Cardholder Services number listed on the back of the card to receive balance and transaction information. Fees may apply. Refer to the Cardholder Agreement for details.',
  'about-h12-title':
    'What should I do with my prepaid card when the value has been spent?',
  'about-p12-text':
    'The sponsor company may reload funds onto your card as per the description included in the package you received. If there are no additional funds to be loaded onto your card, keep your card in case you must return any purchases made with the card. The merchant will ask you to present the card when requesting a refund. If your card has expired, the merchant\'s return policy will determine how the return will be handled.',
  'about-h13-title': 'What happens if I need to return an item?',
  'about-p13-text':
    'Each merchant location has its own return policies and will handle returns in the same manner as any other Visa or Mastercard transaction. They may credit your prepaid card, provide a cash refund, or provide a store credit.',
  'about-h14-title': 'Can I reload my prepaid card?',
  'about-p14-text': 'No, only the card sponsor can load funds onto the card.',
  'about-h15-title': 'Does a prepaid card have an expiration date?',
  'about-p15-text':
    'Yes. It expires on the date printed on the front of the card.',
  'about-h16-title':
    'What happens if there are still funds available when my prepaid card expires?',
  'about-p16-text':
    'We encourage you to use the entire amount of funds before the expiration date. Upon expiry, funds will no longer be accessible. Expiration policies may vary based on program, please check the card package you received for more details about card expiration.',
  'about-h17-title':
    'Can the prepaid card be cancelled prior to the expiry date on the card?',
  'about-p17-text':
    'Yes. At the discretion of the card sponsor, cards can be cancelled prior to the expiry date. You will be provided with a minimum of 60 days notice to allow you to use the balance of funds on your cards.',
  'about-h18-title': 'What happens if my prepaid card is lost or stolen?',
  'about-p18-text':
    'You must notify us immediately by calling the number on the back of the card and providing your card number and other information we request. Once you have notified us, your card may be replaced at your written request, subject to our verification of your request and a replacement fee. You are responsible for any transactions that may occur prior to the card being reported. You are also responsible for all transactions where your PIN has been used.',
  'about-h19-title': 'Why would my prepaid card be declined?',
  'about-li5-text':
    'Your card may have expired. Check the expiry date printed on the front of your card.',
  'about-li6-text':
    'Your remaining card balance may be less than the value of the item you wish to purchase. You may inform the merchant of the remaining balance on the card and use another form of payment to pay the difference, subject to the policy of the merchant.',
  'about-li7-text':
    'Some internet merchants use fraud protection software that may decline a prepaid card purchase transaction. Updating your profile information on this site can help you avoid declines.',
  'about-li8-text':
    'You purchased gas at the pump. Remember to present the card to the station attendant rather than paying at the pump since automated fuel dispensers are sometimes unable to pre-authorize prepaid cards.',
  'about-li9-text':
    'Some merchants (like restaurants) obtain pre-authorizations of up to 20% more than the final transaction amount in case the cardholder leaves a tip. If this amount exceeds the card balance, the transaction will be declined.',
  'about-h20-title': 'What fees are associated with a prepaid card?',
  'about-p20-text':
    'All fees related to your card are provided to you in the Cardholder Agreement contained in the card package you received. We encourage you to read the fees associated with the card and to establish usage patterns that minimize fees associated with card ownership.',
  'about-h21-title': 'Can the card ever have a negative balance?',
  'about-p21-text':
    'No, the card is a prepaid card which will only authorize when enough funds exist on the card to cover the purchase.',
  'about-h22-title':
    'Are the funds on this card insured by the Canadian Deposit Insurance Corporation (CDIC)?',
  'about-p22-text': 'No, the funds on this card are not insured by the CDIC.',
};

export const aboutFr = {
  'about-heading': 'À Propos de votre carte prépayée',
  'about-intro-text':
    'Cette page contient des renseignements importants sur la carte et ses conditions d’utilisation. Veuillez les lire attentivement avant d’utiliser la carte. Pour toute question, veuillez composer le numéro de téléphone figurant au verso de votre carte. Pour consulter ou télécharger votre contrat de titulaire de carte, veuillez cliquer <cardholder>ici</cardholder>.',
  'about-h1-title': 'Une carte prépayée est-elle une carte de crédit?',
  'about-p1-text':
    'Non. Cette carte est une carte prépayée dont les fonds sont approvisionnés par l’organisme commanditaire de la carte (l’organisme qui finance les fonds de la carte). Chaque fois que vous utilisez la carte, le montant de l’achat est déduit du solde disponible.',
  'about-h2-title': 'Où puis-je utiliser cette carte prépayée?',
  'about-p2-text':
    'Votre carte peut être utilisée partout où Visa ou Mastercard est acceptée, y compris pour les achats en ligne et par téléphone.',
  'about-h3-title': 'Puis-je retirer de l’argent au GAB avec cette carte?',
  'about-p3-text':
    'Consultez la trousse qui vous a été remise avec votre carte pour vérifier s’il est permis de retirer de l’argent au guichet automatique.',
  'about-h4-title': 'Ai-je un NIP assigné?',
  'about-p4-text':
    'Si le retrait d’argent au guichet automatique est permis, vous trouverez alors votre NIP dans la trousse qui vous a été remise avec votre carte ou sous la section « Mon profil » de ce site web.',
  'about-h5-title': 'Qu’est-ce qu’un code d’accès et à quoi sert-il?',
  'about-p5-text':
    'Votre code d’accès vous permet d’activer votre carte et de consulter votre solde. Vous le trouverez dans la trousse qui vous a été remise avec votre carte.',
  'about-h6-title':
    'Comment puis-je connaitre le solde initial de la carte prépayée et comment puis-je consulter mon solde?',
  'about-p6-text':
    'L’organisme commanditaire vous informera du montant initial versé sur la carte. Par la suite, il existe plusieurs façons de vérifier votre solde : ',
  'about-li1-text':
    'Vous pouvez prendre en note tous les montants de vos achats et les soustraire manuellement du montant original chargé sur la carte.',
  'about-li2-text':
    'Vous pouvez visiter le site web figurant au verso de votre carte pour obtenir le solde et l’historique des transactions.',
  'about-li3-text':
    'Vous pouvez composer le numéro de téléphone qui apparaît au verso de votre carte et suivre les instructions automatisées. Des frais peuvent s’appliquer.',
  'about-li4-text':
    'Vous pouvez composer le numéro de téléphone qui apparaît au verso de votre carte et parler à un conseiller. Des frais peuvent s’appliquer.',
  'about-h7-title':
    'Puis-je utiliser ma carte prépayée aux stations d’essence?',
  'about-p7-text':
    'Oui. Toutefois, il faut présenter la carte au caissier et ne pas payer à la pompe. En effet, certains distributeurs automatiques d’essence ne peuvent pas préautoriser les cartes prépayées.',
  'about-h8-title':
    'Puis-je utiliser ma carte prépayée aux restaurants et aux hôtels?',
  'about-p8-text':
    'Oui. Toutefois, certains restaurants et hôtels incluent automatiquement un pourboire ou autres frais accessoires lorsqu’ils autorisent votre transaction. Si le montant total de l’achat incluant le pourboire dépasse le montant disponible sur votre carte, la transaction peut être refusée. Si vous pensez que ce pourrait être le cas, prévenez le commerçant à l’avance du montant exact à déduire de votre carte prépayée et avisez-le de la seconde méthode de paiement (carte de crédit, carte de débit, argent comptant ou chèque) que vous utiliserez pour acquitter la différence.',
  'about-h9-title':
    'Puis-je utiliser la carte prépayée pour des achats en ligne ou par téléphone?',
  'about-p9-text':
    'Oui. Votre carte peut être utilisée partout où Visa ou Mastercard est accepté. Veuillez noter que certains commerçants en ligne utilisent des logiciels de protection contre la fraude pouvant refuser les transactions en ligne et par téléphone effectuées au moyen d’une carte prépayée.',
  'about-h10-title': 'Dois-je signer une entente?',
  'about-p10-text':
    'Non. Chaque carte est distribuée avec un barème des frais et un résumé de l’entente avec les titulaires de carte. Vous acceptez les termes et les conditions énoncés dans l’entente lorsque vous signez l’endos de votre carte, utilisez la carte ou demandez à obtenir une carte.',
  'about-h11-title': 'Vais-je recevoir un état de compte?',
  'about-p11-text':
    'Non. Vous pouvez consulter votre solde courant, sans frais, en visitant le site web figurant au verso de votre carte. Vous pouvez également joindre le numéro du service à la clientèle qui apparaît au verso de la carte pour obtenir de l’information sur votre solde et vos transactions. Des frais peuvent s’appliquer. Consultez l’entente avec le titulaire de la carte pour plus de détails.',
  'about-h12-title': 'Que devrais-je faire lorsque le solde est épuisé?',
  'about-p12-text':
    'L’organisme commanditaire pourrait recharger des fonds sur votre carte, si cette option est incluse dans la trousse qui vous est remise avec la carte et prévue par l’organisme. Si aucun approvisionnement additionnel n’est prévu sur votre carte, conservez-la au cas où vous auriez à retourner des achats faits avec votre carte. Le commerçant vous demandera de présenter la carte lorsque vous ferez un retour. Si la carte est expirée, la politique de retour du commerçant déterminera le processus du retour.',
  'about-h13-title': 'Que devrais-je faire si je souhaite retourner un achat?',
  'about-p13-text':
    'Chaque commerçant possède sa politique de retour et traite les retours de la même manière que toute autre transaction effectuée avec une carte Visa ou Mastercard. Il pourrait créditer votre carte prépayée, vous faire un retour en argent comptant ou vous donner un crédit en magasin.',
  'about-h14-title': 'Puis-je recharger ma carte prépayée?',
  'about-p14-text':
    'Non, seulement l’organisme commanditaire peut recharger des fonds sur la carte.',
  'about-h15-title': 'Une carte prépayée expire-t-elle?',
  'about-p15-text':
    'Oui. La carte expire à la date indiquée sur le recto de la carte.',
  'about-h16-title':
    'Que se passe-t-il s’il y a des fonds disponibles suite à l’expiration de la carte?',
  'about-p16-text':
    'Nous vous conseillons d’utiliser le solde disponible avant l’expiration de la carte. Après ce délai, les fonds ne seront plus disponibles. La politique d’expiration peut varier selon le programme, veuillez vérifier le contenu de la trousse qui vous a été remise avec la carte pour obtenir plus de renseignements sur l’expiration de la carte.',
  'about-h17-title':
    'La carte prépayée peut-elle être annulée avant l’expiration de la carte?',
  'about-p17-text':
    'Oui. À la discrétion de l’organisme commanditaire, les cartes peuvent être annulées avant la date d’expiration. Vous recevrez un préavis de 60 jours minimum pour vous permettre d’utiliser le solde restant.',
  'about-h18-title': 'Que devrais-je faire si ma carte est perdue ou volée?',
  'about-p18-text':
    'Vous devez nous en informer immédiatement en composant le numéro de téléphone figurant au verso de votre carte et en nous fournissant votre numéro de carte ainsi l’information requise. Lorsque nous en serons avisés, vous pourrez faire une demande écrite pour que votre carte soit remplacée, sous réserve de notre vérification de votre demande et des frais de remplacement. Vous êtes responsable de toutes transactions effectuées avant que la carte n’ait été déclarée perdue ou volée. Vous êtes également responsable de toute transaction où votre NIP a été utilisé.',
  'about-h19-title': 'Pour quelles raisons ma carte peut être refusée?',
  'about-li5-text':
    'Il est possible que votre carte soit expirée. Vérifiez la date d’expiration imprimée au recto de la carte.',
  'about-li6-text':
    'Votre solde disponible est inférieur au montant de votre achat. Si le commerçant le permet, vous pouvez utiliser le solde restant sur la carte afin d’effectuer un paiement partiel et choisir un deuxième mode de paiement pour payer la différence.',
  'about-li7-text':
    'Certains commerçants en ligne utilisent des logiciels de protection contre la fraude pouvant refuser les transactions effectuées au moyen de cartes prépayées. En tenant à jour votre profil sur le présent site web, vous pourrez toutefois éviter la plupart des refus.',
  'about-li8-text':
    'Vous avez acheté de l’essence et tenté de payer à la pompe. Vous devez présenter votre carte au caissier. Veuillez noter que certains distributeurs automatiques d’essence ne peuvent préautoriser les cartes prépayées.',
  'about-li9-text':
    'Certains commerçants (tels que les restaurants) ajoutent à la préautorisation jusqu\'à 20 % de plus que la transaction finale dans le but de couvrir tout pourboire que vous pourriez laisser. Si ce montant est supérieur au solde de la carte, la transaction sera refusée.',
  'about-h20-title': 'Quels sont les frais associés à une carte prépayée?',
  'about-p20-text':
    'L’entente avec le titulaire de la carte détaille tous les frais associés à l’utilisation de votre carte. Nous vous encourageons à prendre connaissance de ces frais et à gérer votre utilisation de celle-ci de manière à minimiser les frais qui vous seront chargés.',
  'about-h21-title': 'La carte peut-elle avoir un solde négatif?',
  'about-p21-text':
    'Non, la carte est une carte prépayée qui ne sera acceptée que s’il y a suffisamment de fonds sur la carte pour couvrir le montant de l’achat.',
  'about-h22-title':
    'Les fonds sur cette carte sont-ils assurés par la Société d’assurance-dépôts du Canada (SADC)?',
  'about-p22-text':
    'Non, les fonds sur cette carte ne sont pas assurés par la SADC.',
};
