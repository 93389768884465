import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
// Import Shared Components
import { ErrorComponent } from 'shared-components';
// Import Global Hook
import { useFeeCalculation } from '../../hooks/useFeeCalculation';
// Import Local Styled Components
import { TooltipWrapper } from './styles';

const TransferFeeTooltip = ({ transferAmount, feeType, page }) => {
  const { feeAmount, error } = useFeeCalculation(transferAmount, feeType);
  const FeeString = () => (
    <p>
      <FormattedMessage
        id={`${page}-total-fee-text`}
        description="Text for the total amount of fee applied for a transfer"
        // eslint-disable-next-line no-template-curly-in-string
        defaultMessage={'There is a ${feeAmount} fee for this transfer.'}
        values={{ feeAmount }}
      />
    </p>
  );

  if (error) return <ErrorComponent />;
  return (
    <>
      {feeAmount !== 0 && (
        <TooltipWrapper>
          <FeeString />
        </TooltipWrapper>
      )}
    </>
  );
};

TransferFeeTooltip.propTypes = {
  transferAmount: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
  feeType: PropTypes.oneOf([
    'account_to_account',
    'external_recipient',
    'external_bank',
    'loading',
  ]).isRequired,
};

export default TransferFeeTooltip;
