// Note: Commented out for ticket BP-4410
// See below for more info
// import { KNOWLEDGE_PORTAL_LINK } from '../../utils/externalLink';

export default [
  {
    to: '/',
    for: 'dashboard'
  },
  {
    to: '/transfers',
    for: 'transfers'
  },
  {
    to: '/profile',
    for: 'profile'
  },
  {
    to: '/about',
    for: 'about'
  },
  // Commented out while knowledge base page is updating for U.S clients
  // See ticket BP-4410
  // {
  //   externalLink: KNOWLEDGE_PORTAL_LINK,
  //   for: 'knowledge-base'
  // }
];
