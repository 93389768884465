import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';

const RouteWrapper = ({ enabled, ...props }) => {
  // Checks if transfer type is enabled for program
  if (!enabled) return <Redirect to="/transfers" />;

  return <Route {...props} />;
};

RouteWrapper.propTypes = {
  enabled: PropTypes.bool
};

RouteWrapper.defaultProps = {
  enabled: false
};

export default RouteWrapper;
