export const navEn = {
  'nav-dashboard-link': 'Dashboard',
  'nav-transfers-link': 'Transfers',
  'nav-profile-link': 'My Profile',
  'nav-about-link': 'About My Card',
  'nav-knowledge-base-link': 'Knowledge Base',
  'nav-logout-button': 'Logout'
};

export const navFr = {
  'nav-dashboard-link': 'Mon Compte',
  'nav-transfers-link': 'Transferts',
  'nav-profile-link': 'Mon Profil',
  'nav-about-link': 'À Propos de ma carte',
  'nav-knowledge-base-link': 'Base de Connaissances',
  'nav-logout-button': 'Fermer ma session'
};
