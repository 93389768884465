import React, { useState, useEffect, useContext } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { ThemeContext } from 'styled-components';
// Import Shared Components
import {
  Loader,
  ErrorComponent,
  Table,
  ButtonGroup,
  IconButton,
} from 'shared-components';
// Import Shared Utils
import { get, deleteHTTP } from 'shared-components/src/utils/http';
// Import Global Components
import Card from '../../../../components/Card';
import Heading from '../../../../components/Heading';
import Button from '../../../../components/Button';
import Alert from '../../../../components/Alert';
import ConfirmDeleteModal from '../../components/ConfirmDeleteModal';
// Import Hooks
import useAlert from '../../../../hooks/useAlert';
// Import Local Components
import CreatePersonToPersonAccountModal from './components/CreatePersonToPersonAccountModal';
import UpdatePersonToPersonAccountModal from './components/UpdatePersonToPersonAccountModal';
// Import Component Styles
import { Title } from './styles';
import { HeadingWrapper } from '../../styles';

const PersonToPersonAccounts = () => {
  const intl = useIntl();

  // Component states
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useAlert();
  // data states
  const [personToPersonAccounts, setPersonToPersonAccounts] = useState([]);
  const [
    updatePersonToPersonAccount,
    setUpdatePersonToPersonAccount,
  ] = useState(null);
  // Modal States
  const [showCreateModal, setShowCreateModal] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(null);
  const [deleting, setDeleting] = useState(false);

  // Extract theme from Theme Provider
  const theme = useContext(ThemeContext);

  const page = 'person-to-person';

  // fetch data upon component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Get data for working with external accounts
        const res = await get(
          '/api/v1/cardholder_portal/cardholder/external_accounts?type=person_to_person'
        );

        setPersonToPersonAccounts(res.data);
      } catch (e) {
        setError({ type: 'danger', message: e });
      }

      setLoading(false);
    };

    fetchData();
  }, []);

  const deletePersonToPerson = async id => {
    setDeleting(true);
    try {
      await deleteHTTP(
        `/api/v1/cardholder_portal/cardholder/external_accounts/${id}`
      );
      // Remove the deleted recipient from page state
      setPersonToPersonAccounts(prev =>
        prev.filter(account => parseInt(account.id, 10) !== parseInt(id, 10))
      );

      setAlert({
        type: 'success',
        message: intl.messages[`${page}-success-delete-alert`],
        messageFor: 'success-delete',
      });

      setShowDeleteModal(false);
      setUpdatePersonToPersonAccount(null);
    } catch (e) {
      setAlert({
        type: 'danger',
        message: e.message,
        messageFor: 'delete-account-failure',
      });
    }
    setDeleting(false);
  };
  const closeConfirmDeleteModal = e => {
    e.preventDefault();
    setShowDeleteModal(false);
    setAlert(false);
  };
  // Define page states
  if (error) return <ErrorComponent />;
  if (loading) return <Loader />;

  const columns = [
    {
      Header: () => (
        <FormattedMessage
          id="id-table-heading"
          description="Table Header id"
          defaultMessage={intl.messages['id-table-heading']}
        />
      ),
      accessor: 'id',
      maxWidth: 150,
    },
    {
      Header: () => (
        <FormattedMessage
          id="name-table-heading"
          description="Table Header name"
          defaultMessage={intl.messages['name-table-heading']}
        />
      ),
      accessor: 'name',
    },
    {
      Header: () => (
        <FormattedMessage
          id="email-table-heading"
          description="Table Header email"
          defaultMessage={intl.messages['email-table-heading']}
        />
      ),
      accessor: 'email',
    },
    {
      Header: () => (
        <FormattedMessage
          id="create-at-table-heading"
          description="Table Header create at"
          defaultMessage={intl.messages['create-at-table-heading']}
        />
      ),
      accessor: 'created_at',
      Cell: ({ value }) => <FormattedDate value={value} />, //eslint-disable-line
    },
    {
      Header: () => (
        <FormattedMessage
          id="actions-table-heading"
          description="Table Header actions"
          defaultMessage={intl.messages['actions-table-heading']}
        />
      ),
      accessor: 'action',
      innerFocus: true,
      Cell: cellInfo => (
        <ButtonGroup justifyContent="flex-start">
          <IconButton
            iconType="edit"
            onClick={e => {
              e.preventDefault();
              setAlert(null);
              setUpdatePersonToPersonAccount(cellInfo.original);
              setShowUpdateModal(true);
            }}
            color={theme.colors.lightBlue}
          />
          <IconButton
            iconType="trash"
            onClick={e => {
              e.preventDefault();
              setAlert(null);
              setUpdatePersonToPersonAccount(cellInfo.original);
              setShowDeleteModal(true);
            }}
            color={theme.colors.danger}
          />
        </ButtonGroup>
      ),
    },
  ];

  return (
    <>
      <HeadingWrapper>
        <Heading
          page={page}
          heading={intl.messages[`${page}-accounts-heading`]}
        />
        <Button
          buttonText={intl.messages[`${page}-add-new-button`]}
          buttonFor="add-new"
          page={page}
          onClick={e => {
            e.preventDefault();
            setAlert(null);
            setShowCreateModal(true);
          }}
          type="primary"
        />
      </HeadingWrapper>
      <Card>
        <>
          {alert && (
            <Alert
              type={alert.type}
              alert={alert.message}
              alertFor={alert.messageFor}
              page={alert.page ? alert.page : page}
            />
          )}
          <Title
            title={intl.messages[`${page}-list-title`]}
            titleFor="list"
            page={page}
          />
          <Table data={personToPersonAccounts} columns={columns} />
        </>
      </Card>
      {showCreateModal && (
        <CreatePersonToPersonAccountModal
          toggleModal={setShowCreateModal}
          setSuccess={setAlert}
          personToPersonAccounts={personToPersonAccounts}
          setPersonToPersonAccounts={setPersonToPersonAccounts}
        />
      )}
      {showUpdateModal && (
        <UpdatePersonToPersonAccountModal
          toggleModal={setShowUpdateModal}
          setSuccess={setAlert}
          personToPersonAccount={updatePersonToPersonAccount}
          personToPersonAccounts={personToPersonAccounts}
          setPersonToPersonAccount={setUpdatePersonToPersonAccount}
          setPersonToPersonAccounts={setPersonToPersonAccounts}
        />
      )}
      {showDeleteModal && (
        <ConfirmDeleteModal
          toggleModal={closeConfirmDeleteModal}
          loading={deleting}
          alert={alert}
          deleteCallback={deletePersonToPerson}
          page={page}
          translations={intl.messages}
          deleteItemValue={updatePersonToPersonAccount.id}
          deleteItemName={updatePersonToPersonAccount.name}
        />
      )}
    </>
  );
};

export default PersonToPersonAccounts;
