import React from 'react';
import PropTypes from 'prop-types';
// Import Translations
import { homeEn } from '../../../i18n/home';
// Import Global Components
import Heading from '../../../components/Heading';
import Text from '../../../components/Text';
// Import Local Components
import ModifyStatusButton from './ModifyStatusButton';
// Import Component Styles
import { HeaderWrapper } from '../styles';

const HomeHeader = ({
  data: {
    lastFourDigits,
    user: { firstName, lastName },
    accountId,
    cardStatus,
  },
  ...props
}) => (
  <HeaderWrapper>
    <div>
      <Heading
        page="home"
        heading={homeEn['home-heading']}
        values={{
          name: `${firstName} ${lastName}`,
        }}
      />
      {lastFourDigits && (
        <Text
          page="home"
          textFor="card-number"
          text={homeEn['home-card-number-text']}
          values={{
            number: lastFourDigits,
          }}
        />
      )}
    </div>
    {lastFourDigits && (
      <ModifyStatusButton
        lastFourDigits={lastFourDigits}
        accountId={accountId}
        cardStatus={cardStatus}
        {...props}
      />
    )}
  </HeaderWrapper>
);

HomeHeader.propTypes = {
  data: PropTypes.shape({
    cardStatus: PropTypes.string.isRequired,
    accountId: PropTypes.number.isRequired,
    lastFourDigits: PropTypes.number,
    user: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  }).isRequired,
  setAlert: PropTypes.func.isRequired,
};

export default HomeHeader;
