import React from 'react';
import { useIntl } from 'react-intl';
import { SubmitButton } from 'shared-components';

export default props => {
  const intl = useIntl();

  return (
    <SubmitButton
      {...props}
      buttonType="danger"
      size="large"
      value={intl.formatMessage({
        id: 'delete-button',
        description: 'Delete button',
        defaultMessage: intl.messages['delete-button'],
      })}
    />
  );
};
