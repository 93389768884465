import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
// Import Shared Components
import { Loader, ErrorComponent } from 'shared-components';
// Import Shared Utils
import { get } from 'shared-components/src/utils/http';
// Import Hooks
import useAlert from '../../../../hooks/useAlert';
// Import Global Components
import Heading from '../../../../components/Heading';
import Title from '../../../../components/Title';
import Alert from '../../../../components/Alert';
// Import Utils
import {
  getPersonalAccountByNetwork,
  getInitialNetwork,
} from '../../../../utils/transfers';
// Import Local Components
import AccountBalance from '../../components/AccountBalance';
import PersonalAccountTransferForm from './components/PersonalAccountTransferForm';
import AddPaymentMethod from './components/AddPaymentMethod';
import SelectNetwork from './components/SelectNetwork';
// Import Component Styles
import { Card, HeadingWrapper } from '../../styles';

// External Transfer to a Personal Bank Account
const PersonalAccountTransfer = () => {
  const intl = useIntl();

  // Component states
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useAlert();
  // Data states
  const [personalAccounts, setPersonalAccounts] = useState([]);
  const [personalAccount, setPersonalAccount] = useState(null);
  const [accountBalance, setAccountBalance] = useState(null);
  const [integrations, setIntegrations] = useState(null);
  const [selectedNetwork, setSelectedNetwork] = useState('visa');

  // fetch data upon component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Get data for working with external accounts
        const [
          personalAccountsData,
          accountBalanceData,
          integrationsData,
        ] = await Promise.all([
          get(
            '/api/v1/cardholder_portal/cardholder/external_accounts?type=personal_account'
          ),
          get('/api/v1/cardholder_portal/cardholder/current_account/balance'),
          get(
            '/api/v1/cardholder_portal/cardholder/external_transfers/integrations'
          ),
        ]);

        // Remove eft for now. Will add in EFT at a later date
        const networks = integrationsData.data.networks.filter(
          network => network !== 'eft'
        );

        setPersonalAccounts(personalAccountsData.data);
        setAccountBalance(accountBalanceData.data);
        setIntegrations({
          networks,
          etransfer_config: integrationsData.data.etransfer_config,
        });

        if (networks.length > 0) {
          const initialNetwork = getInitialNetwork(networks);
          setSelectedNetwork(initialNetwork);
          const pa = getPersonalAccountByNetwork(
            personalAccountsData.data,
            initialNetwork
          );
          if (pa) setPersonalAccount(pa);
        }
      } catch (e) {
        setError({ type: 'danger', message: e });
      }

      setLoading(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const pa = getPersonalAccountByNetwork(personalAccounts, selectedNetwork);

    // eslint-disable-next-line no-unused-expressions
    pa ? setPersonalAccount(pa) : setPersonalAccount(null);
  }, [selectedNetwork, personalAccounts]);

  // Define page states
  if (error) return <ErrorComponent />;
  if (loading) return <Loader />;

  const page = 'personal-account';

  return (
    <>
      <HeadingWrapper>
        <Heading page={page} heading={intl.messages[`${page}-heading`]} />
        <AccountBalance
          balance={accountBalance.balance}
          currency={accountBalance.currency}
        />
      </HeadingWrapper>
      {integrations.networks.length === 0 ? (
        <Alert
          type="info"
          alert={intl.messages[`${page}-no-integrations-alert`]}
          alertFor="no-integrations"
          page={page}
        />
      ) : (
        <>
          {integrations.networks.length > 1 && (
            <SelectNetwork
              networks={integrations.networks}
              setSelectedNetwork={setSelectedNetwork}
              selectedNetwork={selectedNetwork}
            />
          )}
          <Card>
            {alert && (
              <Alert
                type={alert.type}
                alert={alert.message}
                alertFor={alert.messageFor}
                page={page}
              />
            )}
            {personalAccount ? (
              <>
                <Title
                  title={intl.messages[`${page}-with-account-title`]}
                  titleFor="with-account"
                  page={page}
                />
                <PersonalAccountTransferForm
                  setAlert={setAlert}
                  personalAccount={personalAccount}
                  setPersonalAccount={setPersonalAccount}
                  setAccountBalance={setAccountBalance}
                  balance={accountBalance.balance}
                  setPersonalAccounts={setPersonalAccounts}
                  personalAccounts={personalAccounts}
                  selectedNetwork={selectedNetwork}
                />
              </>
            ) : (
              <>
                <Title
                  title={intl.messages[`${page}-add-payment-method-title`]}
                  titleFor="add-payment-method"
                  page={page}
                />
                <AddPaymentMethod
                  setAlert={setAlert}
                  setPersonalAccounts={setPersonalAccounts}
                  personalAccounts={personalAccounts}
                  etransferConfig={integrations.etransfer_config}
                  selectedNetwork={selectedNetwork}
                />
              </>
            )}
          </Card>
        </>
      )}
    </>
  );
};

PersonalAccountTransfer.propTypes = {
  history: PropTypes.object.isRequired, // eslint-disable-line
};

export default PersonalAccountTransfer;
