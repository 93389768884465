import styled from 'styled-components';

export const PinContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > p {
    margin-bottom: 15px;
  }

  ${({ theme }) => theme.media.tabletPortraitUp`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

      & > p {
        margin-bottom: 0;
      }
  `}
`;
