import React from 'react';
import { useIntl,FormattedMessage } from 'react-intl';
import { withTheme } from 'styled-components';
import { ExternalLink } from 'shared-components';
import { authEn,authFr } from '../../../../i18n/auth';


export default withTheme(({ theme }) => {
  const intl = useIntl();
  if (intl.locale === 'fr')  {
    return (
      <ExternalLink
        href={theme.brand.registerTermsOfUseFr}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FormattedMessage
          id="register-terms-of-use-link"
          description="Label for Terms of Use"
          defaultMessage={authFr['register-terms-of-use-link']}
        />
      </ExternalLink>
    );
  }
  return (
    <ExternalLink
      href={theme.brand.registerTermsOfUseEn}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FormattedMessage
        id="register-terms-of-use-link"
        description="Label for Terms of Use"
        defaultMessage={authEn['register-terms-of-use-link']}
      />
    </ExternalLink>
  );
});
