import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
// Import Shared Components
import { ButtonGroup, IconButton } from 'shared-components';
// Import Global Components
import Label from '../../../../../../../../components/Label';
// Import Component Styles
import {
  StyledPersonalAccount,
  PersonalAccountName,
  PersonalAccountContainer,
} from './styles';

const PersonalAccountInfo = ({
  personalAccountName,
  toggleUpdateModal,
  toggleDeleteModal,
}) => {
  const intl = useIntl();

  return (
    <StyledPersonalAccount>
      <Label
        required
        label={intl.messages['personal-account-account-label']}
        labelFor="account"
        page="personal-account"
      />
      <PersonalAccountContainer>
        <PersonalAccountName>{personalAccountName}</PersonalAccountName>
        <ButtonGroup>
          <IconButton
            iconType="edit"
            onClick={e => {
              e.preventDefault();
              toggleUpdateModal(true);
            }}
          />
          <IconButton
            iconType="trash"
            onClick={e => {
              e.preventDefault();
              toggleDeleteModal(true);
            }}
          />
        </ButtonGroup>
      </PersonalAccountContainer>
    </StyledPersonalAccount>
  );
};

PersonalAccountInfo.propTypes = {
  personalAccountName: PropTypes.string.isRequired,
  toggleUpdateModal: PropTypes.func.isRequired,
  toggleDeleteModal: PropTypes.func.isRequired,
};

export default PersonalAccountInfo;
