export const getPersonalAccountByNetwork = (personalAccounts, network) =>
  personalAccounts.find(pa => pa.vanity_identifier.includes(network));

// If visa is an available network, we want to have this as option #1 selected
// eslint-disable-next-line consistent-return
export const getInitialNetwork = networks => {
  if (networks.includes('visa')) return 'visa';
  if (networks.includes('eft')) return 'eft';
  if (networks.includes('etransfer')) return 'etransfer';
};
