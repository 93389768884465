import styled from 'styled-components';
import UnstyledBase from '../../layouts/Base';

// Base layout for application
export const Base = styled(UnstyledBase)`
  ${({ theme }) =>
    theme.brand.background.includes('https')
      ? `background: url(${theme.brand.background});
       background-size: cover;
    `
      : `background: ${theme.brand.background};`}
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 15px;
  }

  ${({ theme }) => theme.media.tabletPortraitUp`
    flex-direction: row;

    & > *:not(:last-child) {
      margin-bottom: 0;
      margin-right: 15px;
    }
  `}
`;

export const FormGroup = styled.fieldset`
  & > *:not(:last-child) {
    margin-bottom: 15px;
  }
`;

export const PasswordRequirements = styled.ul`
  li {
    list-style-type: disc;
    margin-left: 15px;
  }
`;

