// Translation file for Home/Dashboard page

export const homeEn = {
  'home-heading': 'Welcome to your Dashboard, {name}!',
  'home-suspend-button': 'Suspend Card',
  'home-unsuspend-button': 'Unsuspend Card',
  'home-status-label': 'Account Status',
  'home-balance-label': 'Balance',
  'home-expiry-label': 'Expiry Date',
  'home-card-number-text': 'Card Number XXXX XXXX XXXX {number}',
  'home-month-button': '{range} Month',
  'home-view-label': 'View',
  'home-table-alert': 'No Transactions have been made on this Account',
  'home-transactions-title': 'Transactions',
  'txn-date-table-header': 'Transaction Date',
  'txn-description-table-header': 'Transaction Type',
  'txn-amount-table-header': 'Amount',
  'txn-merchant-info-table-header': 'Merchant Information',
  'txn-status-table-header': 'Status',
  'date-to-label': 'To:',
  'date-from-label': 'From:',
  'suspend-card-error-alert': 'Failed to suspend card',
  'unsuspend-card-error-alert': 'Failed to unsuspend card',
  'suspend-card-success-alert': 'Card suspended successfully',
  'unsuspend-card-success-alert': 'Card unsuspended successfully',
  'card-closed-alert':
    'The card on this account is marked as closed. You are still able to view your account information but the card is not longer active.',
};

export const homeFr = {
  'home-heading': 'Bienvenue à votre compte, {name}!',
  'home-suspend-button': 'Suspendre carte',
  'home-unsuspend-button': 'Débloquer la carte',
  'home-status-label': 'Statut du compte',
  'home-balance-label': 'Votre Solde',
  'home-expiry-label': 'Date D\'Expiration',
  'home-card-number-text': 'Numéro de Carte XXXX XXXX XXXX {number}',
  'home-month-button': '{range} Mois',
  'home-view-label': 'Voir',
  'home-table-alert': 'Aucune transaction n\'a été effectuée sur ce compte',
  'home-transactions-title': 'Transactions',
  'txn-date-table-header': 'Date de la Transaction',
  'txn-description-table-header': 'Type de Transaction',
  'txn-amount-table-header': 'Montant',
  'txn-merchant-info-table-header': 'Informations du Commerçant',
  'txn-status-table-header': 'Statut',
  'date-to-label': 'De:',
  'date-from-label': 'À:',
  'suspend-card-error-alert': 'Échec de la suspension de la carte',
  'unsuspend-card-error-alert': 'Échec de la réactivation de la carte',
  'suspend-card-success-alert': 'Carte suspendue avec succès',
  'unsuspend-card-success-alert': 'Suspension de la carte levée avec succès',
  'card-closed-alert':
    'La carte de ce compte est marquée comme fermée. Vous êtes toujours en mesure d\'afficher vos informations de compte, mais la carte est plus actif.',
};
