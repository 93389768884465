import styled from 'styled-components';

// List containing Navigation Links excluding Logout
export const LinksGroup = styled.ul`
  display: flex;
  flex-direction: column;

  li > a {
    padding: 0;
  }

  ${({ theme }) => theme.media.desktopUp`
    flex-direction: row;

    li > a {
      padding: 15px;
    }
  `}
`;

// List containing Navigation Links including Logout
export const LinksContainer = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  text-align: right;

  li {
    margin-bottom: 15px;
  }

  ${({ theme }) => theme.media.desktopUp`
    flex-direction: row;
    align-items: center;
    padding: 0 30px;

    li {
      margin-bottom: 0;
    }

    li > button {
      margin-left: 30px;
    }

  `}
`;

export const NavMenu = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  padding: 20px 15px 20px 60px;
  background-color: ${({ theme }) => theme.brand.tertiary};
  height: 100vh;
  transition: transform 0.3s ease-in-out;
  width: 100%;

  & > * {
    margin-bottom: 10px;
    margin-left: auto;
  }

  & > *:last-child {
    margin-top: 10px;
    margin-bottom: 5px;
  }

  ${({ theme }) => theme.media.desktopUp`
    flex-direction: row;
    align-items: center;
    margin-top: 0;
    padding: 0;
    background-color: transparent;
    height: auto;
    text-align: none;
    width: auto;
    transform: none;

    & > * {
      margin-bottom: 0;
      margin-left: 0;
    }

    & > *:last-child {
      margin-top: 0;
      margin-bottom: 5px;
    }
  `}
`;

// Contains the List of Navigation Links
export const HeaderNav = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 100%;
  right: 8px;
  z-index: 2;
  height: auto;
  margin-right: ${({ open }) => (open ? '-8px' : '-400px')};

  ${({ theme }) => theme.media.desktopUp`
    position: static;
    flex-direction: row;
    margin-right: 0;
    width: 90%;
    justify-content: flex-end;
    top: 0;
    right: 0;
    z-index: 1;
  `}
`;
