import React from 'react';
import { withTheme } from 'styled-components';
import { Logo } from 'shared-components';
// Import Component Styles
import { LogoLink, LogoLinkDefault } from '../styles';

export default withTheme(({ locale, theme }) => (
  <>
    {theme.brand.logo_redirect_url && (
      <LogoLink link={theme.brand.logo_redirect_url}>    
        <Logo
          src={locale !== 'en' ? theme.brand.logo_fr : theme.brand.logo}
          alt={theme.brand.logoAlt}
        />
      </LogoLink>
    )}
    {!theme.brand.logo_redirect_url && (
      <LogoLinkDefault to="/">
        <Logo
          src={locale !== 'en' ? theme.brand.logo_fr : theme.brand.logo}
          alt={theme.brand.logoAlt}
        />
      </LogoLinkDefault>
    )}
  </>
));
