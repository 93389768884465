import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
// Import Shared Components
import {
  Modal,
  ActionBar,
  ButtonGroup,
  Loader,
  Input,
} from 'shared-components';
// Import Shared Utils
import { post } from 'shared-components/src/utils/http';
import { personToPersonAccount as PERSON_TO_PERSON_ACCOUNT } from 'shared-components/src/constants/propTypes';
// Import Global Layouts
import InputGroup from '../../../../../../layouts/InputGroup';
import InputRow from '../../../../../../layouts/InputRow';
import Form, { ModalFormContainer } from '../../../../../../layouts/Form';
// Import Global Components
import Heading from '../../../../../../components/Heading';
import Alert from '../../../../../../components/Alert';
import SubmitButton from '../../../../../../components/SubmitButton';
import CancelButton from '../../../../../../components/CancelButton';

const UpdateRecipientAccountModal = ({
  toggleModal,
  setSuccess,
  personToPersonAccounts,
  setPersonToPersonAccounts,
  personToPersonAccount,
  setPersonToPersonAccount,
}) => {
  const intl = useIntl();
  // component states
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);
  // data states
  const nameParts = personToPersonAccount.name.split(' ');
  const [firstNameInput, setFirstNameInput] = useState(nameParts[0]);
  const [lastNameInput, setLastNameInput] = useState(nameParts[1]);
  const [emailInput, setEmailInput] = useState(personToPersonAccount.email);

  const page = 'person-to-person';

  const updatePersonToPersonAccount = async params => {
    try {
      // create external account
      const res = await post(
        `/api/v1/cardholder_portal/cardholder/external_accounts/${personToPersonAccount.id}`,
        params
      );
      const idx = personToPersonAccounts.findIndex(
        account => account.id === res.data.id
      );

      // remove old recipient account and add updated recipient account
      setPersonToPersonAccounts([
        ...personToPersonAccounts.slice(0, idx),
        res.data,
        ...personToPersonAccounts.slice(idx + 1),
      ]);

      setSuccess({
        type: 'success',
        message: intl.messages[`${page}-update-success-alert`],
        messageFor: 'update-success',
      });
      setLoading(false);
      toggleModal(false);
      setPersonToPersonAccount(null);
    } catch (e) {
      setAlert({ type: 'danger', message: e.message });
      setLoading(false);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    setAlert(null);

    const params = {
      name: `${firstNameInput.trim()} ${lastNameInput.trim()}`,
      email: emailInput.trim(),
    };

    updatePersonToPersonAccount(params);
  };

  return (
    <Modal>
      <ModalFormContainer>
        {alert && (
          <Alert
            alert={alert.message}
            alertFor={alert.type}
            page={page}
            type={alert.type}
          />
        )}
        <Heading
          page={`${page}-update`}
          heading={intl.messages[`${page}-update-heading`]}
        />
        {loading ? (
          <Loader />
        ) : (
          <Form onSubmit={e => handleSubmit(e)}>
            <InputRow>
              <InputGroup
                label={intl.messages['firstName-label']}
                labelFor="firstName"
                required
              >
                <Input
                  value={firstNameInput}
                  onChange={e => setFirstNameInput(e.target.value)}
                  required
                  dataTestid="firstName"
                  name="firstName"
                  autocomplete="firstName"
                />
              </InputGroup>
            </InputRow>
            <InputRow>
              <InputGroup
                label={intl.messages['lastName-label']}
                labelFor="lastName"
                required
              >
                <Input
                  value={lastNameInput}
                  onChange={e => setLastNameInput(e.target.value)}
                  required
                  dataTestid="lastName"
                  name="lastName"
                  autocomplete="lastName"
                />
              </InputGroup>
            </InputRow>
            <InputRow>
              <InputGroup
                label={intl.messages['email-label']}
                labelFor="email"
                required
              >
                <Input
                  value={emailInput}
                  onChange={e => setEmailInput(e.target.value)}
                  required
                  dataTestId="email"
                  name="email"
                  autocomplete="email"
                  type="email"
                />
              </InputGroup>
            </InputRow>

            <ActionBar>
              <ButtonGroup reverse>
                <SubmitButton />
                <CancelButton
                  onClick={e => {
                    e.preventDefault();
                    toggleModal(false);
                  }}
                />
              </ButtonGroup>
            </ActionBar>
          </Form>
        )}
      </ModalFormContainer>
    </Modal>
  );
};

UpdateRecipientAccountModal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
  personToPersonAccount: PERSON_TO_PERSON_ACCOUNT.isRequired, // eslint-disable-line
  personToPersonAccounts: PropTypes.arrayOf(PERSON_TO_PERSON_ACCOUNT)
    .isRequired,
  setPersonToPersonAccount: PropTypes.func.isRequired,
  setPersonToPersonAccounts: PropTypes.func.isRequired,
};

export default UpdateRecipientAccountModal;
