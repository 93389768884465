import React, { useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
// Import Shared Components
import { SubmitButton, ActionBar, Input } from 'shared-components';
import { numberDollarsToCents } from 'shared-components/src/utils/currency';
// Import Global Components
import TransferFeeTooltip from '../../../../../components/TransferFeeTooltip';
// Import Translations
import { accountToAccountEn } from '../../../../../i18n/accountToAccount';
// Import Global Layouts
import InputGroup from '../../../../../layouts/InputGroup';
import InputRow from '../../../../../layouts/InputRow';
import Form from '../../../../../layouts/Form';

const AccountToAccountTransferForm = ({
  setAlert,
  balance,
  disableSubmit,
  createAccountToAccountTransfer,
  recipientAccounts
}) => {
  // create Intl instance
  const intl = useIntl();

  // Define input refs
  const recipientInput = useRef(null);
  const [amountInput, setAmountInput] = useState(0);
  const messageInput = useRef(null);

  // Define States
  const [transferNameInput, setTransferNameInput] = useState(
    `Transfer ${Date.now()}`
  );

  // create an array of recipient accounts for select input
  const recipientAccountOptions = recipientAccounts.map(account => {
    return { key: account.id, val: account.name };
  });

  const page = 'account-to-account';

  // handle submission of account to account transfer form
  const handleSubmit = e => {
    e.preventDefault();
    setAlert(null);

    // Check that entered amount is less than available balance
    if (parseInt(amountInput, 10) > parseInt(balance, 10))
      return setAlert({
        type: 'danger',
        message: accountToAccountEn[`${page}-exceeds-available-amount-alert`],
        messageFor: 'exceeds-available-amount',
      });

    // populate params for transfer post request
    const params = {
      recipient_account_id: recipientInput.current.value,
      amount: numberDollarsToCents(amountInput),
      external_tag: transferNameInput,
      message: messageInput.current.value,
    };

    return createAccountToAccountTransfer(params);
  };

  return (
    <Form onSubmit={e => handleSubmit(e)}>
      <InputRow>
        <InputGroup
          label={accountToAccountEn[`${page}-recipient-label`]}
          labelFor="recipient"
          page={page}
          required
        >
          <Input required ref={recipientInput} as="select">
            {' '}
            <option disabled selected value="">
              Select...
            </option>
            ,
            {recipientAccountOptions.map(p => (
              <option key={p.key} value={p.key}>{p.val}</option>
            ))}
          </Input>
        </InputGroup>
      </InputRow>
      <InputRow>
        <InputGroup
          required
          label={accountToAccountEn[`${page}-transfer-name-label`]}
          labelFor="transfer-name"
          page={page}
        >
          <Input
            max="50"
            name="transfer-name"
            pattern="[a-zA-Z0-9\- ]*"
            title="Please use letters, numbers, and dashes only"
            value={transferNameInput}
            onChange={e => setTransferNameInput(e.target.value)}
          />
        </InputGroup>
        <InputGroup
          required
          label={accountToAccountEn[`${page}-amount-label`]}
          labelFor="amount"
          page={page}
        >
          <Input
            name="amount"
            pattern="[0-9\.]"
            title="Please enter a valid amount"
            placeholder="00.00"
            type="number"
            step="0.01"
            min={1}
            value={amountInput}
            onChange={e => setAmountInput(e.target.value)}
            required
          />
        </InputGroup>
      </InputRow>
      <InputRow>
        <TransferFeeTooltip
          transferAmount={amountInput}
          feeType="account_to_account"
          page={page}
        />
      </InputRow>
      <InputRow>
        <InputGroup
          label={accountToAccountEn[`${page}-message-label`]}
          labelFor="message"
          page={page}
        >
          <Input ref={messageInput} as="textarea" max="300" />
        </InputGroup>
      </InputRow>

      <ActionBar>
        <SubmitButton
          disabled={disableSubmit}
          buttonType="primary"
          size="large"
          value={intl.formatMessage({
            id: 'external-account-create-button',
            description: 'Submit button transfer external acct',
            defaultMessage: 'Submit',
          })}
        />
      </ActionBar>
    </Form>
  );
};

AccountToAccountTransferForm.propTypes = {
  setAlert: PropTypes.func.isRequired,
  disableSubmit: PropTypes.bool.isRequired,
  createAccountToAccountTransfer: PropTypes.func.isRequired,
  balance: PropTypes.string.isRequired,
  recipientAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      cardholder_id: PropTypes.number.isRequired,
      created_at: PropTypes.string.isRequired,
      dest_account_id: PropTypes.number.isRequired,
      email: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      updated_at: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default AccountToAccountTransferForm;
