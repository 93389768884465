import styled from 'styled-components';

export const IntroText = styled.div`
  padding-bottom: 30px;
  margin: 0;
`;

export const AlphaListItem = styled.li`
  list-style: lower-alpha;
  margin-left: 15px;
`;

export const CardSections = styled.div`
  width: 100%;

  & > * {
    margin-bottom: 20px;
  }

  ${({ theme }) => theme.media.tabletLandscapeUp`
    width: 50%;
  `}
`;

export const About = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.tabletLandscapeUp`
    flex-direction: row;

    & > *:first-child {
      margin-right: 15px;
    }
  `}
`;
