export const etransferPushComponentName = 'etransfer-push';

export const updateFeeAmounts = data => {
  const successFee = data.etransfer_push_success;

  const update = {};
  if (typeof successFee === 'number' && successFee > 0) {
    update.create = (Number(successFee) / 100).toFixed(2);
  }

  return update;
};
