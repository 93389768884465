import React from 'react';
import { useIntl,FormattedMessage } from 'react-intl';
import { withTheme } from 'styled-components';
import { ExternalLink } from 'shared-components';
import { authEn,authFr } from '../../../../i18n/auth';


export default withTheme(({ theme }) => {
  const intl = useIntl();
  if (intl.locale === 'fr')  {
    return (
      <ExternalLink
        href={theme.brand.activatePrivacyPolicyFr}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FormattedMessage
          id="activate-privacy-policy-link"
          description="Label for Privacy Policy"
          defaultMessage={authFr['activate-privacy-policy-link']}
        />
      </ExternalLink>
    );
  }
  return (
    <ExternalLink
      href={theme.brand.activatePrivacyPolicyEn}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FormattedMessage
        id="activate-privacy-policy-link"
        description="Label for Privacy Policy"
        defaultMessage={authEn['activate-privacy-policy-link']}
      />
    </ExternalLink>
  );
});
