import styled from 'styled-components';
import { Dropdown } from 'shared-components';

export const StyledDropdown = styled(Dropdown)`
    margin: 0 10px;
    width: fit-content;
    height: fit-content;
    align-self: end;
`;

export const DropdownContainer = styled.div``;