import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
// Import Shared Utils
import { getAccountStatus } from 'shared-components/src/utils/cardholders';
import { formatExpiryDate } from 'shared-components/src/utils/formatDateTime';
import { formatCurrencyCode } from 'shared-components/src/utils/formatCurrency';
// Import Local Components
import Widget from './components/Widget';
// Import Component Styles
import { WidgetGroup } from '../../styles';

const AccountDetails = ({ accountBalance, expiryDate, status }) => {
  const renderBalance = () => {
    if (!accountBalance) return <span>--</span>;

    return (
      <FormattedNumber
        style="currency" // eslint-disable-line react/style-prop-object
        currency={formatCurrencyCode(accountBalance.currency)}
        value={parseFloat(accountBalance.balance)}
      />
    );
  };

  return (
    <WidgetGroup>
      <Widget widgetLabel="Available Balance" widgetType="balance">
        {renderBalance()}
      </Widget>
      <Widget widgetLabel="Account Status" widgetType="status">
        {getAccountStatus(status)}
      </Widget>
      <Widget widgetLabel="Expiry Date" widgetType="expiry">
        {formatExpiryDate(expiryDate)}
      </Widget>
    </WidgetGroup>
  );
};

AccountDetails.propTypes = {
  accountBalance: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    balance: PropTypes.number.isRequired,
  }).isRequired,
  status: PropTypes.string.isRequired,
  expiryDate: PropTypes.string.isRequired,
};

export default AccountDetails;
