import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
// Import Shared Components
import { Loader, ErrorComponent } from 'shared-components';
// Import Shared Utils
import { get, post } from 'shared-components/src/utils/http';
// Import Hooks
import useAlert from '../../../../hooks/useAlert';
// Import Global Components
import Heading from '../../../../components/Heading';
import Title from '../../../../components/Title';
import Alert from '../../../../components/Alert';
import Button from '../../../../components/Button';
// Import Local Components
import AccountBalance from '../../components/AccountBalance';
import PersonToPersonTransferForm from './components/PersonToPersonTransferForm';
// Import Component Styles
import { Card, HeadingWrapper, TitleContainer } from '../../styles';

const PersonToPersonTransfer = ({ history }) => {
  const intl = useIntl();

  // Component states
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [sendingTransfer, setSendingTransfer] = useState(false);
  const [alert, setAlert] = useAlert();
  // data states
  const [personToPersonAccounts, setPersonToPersonAccounts] = useState([]);
  const [accountBalance, setAccountBalance] = useState(null);

  // fetch data upon component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Get data for working with external accounts
        const [
          personToPersonAccountsData,
          accountBalanceData,
        ] = await Promise.all([
          get(
            '/api/v1/cardholder_portal/cardholder/external_accounts?type=person_to_person'
          ),
          get('/api/v1/cardholder_portal/cardholder/current_account/balance'),
        ]);
        setPersonToPersonAccounts(personToPersonAccountsData.data);
        setAccountBalance(accountBalanceData.data);
      } catch (e) {
        setError({ type: 'danger', message: e });
      }

      setLoading(false);
    };

    fetchData();
  }, []);

  // Define page states
  if (error) return <ErrorComponent />;
  if (loading) return <Loader />;

  const page = 'person-to-person';

  // Create person to person transfer
  const createPersonToPersonTransfer = async params => {
    setSendingTransfer(true);
    try {
      // send transfer request
      await post(
        '/api/v1/cardholder_portal/cardholder/external_transfers',
        params
      );
      const accountBalanceData = await get(
        '/api/v1/cardholder_portal/cardholder/current_account/balance'
      );

      // set updated account data
      setAccountBalance(accountBalanceData.data);
      // set success alert
      setAlert({
        type: 'success',
        message: intl.messages[`${page}-transfer-created-alert`],
        messageFor: 'transfer-created',
      });

      // update loading state
      setSendingTransfer(false);
    } catch (e) {
      setAlert({
        type: 'danger',
        message: e.message,
      });
      setSendingTransfer(false);
    }
  };

  // Create person to person account before creating external transfer
  // eslint-disable-next-line
  const createPersonToPersonExternalAccountAndTransfer = async (
    newRecipientParams,
    transferParams
  ) => {
    setSendingTransfer(true);
    let createdAccount;
    try {
      // send transfer request
      const res = await post(
        '/api/v1/cardholder_portal/cardholder/external_accounts',
        newRecipientParams
      );
      setPersonToPersonAccounts([...personToPersonAccounts, res.data]);
      // We don't update sending transfer state here.
      // Next step is to create transfer so we keep sending transfer state and return the resolved data.
      createPersonToPersonTransfer({
        external_account_id: res.data.id,
        ...transferParams,
      });
    } catch (e) {
      setAlert({
        type: 'danger',
        message: e.message,
      });
      setSendingTransfer(false);
    }

    return createdAccount;
  };

  return (
    <>
      <HeadingWrapper>
        <Heading page={page} heading={intl.messages[`${page}-heading`]} />
        <AccountBalance
          balance={accountBalance.balance}
          currency={accountBalance.currency}
        />
      </HeadingWrapper>
      <Card>
        {alert && (
          <Alert
            type={alert.type}
            alert={alert.message}
            alertFor={alert.messageFor}
            page={page}
          />
        )}
        <TitleContainer>
          <Title
            title={intl.messages[`${page}-form-title`]}
            titleFor="form"
            page={page}
          />
          <Button
            buttonText={intl.messages[`${page}-manage-accounts-button`]}
            buttonFor="manage-accounts"
            page={page}
            onClick={e => {
              e.preventDefault();
              history.push('/transfers/person-to-person/accounts');
            }}
            type="secondary"
          />
        </TitleContainer>
        {sendingTransfer ? (
          <Loader />
        ) : (
          <PersonToPersonTransferForm
            setAlert={setAlert}
            balance={accountBalance.balance}
            createPersonToPersonTransfer={createPersonToPersonTransfer}
            createPersonToPersonExternalAccountAndTransfer={
              createPersonToPersonExternalAccountAndTransfer
            }
            personToPersonAccounts={personToPersonAccounts}
          />
        )}
      </Card>
    </>
  );
};

PersonToPersonTransfer.propTypes = {
  history: PropTypes.object.isRequired, // eslint-disable-line
};

export default PersonToPersonTransfer;
