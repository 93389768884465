import { useEffect, useState } from 'react';
import { get } from 'shared-components/src/utils/http';

export const useFeeCalculation = (transferAmount, feeType) => {
  const feeTypePercent = `${feeType}_percent`;
  const initialProgramFeeConfig = {
    account_to_account: 0,
    external_recipient: 0,
    external_bank: 0,
    interac_pull_success: 0,
    interac_pull_cancellation: 0,
    loading: 0,
    account_to_account_percent: '0',
    external_recipient_percent: '0',
    external_bank_percent: '0',
    loading_percent: '0',
  };
  const [programFeeConfig, setProgramFeeConfig] = useState(
    initialProgramFeeConfig
  );
  const [feeAmount, setFeeAmount] = useState(0);
  const [error, setError] = useState(null);

  // Fetch program fee config on initial
  useEffect(() => {
    const fetchProgramFeeConfig = async () => {
      try {
        const res = await get(
          '/api/v1/cardholder_portal/cardholder/fee_configs'
        );
        setProgramFeeConfig(res.data);
      } catch (e) {
        setError(e);
      }
    };
    fetchProgramFeeConfig();
  }, []);

  // Updates total fee whenever program fee config is fetched initially or transfer amount changes
  useEffect(() => {
    const hasNoFees = () => {
      if (
        programFeeConfig[feeType] === 0 &&
        programFeeConfig[feeTypePercent] === 0
      ) {
        return true;
      }
      return false;
    };
    const calculateFeeAmount = () => {
      const flatAmount = Number(programFeeConfig[feeType]);
      const percentAmount = Number(programFeeConfig[feeTypePercent]);
      if (hasNoFees()) {
        return 0;
      }
      const transAmount = Number(transferAmount) * 100;
      const totalAmount =
        Number(
          ((flatAmount + (percentAmount / 100) * transAmount) / 100).toFixed(2)
        ) || 0;
      return totalAmount;
    };
    setFeeAmount(calculateFeeAmount());
  }, [transferAmount, programFeeConfig, feeType, feeTypePercent]);

  return { feeAmount, error };
};
