import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// Import Shared Components
import { Modal, Loader, IconButton } from 'shared-components';
// Import Shared Utils
import { post } from 'shared-components/src/utils/http';
// Import Global Components
import Heading from '../../../../components/Heading';
// TODO: add or remove set pin functionality based on whether or not we allow it for Galileo programs
// import Button from '../../../../components/Button';
import Text from '../../../../components/Text';
import Alert from '../../../../components/Alert';
// Import Translations
import { profileEn } from '../../../../i18n/profile';
// Import Local Layouts
import PinModal from '../../layouts/PinModal';
// Import Component Styles
import { PinContainer } from './styles';

// TODO: add or remove set pin functionality based on whether or not we allow it for Galileo programs
const GetPinModal = ({
  toggleModal,
  // toggleSetPinModal,
  pin,
  setPin,
  currentAccount,
  alert,
  setAlert,
  initialPinFetch,
}) => {
  const [loading, setLoading] = useState(false);
  // TODO: add or remove set pin functionality based on whether or not we allow it for Galileo programs
  // const isError = !!alert && alert.type === 'danger';

  /* eslint-disable no-param-reassign */
  useEffect(() => {
    if (!initialPinFetch.current) {
      return;
    }
    initialPinFetch.current = false;
    const fetchData = async () => {
      setLoading(true);
      try {
        const params = {
          last_four_digits: currentAccount.cards[0].last_four_digits,
        };

        const res = await post(
          `api/v1/cardholder_portal/cardholder/accounts/${currentAccount.id}/get_pin`,
          params
        );

        setPin(res.data.pin);
      } catch (e) {
        setAlert({ type: 'danger', message: e.message });
      }
      setLoading(false);
    };
    fetchData();
  }, [
    pin,
    currentAccount.cards,
    currentAccount.id,
    setPin,
    setAlert,
    initialPinFetch,
  ]);
  /* eslint-enable no-param-reassign */

  const page = 'profile';

  return (
    <Modal>
      <PinModal>
        <IconButton
          iconType="x"
          onClick={toggleModal}
          className="close-modal"
        />
        {/* alert for both error and success */}
        {alert && (
          <Alert
            alert={alert.message}
            alertFor={alert.type}
            page={page}
            type={alert.type}
          />
        )}
        <Heading
          page={`${page}-get-pin`}
          heading={profileEn[`${page}-get-pin-heading`]}
        />
        {loading ? (
          <>
            <Text
              text={profileEn[`${page}-get-pin-wait-text`]}
              textFor="get-pin-wait"
              page={page}
            />
            <Loader />
          </>
        ) : (
          <PinContainer>
            <Text
              text={profileEn[`${page}-get-pin-text`]}
              textFor="get-pin"
              page={page}
              values={{
                b: chunks => <b>{chunks}</b>,
                pin,
              }}
            />
            {/* TODO: add or remove set pin functionality based on whether or not we allow it for Galileo programs */}
            {/* Don't allow users to access setPinModal if there is error fetching pin
            {!isError && (
              <Button
                buttonText={profileEn[`${page}-set-pin-button`]}
                buttonFor="set-pin"
                page={page}
                type="secondary"
                onClick={e => {
                  e.preventDefault();
                  toggleSetPinModal();
                }}
              />
            )} */}
          </PinContainer>
        )}
      </PinModal>
    </Modal>
  );
};

GetPinModal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  // toggleSetPinModal: PropTypes.func.isRequired,
  pin: PropTypes.string,
  setPin: PropTypes.func.isRequired,
  currentAccount: PropTypes.shape({
    id: PropTypes.number.isRequired,
    cards: PropTypes.array.isRequired, //eslint-disable-line
  }).isRequired,
  alert: PropTypes.shape({
    message: PropTypes.string.isRequired,
    messageFor: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  setAlert: PropTypes.func.isRequired,
  initialPinFetch: PropTypes.shape({
    current: PropTypes.bool.isRequired,
  }).isRequired,
};

GetPinModal.defaultProps = {
  pin: null,
};
export default GetPinModal;
