import styled from 'styled-components';

// Wrapper for sensitive card information
export const CardInfoGroup = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 50px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 1em;

  ${({ theme }) => theme.media.tabletPortraitUp`
    flex-direction: row;
    justify-content: space-between;

    // Without explicitly setting div to auto, 
    // the ButtonGroup will take up too much space and cause header text to wrap in tabletPortraitUp
    div {
      width: auto;
    }
  `}
`;

export const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
