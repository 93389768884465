import React from 'react';
// Import Global Layouts
import Form from '../../../layouts/Form';
// Import Local components
import ProfileForm from '../components/Form';

const ProfileShow = props => (
  <Form as='div'>
    <ProfileForm
      disabled
      iconType="edit"
      {...props}
    />
  </Form>
);

export default ProfileShow;
