import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

export default () => {
  const intl = useIntl();

  return (
    <p style={{ marginBottom: '5px', fontSize: '.9em' }}>
      <i>
        <FormattedMessage
          id="personal-account-interac-details-text"
          description="Text for interac details"
          defaultMessage={
            intl.messages['personal-account-interac-details-text']
          }
        />
      </i>
    </p>
  );
};
