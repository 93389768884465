import styled from 'styled-components';

export default styled.div`
  padding: 0 15px;
  margin: 0 auto;
  height: 100%;

  @media (max-width: 990px) {
    width: auto;
  }
  @media (min-width: 768px) {
    min-width: 750px;
  }
  @media (min-width: 992px) {
    min-width: 970px;
  }
  ${({ theme }) => theme.media.desktopUp`
    min-width: 1170px;
  `}
  @media (min-width: 1500px) {
    max-width: 85%;
  }

  &::before,
  &::after {
    display: table;
    content: "";
  }

  &::after {
    clear: both;
  }
`;
