import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
// Import Shared Components
import {
  Modal,
  ActionBar,
  ButtonGroup,
  Loader,
  Input,
} from 'shared-components';
// Import Shared Utils
import { post } from 'shared-components/src/utils/http';
// Import Global Layouts
import InputGroup from '../../../../../../layouts/InputGroup';
import InputRow from '../../../../../../layouts/InputRow';
import Form, { ModalFormContainer } from '../../../../../../layouts/Form';
// Import Global Components
import Heading from '../../../../../../components/Heading';
import Alert from '../../../../../../components/Alert';
import SubmitButton from '../../../../../../components/SubmitButton';
import CancelButton from '../../../../../../components/CancelButton';

// Modal to update Cardholder's Personal Bank Account
const UpdateAccountModal = ({
  toggleModal,
  setSuccess,
  personalAccount,
  setPersonalAccount,
}) => {
  const intl = useIntl();

  const [personalAccountName, setPersonalAccountName] = useState(
    personalAccount.name
  );
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);

  const page = 'personal-account';

  // send post to update personal account
  const updatePersonalAccount = async params => {
    setLoading(true);
    setAlert(null);

    try {
      // update personal account
      await post(
        `/api/v1/cardholder_portal/cardholder/external_accounts/${personalAccount.id}`,
        params
      );

      // update personal account with new name
      setPersonalAccount({ name: params.name, ...personalAccount });

      setSuccess({
        type: 'success',
        message: intl.messages[`${page}-success-update-alert`],
        messageFor: 'success-update',
      });
      setLoading(false);
      toggleModal(false);
    } catch (e) {
      setAlert({ type: 'danger', message: e.message });
      setLoading(false);
    }
  };

  // handle submission of form
  const handleSubmit = e => {
    e.preventDefault();
    // Check that new name is not the same as existing name
    if (personalAccountName === personalAccount.name)
      return setAlert({
        type: 'danger',
        message: intl.messages[`${page}-invalid-name-alert`],
        messageFor: 'invalid-name',
      });

    return updatePersonalAccount({ name: personalAccountName });
  };

  return (
    <Modal>
      <ModalFormContainer>
        {alert && (
          <Alert
            alert={alert.message}
            alertFor={alert.type}
            page={page}
            type={alert.type}
          />
        )}
        <Heading
          page={`${page}-update`}
          heading={intl.messages[`${page}-update-heading`]}
        />
        {loading ? (
          <Loader />
        ) : (
          <Form onSubmit={e => handleSubmit(e)}>
            <InputRow>
              <InputGroup
                label={intl.messages[`${page}-name-label`]}
                labelFor="name"
                required
                page={page}
              >
                <Input
                  required
                  value={personalAccountName}
                  onChange={e => setPersonalAccountName(e.target.value)}
                  dataTestId="name"
                  name="name"
                  min="1"
                  max="150"
                  autocomplete="name"
                  placeholder="Ex: TD Visa Debit"
                />
              </InputGroup>
            </InputRow>

            <ActionBar>
              <ButtonGroup reverse>
                <SubmitButton />
                <CancelButton
                  onClick={e => {
                    e.preventDefault();
                    toggleModal(false);
                  }}
                />
              </ButtonGroup>
            </ActionBar>
          </Form>
        )}
      </ModalFormContainer>
    </Modal>
  );
};

UpdateAccountModal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  personalAccount: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  setSuccess: PropTypes.func.isRequired,
  setPersonalAccount: PropTypes.func.isRequired,
};

export default UpdateAccountModal;
